
import { defineComponent, reactive, onMounted, toRefs, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import { ethitransService } from "@/services/EthitransService";

import { config } from "@/Config";
import { common } from "@/common/services/Common";
import Pusher from "pusher-js";
import { signOut } from "@/common/google";

export default defineComponent({
  name: "Invitation",
  components: {
    EmptyData,
    ErrorList,
    SuccessfulModal,
    // VFacebookLogin
    // VFacebookLogin
    // facebookLogin
    // GoogleLogin
    // 'g-signin-button': GSignInButton
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      data: {
        selectedInvition: {} as any,
        showSuccesModal: false,
        declineShareProject:true,
        selectedUuid: "" as any,
        description: "",
        successFulMessage: "",
        loggedInUserUUID: "",
        imageBaseUrl: "",
        error: {
          errorsList: [] as any,
          hasError: false,
        },
      },
      ui: {
        isLoading: {
          userStatus: false,
        },
        isPostLoading: {
          redirectToChat: false,
        },
        errors: {
          errorList: {
            projectAcknowledgementDetails: [] as any,
          },
          hasError: false,
        },
      },
      title: "invitations",
      heading: "Get started by creating invitation in your project.",
      userName: "",
      uuId: "",
      postLoading: {
        acceptInvitation: false,
        declineInvitation: false,
      },
      isLoading: false,
      pendingList: [],
    });
    function hideDeleteInvitation(){
      $("#deleteInvitation").modal("hide");
    }
    function hideInvitationDetails() {
      $("#showInvitationDetails").modal("hide");
    }
    function showDeclineModal(selectedInvition: any){
      if(selectedInvition.type===8){
        state.data.declineShareProject = false;
      }
      state.data.selectedInvition = {};
      state.data.selectedInvition = selectedInvition;
      state.data.description = '';
      hideInvitationDetails();
      $("#deleteInvitation").modal("show");
    }
    
     function detailsModal(selectedInvitation: any) {
        const invitationId = selectedInvitation.uuid;
      // console.log("CHECK INVITATION >>> ", invitationId );
      router.push({
        name: "user-phase-task-invitation-acknowledgement",
        params: { invitationId: invitationId },
      });
     
    }

       function jobEstimationDetails(selectedInvitation: any) {
        const invitationId = selectedInvitation.related_id;
      router.push({
        name: "invite-job-estimation-details",
        params: { estimationId: invitationId },
      });
     
    }


    function projectInvitationDetail(selectedInvitation: any) {
        const invitationId = selectedInvitation.uuid;
      router.push({
        name: "project-member-invitation",
        params: { invitationId: invitationId },
      });
     
    }

    function removeSelectedInvitation() {
      // state.data.selectedInvition
      state.pendingList = state.pendingList.filter(
        (e: any) => e.uuid != state.data.selectedInvition.uuid
      );
      state.data.selectedInvition = {};
    }
    function viewInvitationDetail(invitation: any) {
      const invitationId = invitation.related_id;
      // console.log("CHECK INVITATION >>> ", invitationId );
      router.push({
        name: "user-project-acknowledgement",
        params: { invitationId: invitationId },
      });
    }
    // const invitationReload = inject('reload', true)
    // console.log("CHECK RELOAD >>> ", invitationReload)
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }

    function acceptInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.postLoading.acceptInvitation) return false;
      const payLoad = {
        uuid: uuid,
        // uuid: state.uuId
      };
      state.postLoading.acceptInvitation = true;

      ethitransService
        .joinInvitation(payLoad)
        .then((res: any) => {
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.acceptInvitation = false;
        });
    }
    function declineToDoInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.declineInvitation) return false;
      state.postLoading.declineInvitation = true;
      ethitransService
        .declineToDoInvitation(uuid.toString())
        .then((res: any) => {
          // console.log("CHECK declineInvitation:>>", res.data);
          removeSelectedInvitation();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.declineInvitation = false;
        });
    }
    function declineInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.declineInvitation) return false;
      state.postLoading.declineInvitation = true;
      const payLoad = {
        uuid: uuid,
        description: state.data.description
        // uuid: state.uuId
      };
      ethitransService
        .declineInvitation(payLoad)
        .then((res: any) => {
          // console.log("CHECK declineInvitation:>>", res.data);
          removeSelectedInvitation();
          hideDeleteInvitation();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.declineInvitation = false;
        });
    }
    function declinePhaseInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.declineInvitation ) return false;
      if (!state.data.description) return false;
      const payLoad = {
        id: uuid.toString(),
        description: state.data.description
      };
      state.postLoading.declineInvitation = true;
      ethitransService
        .declinePhaseInvitation(payLoad)
        .then((res: any) => {
          // console.log("CHECK declineInvitation:>>", res.data);
          // state.pendingList = res.data ? res.data : [];
          removeSelectedInvitation();
          hideDeleteInvitation();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.declineInvitation = false;
        });
    }

    function declineProjectShareInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.declineInvitation ) return false;
      const payLoad = {
        id: uuid.toString(),
        // description: state.data.description
      };
      state.postLoading.declineInvitation = true;
      ethitransService
        .declineProjectShareInvitation(payLoad)
        .then((res: any) => {
          // console.log("CHECK declineInvitation:>>", res.data);
          // state.pendingList = res.data ? res.data : [];
          removeSelectedInvitation();
          hideDeleteInvitation();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.declineInvitation = false;
        });
    }

    function acceptTodoInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.postLoading.acceptInvitation) return false;
      state.postLoading.acceptInvitation = true;

      ethitransService
        .acceptToDoInvitation(uuid.toString())
        .then((res: any) => {
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.acceptInvitation = false;
        });
    }
    function acceptPhaseInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.postLoading.acceptInvitation) return false;
      state.postLoading.acceptInvitation = true;

      ethitransService
        .acceptPhaseInvitation(uuid.toString())
        .then((res: any) => {
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.data.error.errorsList = res.error;
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.postLoading.acceptInvitation = false;
        });
    }

    function acceptProjectInvitation(uuid: number) {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.acceptInvitation) return false;
      const payLoad = {
        id: uuid,
      };
      state.postLoading.acceptInvitation = true;

      ethitransService
        .joinProjectShareInvitation(payLoad)
        .then((res: any) => {
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.postLoading.acceptInvitation = false;
        });
    }

    function join(selectedInvition: any) {
      state.data.selectedInvition = selectedInvition;
      if (selectedInvition.type === 1) {
        // normal invitation
        console.log("SELECTED >>> ", selectedInvition);
        acceptInvitation(selectedInvition.uuid);
      } else if (selectedInvition.type === 2) {
        // todo invitation
        console.log("SELECTED >>> ", selectedInvition);
        acceptTodoInvitation(selectedInvition.uuid);
      } else if (
        selectedInvition.type === 3 ||
        selectedInvition.type === 5 ||
        selectedInvition.type === 7 ||
        selectedInvition.type === 6
      ) {
        // phase invitation
        console.log("SELECTED >>> ", selectedInvition);
        acceptPhaseInvitation(selectedInvition.uuid);
      }
      else if(selectedInvition.type === 8){
        acceptProjectInvitation(selectedInvition.uuid);
      }
    }
    function decline() {
      // state.data.selectedInvition = selectedInvition;
      if (state.data.selectedInvition.type === 1) {
        // normal invitation
        declineInvitation(state.data.selectedInvition.uuid);
      } else if (state.data.selectedInvition.type === 2) { // dont comes in after
        // todo invitation decline
        console.log("SELECTED DECLINE>>> ", state.data.selectedInvition);
        declineToDoInvitation(state.data.selectedInvition.uuid);
      } else if (
        state.data.selectedInvition.type === 3 ||
        state.data.selectedInvition.type === 5 ||
        state.data.selectedInvition.type === 7 ||
        state.data.selectedInvition.type === 6
      ) {
        // phase invitation decline
        declinePhaseInvitation(state.data.selectedInvition.uuid);
        console.log("SELECTED DECLINE>>> ", state.data.selectedInvition);
      }
      else if (
        state.data.selectedInvition.type === 8
      ) {
        declineProjectShareInvitation(state.data.selectedInvition.uuid);
      }
      console.log("SELECTED DECLINE>>> ", state.data.selectedInvition);
    }
    function getInvitationPending() {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      state.isLoading = true;

      ethitransService
        .invitationPendingList()
        .then((res: any) => {
          console.log("CHECK PENDING LIST", res.data.data);
              res.data.data.forEach((response: any) => {
              response.createdLatest = common.localDateAndTime(response.created_at);
            });
          res.data.data.forEach((item: any) => {
            item.isJoinStatus = false;
            item.isDeclineStatus = false;
          });
          state.pendingList = res.data.data ? res.data.data : [];

        })
        .catch((error: any) => {
          // console.log("WOWOW",error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }

              // console.log("CHECK for Error >>>", res)
              // if (res) {

              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     // state.data.error.errorsList.push(res.error[0]);
              //     // state.data.error.errorsList = [...state.data.error.errorsList]
              //   } else {
              //     // state.data.error.errorsList.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.isLoading = false;
        });
    }
    const userAllInfo: any = localStorage.getItem("current_user_info");
    const userInfo: any = JSON.parse(userAllInfo);
    state.data.loggedInUserUUID = userInfo.uuid;
    // console.log("<<< CHECK UUID:>>>>", state.data.loggedInUserUUID);
    // Pusher.logToConsole = true;s
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
    });

    const channel = pusher.subscribe(
      `refreshInvitationByUuid.${state.data.loggedInUserUUID}`
    );
    channel.bind("invitation.refresh", function (data: any) {
      getInvitationPending();
    });
    function userStatus() {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      const payLoad = {
        email: localStorage.getItem("current_user_email"),
      };
      state.ui.isLoading.userStatus = true;
      ethitransService
        .userStatus(payLoad)
        .then((res: any) => {
          if (res.data.data.online_status === "offline") {
            router.push({
              name: "admin-login",
            });
            signOut();
          } else {
            getInvitationPending();
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isLoading.userStatus = false;
        });
    }

    function redirectToChannelInvitation(projectId: any) {
      router.push({
        name: "admin-channel-invitaion",
        params: { id: projectId },
      });
    }

    watch(
      // () => watchTour,
      () => route.query.id,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.data.selectedUuid = newvalue;
          // console.log("ROLLED >>>", newvalue)
          userStatus();
        }
      }
    );

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      // console.log("CHECK THE QUERY >>> ", route.query.id)
      state.data.selectedUuid = route.query.id;

      userStatus();
      const token = localStorage.getItem("current_user_uuid");
      // console.log('CHECK LOCAL STORAGE UUID', userInfo.uuid);
      let UUID = "";
      if (token) {
        UUID = token.replace(/"/gi, "");
      }
      state.uuId = UUID;
      // console.log("<<< CHECK UUID:>>>>", state.data.loggedInUserUUID);
    });
    return {
      state,
      hideDeleteInvitation,
      showDeclineModal,
      hideInvitationDetails,
      detailsModal,
      declineToDoInvitation,
      removeSelectedInvitation,
      userStatus,
      ...toRefs(state),
      getInvitationPending,
      acceptInvitation,
      declineInvitation,
      closeSuccessModal,
      join,
      decline,
      acceptTodoInvitation,
      acceptPhaseInvitation,
      declinePhaseInvitation,
      viewInvitationDetail,
      acceptProjectInvitation,
      declineProjectShareInvitation,
      projectInvitationDetail,
      redirectToChannelInvitation,
      jobEstimationDetails
    };
  },
});
