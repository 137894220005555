
import { defineComponent, onMounted, reactive, watch } from "vue";
import Editor from "@tinymce/tinymce-vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CreateBusinessMailTemplate",
  props: {
    showModal: Boolean,
  },
  components: {
     Editor,
    SuccessfulModal,
    ErrorList,
  },
  setup(props, { emit }) {
   const router = useRouter();
    const state = reactive({
      title: "" as any,
      template: "" as any,
      data: {
        SuccessFulMessage: "",
        showSuccesModal: false,
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          getUserActivityList: false,
          saveEditUser: false,
        },
         error: {
          validationList: [] as any,
          hasError: false,
        },
      },

      newSearchData: {
        emailError: false,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });
    function serachValidation() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      if (!state.title) {
        state.ui.error.hasError = true;
        state.ui.error.validationList.push("Title is required.");
      }
       if (!state.template) {
        state.ui.error.hasError = true;
        state.ui.error.validationList.push("Template is required.");
      }
    }

 
  function backToBusinessMail(){
      router.push({
          name: "business-mail-template",
        });
    }
    function createBusinessMailTemplate() {
      state.ui.isPostLoading.getUserActivityList = true;
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      serachValidation();
      const payLoad = {
        title: state.title,

      // eslint-disable-next-line @typescript-eslint/camelcase
        mail_template: state.template,
      };
      ethitransService
        .createBusinessMailTemplate(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.title = "";
          state.template = "";
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.SuccessFulMessage = "Mail Template created successfully.";
          // backToBusinessMail();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserActivityList = false;
          // backToBusinessMail()
        });
    }

  

    onMounted(() => {
      Editor;
    });

    return {
      state,
      serachValidation,
      createBusinessMailTemplate,
      backToBusinessMail
    };
  },
});
