
import { defineComponent, reactive, onMounted, watch, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Multiselect from 'vue-multiselect';
import { useRoute, useRouter } from "vue-router";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";

export default defineComponent({
  name: "CreateProjectEstimation",
  components: {
    DragAndDropZone,
    ErrorList,
    Multiselect,
    SuccessfulModal
  },
  setup() {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const isInvalid = ref(false);
    const state = reactive({
      projectId: projectId,
      data: {
        showSuccesModal: false,
        imageBaseUrl: '',
        successFulMessage: "",
        todayData: new Date,
        removeUser: {} as any,
        projectEstimations: {
          customer: "",
          customersList: [] as any,
          name: "",
          description: "",
          estimatedBudget: "",
          startDate: "",
          endDate: "",
          attachments: [] as any,
          edocLink: "",
        },
        allCustomer: [] as any,
        selectedCustomers: [] as any,
      },
      ui: {
        isLoading: {
          projectDetailsAPICalled: false,
          enableEndDate: true,
          isEdit: false,
        },
        isPostLoading: {
          removeSelectedImage: false,
          createEstimation: false,
          delete: false,
          updateEstimation: false,
        },
        errors: {
          errorList: {
            approval: [] as any,
          },
          hasError: false,
        },
      },
    });
    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.name = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        state.data.projectEstimations.attachments.push(data);
        console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    };

    function removeSelectedImage(image: any) {
      state.data.projectEstimations.attachments = state.data.projectEstimations.attachments.filter(
        (e: any) => e != image
      );
    }


    function validateForm() {
      state.ui.errors.hasError = false;

      if (!state.data.projectEstimations.startDate) {
        state.ui.errors.errorList.approval.push("Start Date is required.");
      }
      if (!state.data.projectEstimations.endDate) {
        state.ui.errors.errorList.approval.push("End Date is required.");
      }
      if (state.data.projectEstimations.startDate && state.data.projectEstimations.endDate) {
        // console.log("CHECK DATE CHECK >>> ")
        const d1 = new Date(state.data.projectEstimations.startDate).getTime();
        const d2 = new Date(state.data.projectEstimations.endDate).getTime();
        // console.log("CHECK DATE START >>> ", d1)
        // console.log("CHECK DATE END >>> ", d2)
        if (d2 < d1) {
          state.ui.errors.errorList.approval.push("Date is not in Valid Range.");
        }
      }


      if (!state.ui.errors.errorList.approval && !state.ui.errors.errorList.approval.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.approval.length != 0) {
        state.ui.errors.hasError = true;
      }
    }

    function redirectToProjectDetails() {
      router.push({
        name: "admin-project-details",
        params: { projectId: projectId },
      });
    }

    function redirectToProjectToDo() {
      router.push({
        name: "user-todo-list",
        params: { projectId: projectId },
      });
    }

    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.ui.isLoading.isEdit = false;
      $("#successfulModal").modal("hide");
      redirectToProjectToDo();
    }

    function createEstimation() {
      state.ui.errors.errorList.approval = [];
      validateForm();
      if (state.ui.isPostLoading.createEstimation || state.ui.errors.errorList.approval.length != 0 || state.ui.errors.hasError) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        budget: state.data.projectEstimations.estimatedBudget,
        payers: state.data.selectedCustomers,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.projectEstimations.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.projectEstimations.endDate,
        files: state.data.projectEstimations.attachments,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.projectEstimations.edocLink,
      };
      state.ui.isPostLoading.createEstimation = true;
      ethitransService
        .createProjectEstimation(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "Create Estimation Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createEstimation = false;

        });
    }

    function updateEstimation() {
      state.ui.errors.errorList.approval = [];
      validateForm();
      if (state.ui.isPostLoading.createEstimation || state.ui.errors.errorList.approval.length != 0 || state.ui.errors.hasError) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        budget: state.data.projectEstimations.estimatedBudget,
        payers: state.data.selectedCustomers,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.projectEstimations.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.projectEstimations.endDate,
        files: state.data.projectEstimations.attachments,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.projectEstimations.edocLink,
      };
      state.ui.isPostLoading.updateEstimation = true;
      ethitransService
        .createProjectEstimation(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "Update Estimation Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.updateEstimation = false;
        });
    }

    function addCustomerEmail(data: any) {
      isInvalid.value = false;
      const email = data;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email==localStorage.getItem("current_user_email")) {
        isInvalid.value = true;
        return false;
      }
      if (!emailRegex.test(email)) {
        isInvalid.value = true;
        return false;
      }
      const addData = {
        email: data,
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        middle_name: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_name: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        address_line1: '',
        code: data,
        phone: '',
        isEdit: true,
        emailError: '',
        phoneError: '',
      };
      state.data.allCustomer.push(addData);
      state.data.selectedCustomers.push(addData);
      //  state.data.allCustomer.push(data);
    }

    function getProjectDetails() {
      ethitransService
        .getEstimationCreateDetails(projectId)
        .then((res: any) => {
          state.data.projectEstimations.name = res.data.data.project.name;
          state.data.projectEstimations.description = res.data.data.project.description;
          res.data.data.payers.forEach((user: any) => {
            const data = {
              email: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: user.first_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              middle_name: user.middle_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: user.last_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              address_line1: user.address_line1,
              code: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              phone: user.phone,
              isEdit: false,
            };
            state.data.allCustomer.push(data);
          });
          // toggleTourGuide();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function getEstimationDetails() {
      ethitransService
        .getEstimationEditDetails(projectId)
        .then((res: any) => {
          console.log((res.data.data));
          if (res.data.data.payerRequest) {
            state.ui.isLoading.isEdit = true
            state.data.projectEstimations.name = res.data.data.project.name;
            state.data.projectEstimations.description = res.data.data.project.description;
            state.data.projectEstimations.estimatedBudget = res.data.data.payerRequest.budget;
            state.data.projectEstimations.startDate = res.data.data.payerRequest.start_date;
            state.data.projectEstimations.endDate = res.data.data.payerRequest.end_date;
            state.data.projectEstimations.attachments = res.data.data.attachments;
            state.data.projectEstimations.edocLink = res.data.data.payerRequest.edoc_id;
            res.data.data.payer_emails.forEach((user: any) => {
              const data = {
                email: user.email,
                // eslint-disable-next-line @typescript-eslint/camelcase
                first_name: user.first_name,
                // eslint-disable-next-line @typescript-eslint/camelcase
                middle_name: user.middle_name,
                // eslint-disable-next-line @typescript-eslint/camelcase
                last_name: user.last_name,
                // eslint-disable-next-line @typescript-eslint/camelcase
                address_line1: user.address_line1,
                code: user.email,
               // eslint-disable-next-line @typescript-eslint/camelcase
                phone: user.contact_number,
                isEdit: false,
                emailError: '',
                phoneError: '',
              };
              state.data.selectedCustomers.push(data);
            })

          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function showDelete(data: any) {
      state.data.removeUser = data;
      $("#deleteModal").modal("show");
    }

    function hideDelete() {
      $("#deleteModal").modal("hide");
    }

    function isEmailValid(item: any) {
      const email = item.email;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email==localStorage.getItem("current_user_email")) {
        item.emailError = 'Owner email  not valid here.';
        return false;
      }
      if (!emailRegex.test(email)) {
        item.emailError = 'Please enter valid email.';
        return false;
      }
      item.emailError = '';
      return true;
    }

    function isPhoneValid(item: any) {
      const phone = item.phone;
      const phoneRegex = /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
      if (!phoneRegex.test(phone)) {
        item.phoneError = 'Please enter valid phone.';
        return false;
      }
      item.phoneError = '';
      return true;
    }

    function editSelectCustomer(data: any) {
      state.data.selectedCustomers[data].isEdit = true;
    }
    function updateSelectCustomer(data: any) {
      const item = state.data.selectedCustomers[data];
      item.emailError = '';
      item.phoneError = '';
      if (item.phone) {
        if (isEmailValid(item)) {
          item.emailError = '';
          item.phoneError = '';
        }
        if (isPhoneValid(item)) {
          item.emailError = '';
          item.phoneError = '';
          item.isEdit = false;
        }
      } else {
        if (isEmailValid(item)) {
          item.emailError = '';
          item.phoneError = '';
          item.isEdit = false;
        }
      }
    }
    function removeSelectCustomer(data: any) {
      state.data.selectedCustomers = state.data.selectedCustomers.filter((e: any) => e != data);
      hideDelete();
    }

    function changeValue() {
      isInvalid.value = false;
    }

    function removeUploadSelectedImage(image: any) {
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.removeSelectedImage) return false;
      state.ui.isPostLoading.removeSelectedImage = true;
      ethitransService
        .projectDocumentDelete(image.uuid.toString())
        .then((res: any) => {
          state.data.projectEstimations.attachments = state.data.projectEstimations.attachments.filter(
            (e: any) => e != image
          );
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.approval
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedImage = false;
        });

    }
    watch(
      () => state.data.projectEstimations.startDate,
      (newvalue, oldvalue) => {
        state.ui.isLoading.enableEndDate = false
      }
    );

    onMounted(() => {
      getProjectDetails();
      getEstimationDetails();
      state.data.imageBaseUrl = config.imageBaseUrl;
    });
    return {
      state,
      removeSelectedImage,
      dropFiles,
      validateForm,
      createEstimation,
      addCustomerEmail,
      getProjectDetails,
      removeSelectCustomer,
      editSelectCustomer,
      updateSelectCustomer,
      redirectToProjectToDo,
      getEstimationDetails,
      updateEstimation,
      closeSuccessModal,
      showDelete,
      hideDelete,
      isEmailValid,
      isPhoneValid,
      isInvalid,
      changeValue,
      removeUploadSelectedImage,
      redirectToProjectDetails
    };
  },
});
