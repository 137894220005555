
import { defineComponent, reactive, onMounted, ref } from "vue";
import Button from "@/common/Button.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import ToDoPhasePaymentHistory from "@/components/todo/ToDoPhasePaymentHistory.vue";

import ErrorList from "@/components/commonDesign/ErrorList.vue";

export default defineComponent({
  name: "ToDoPhasePaymentRequest",
  props: {
    showAddPhase: {
      type: Boolean,
      required: true,
    },
    phase: {
      type: Object,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
  },
  components: {
    Button,
    ErrorList,
    SuccessfulModal,
    ToDoPhasePaymentHistory,
  },
  setup(props, { emit }) {
    const state = reactive({
      data: {
        buttonName: "Request",
        paymentHistory: {
          contractorPaymentRequests: [] as any,
          payerPaymentRequests: [] as any,
          latest: {} as any,
        },
        paymentRequest: {
          amount: 0,
          detail: "",
        },
        log: {
          todayDate: "",
        },
        imagesToUpload: [] as any,
        showSuccesModal: false,
        successFulMessage: "",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            paymentRequestByPhase: [] as any,
          },
        },
        isLoading: {
          getPaymentHistoryByPhase: false,
        },
        isPostLoading: {
          paymentRequestByPhase: false,
        },
      },
    });
    function acknowledgePayerPayment(data: any) {
      state.data.paymentHistory.payerPaymentRequests =
        state.data.paymentHistory.payerPaymentRequests.filter((e: any) => {
          if (data.uuid === e.uuid) {
            e === data;
          }
        });
    }

    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function removeSelectedImage(image: any) {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
    }
    function closeEmit() {
      emit("close");
    }
    function hideTaskLogModal() {
      $("#phaseTaskPaymentModal").modal("hide");
      closeEmit();
    }
    function acknowledgeContractorPayment(data: any) {
      console.log("ACKNOW >>> ", data);
      state.data.paymentHistory.contractorPaymentRequests =
        state.data.paymentHistory.contractorPaymentRequests.filter((e: any) => {
          console.log("ACKNOW >>> ", e.uuid);
          if (data.uuid.toLowerCase() === e.uuid.toLowerCase()) {
            e === data;
          }
        });
      hideTaskLogModal();
    }
    function update() {
      // if (props.editMode) {
      alert("EDIT MODE");
      //   updatePhase();
      // } else {
      //   createPhase();
      //   // alert("ADD MODE")
      // }
    }
    function getPaymentHistoryByPhase() {
      state.ui.error.errorList.paymentRequestByPhase = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPaymentHistoryByPhase) return false;
      state.ui.isLoading.getPaymentHistoryByPhase = true;
      ethitransService
        .paymentHistoryByPhase(props.phase.uuid.toString(), props.role)
        .then((res: any) => {
          if (
            res.data.data.contractorPaymentRequests &&
            res.data.data.contractorPaymentRequests.length
          ) {
            res.data.data.contractorPaymentRequests.forEach((item: any) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.created_at = common.localDateAndTime(item.created_at);
            });
          }
          if (
            res.data.data.payerPaymentRequests &&
            res.data.data.payerPaymentRequests.length
          ) {
            res.data.data.payerPaymentRequests.forEach((item: any) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.created_at = common.localDateAndTime(item.created_at);
            });
          }
          console.log(
            "PaymentHistoryByPhase Response Check:>>> ",
            res.data.data
          );
          state.data.paymentHistory = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.paymentRequestByPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.paymentRequestByPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getPaymentHistoryByPhase = false;
        });
    }
    // paymentRequestByPhase
    function paymentRequestByPhase() {
      state.ui.error.errorList.paymentRequestByPhase = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.paymentRequestByPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.paymentRequestByPhase.length != 0
      )
        return false;

      const payLoad = {
        id: props.phase.uuid,
        type: props.role,
        amount: state.data.paymentRequest.amount,
        detail: state.data.paymentRequest.detail,
      };
      console.log("CHECK PAYLOAD >>>> ", payLoad);
      // memberSelected.resend = true;
      state.ui.isPostLoading.paymentRequestByPhase = true;
      ethitransService
        .paymentRequestByPhase(payLoad)
        .then((res: any) => {
          console.log("RESPONSE >>> ", res.data.data);
          hideTaskLogModal();
          // closeEmit();
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage =
            "Payment Request is Successfully Sent.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.paymentRequestByPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.paymentRequestByPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.paymentRequestByPhase = false;
        });
    }
    onMounted(() => {
      if (props.showAddPhase) {
        const data = new Date();
        state.data.log.todayDate = data.toDateString();
        $("#phaseTaskPaymentModal").modal("show");
        getPaymentHistoryByPhase();
      }
    });

    return {
      state,
      acknowledgePayerPayment,
      acknowledgeContractorPayment,
      closeEmit,
      getPaymentHistoryByPhase,
      paymentRequestByPhase,
      closeSuccessModal,
      removeSelectedImage,
      hideTaskLogModal,
      update,
    };
  },
});
