
import { defineComponent, reactive, onMounted, watch, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { common } from "@/common/services/Common";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
// import CurrencyInput from '@/common/CurrencyInput.vue'
// import Formatting from "@/components/todo/Formatting.vue";
// import PriceInput from '@/components/todo/PriceInput.vue'; 
import PriceInputField from '@/common/PriceInputField.vue'

// import DebouncedCurrencyInput from "@/common/DebouncedCurrencyInput.vue";


export default defineComponent({
  name: "CreateLineItem",
  components: {
    ErrorList,
    SuccessfulModal,
    // CurrencyInput,
    // Formatting,
    // PriceInput,
    PriceInputField
    // DebouncedCurrencyInput

  },
  setup() {
    const router = useRouter();
    const {
      params: { itemUUId },
    } = useRoute();
    const state = reactive({
      lineItemId: itemUUId,
      selectSubItemIndex: '' as any,
      selectUnitId: '' as any,
      defMarkUpType: '' as any,
      data: {
        inputFrom: 'markUp',
        showSuccesModal: false,
        itemId: "" as any,
        imageBaseUrl: '',
        successFulMessage: "",
        itemName: "",
        itemDetails: "",
        itemCost: 0 as any,
        itemTotal: 0 as any,
        itemMarkup: 0 as any,
        itemMarkupType: '' as any,
        selectedUnit: '' as any,
        subItems: [] as any,
        unitLists: [] as any,
        unit: {
          name: '',
          abbreviation: '',
        },
        selectSubItem: {} as any,
      },
      ui: {
        isLoading: {
          enableSubItem: false,
          projectDetailsAPICalled: false,
          enableEndDate: true,
          isEdit: false,
          isShow: false,
          isUnitEdit: false,
          getUnit: false,
        },
        isPostLoading: {
          removeSelectedImage: false,
          createLineItem: false,
          addUnite: false,
          delete: false,
          updateEstimation: false,
        },
        errors: {
          errorList: {
            createLineItem: [] as any,
            createUnit: [] as any,
          },
          validationErrorList: {
            itemName: "",
            unitName: "",
            unitAbbreviation: "",
            itemUnitError: "",
            itemCostError: "",
          },
          hasError: false,
        },
      },
    });
    function setCost(data: any) {
      const resultString = data.replace(/[$,]/g, '');
      state.data.itemCost = resultString
    }
    function setMarkup(data: any) {
      const resultString = data.replace(/[$,]/g, '');
      state.data.itemMarkup = resultString
    }
    function updateItem(index: any, newValue: any) {
      state.data.subItems[index].cost = newValue;
    }

    function updateItemMarkup(index: any, newValue: any) {
      state.data.subItems[index].markup = newValue;
    }
    function showRemoveSubItem() {
      $("#removeSubItem").modal("show");

    }

    function closeRemoveSubItem() {
      $("#removeSubItem").modal("hide");

    }
    function addUnitShow() {
      $("#addUnite").modal("show");
    }
    function addUniteClose() {
      state.ui.errors.validationErrorList.unitName = "";
      state.ui.errors.validationErrorList.unitAbbreviation = "";

      state.data.unit.abbreviation = "";
      state.data.unit.name = "";
      $("#addUnite").modal("hide");
      state.ui.isLoading.isUnitEdit = false;
    }

    function changeUnit(index: any, value: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.subItems[index].unit_id = value.id;
      state.data.subItems[index].selectUnit = value;
    }


    function selectUnit(item: any) {
      state.data.selectedUnit = item;
    }

    function getAllUnits() {

      state.ui.isLoading.getUnit = true
      ethitransService
        .getUnitList()
        .then((res: any) => {
          state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getUnit = false;
        });

    }

    function addSubItem() {
      // sub_input_file
      // document.getElementById('sub_input_file').click();
      document.getElementById("sub_input_file");
      state.data.subItems = [{
        name: "",
        description: "",
        unit: "",
        cost: 0 as any,
        markup: 0 as any,
        // eslint-disable-next-line @typescript-eslint/camelcase
        markup_type: state.defMarkUpType,
        total: 0 as any,
      }]
      // state.ui.isLoading.enableSubItem = !state.ui.isLoading.enableSubItem;
      state.ui.isLoading.enableSubItem = true;

    }

    function removeAllSubItem() {
      state.data.subItems = []
      state.ui.isLoading.enableSubItem = !state.ui.isLoading.enableSubItem;
      closeRemoveSubItem();
    }

    function checkInputNumber(item: any) {
      const data = item.toString().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
      return data;
    }

    function calculateTotal(item: any) {
      const selectedIndex = common.findIndex(state.data.subItems, item);
      state.data.subItems[selectedIndex].cost = checkInputNumber(item.cost);
      state.data.subItems[selectedIndex].markup = checkInputNumber(item.markup);
      const total = computed(() => {
        if (item.markup_type == 1) {
          const prCal = ((state.data.subItems[selectedIndex].cost ? parseFloat(state.data.subItems[selectedIndex].cost) : 0) * (state.data.subItems[selectedIndex].markup ? parseFloat(state.data.subItems[selectedIndex].markup) : 0)) / 100;
          return prCal + (parseFloat(item.cost) ? parseFloat(item.cost) : 0);
        } else {

          return (state.data.subItems[selectedIndex].cost ? parseFloat(state.data.subItems[selectedIndex].cost) : 0) + (state.data.subItems[selectedIndex].markup ? parseFloat(state.data.subItems[selectedIndex].markup) : 0);
        }

      });
      state.data.subItems[selectedIndex].total = common.usdPriceFormate(total.value);
    }

    function changeMarkupType(index: any, value: any) {
      // state.data.subItems[index].markup = 0;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.subItems[index].markup_type = value;
      calculateTotal(state.data.subItems[index]);
    }

    function calculateiItemTotal() {
      const total = computed(() => {
        if (state.data.itemMarkupType == 1) {
          const prCal = ((state.data.itemCost ? parseFloat(state.data.itemCost) : 0) * (state.data.itemMarkup ? parseFloat(state.data.itemMarkup) : 0)) / 100;
          return prCal + (parseFloat(state.data.itemCost) ? parseFloat(state.data.itemCost) : 0);
        } else {

          return (state.data.itemCost ? parseFloat(state.data.itemCost) : 0) + (state.data.itemMarkup ? parseFloat(state.data.itemMarkup) : 0);
        }

      });
      state.data.itemTotal = common.usdPriceFormate(total.value);
    }

    function addMoreSubItems(item: any) {
      const selectedIndex = common.findIndex(state.data.subItems, item);
      state.data.subItems[selectedIndex].nameError = false;
      state.data.subItems[selectedIndex].unitError = false;
      state.data.subItems[selectedIndex].costError = false;

      if (!item.name) {

        state.data.subItems[selectedIndex].nameError = true;
      }
      if (!item.unit_id) {

        state.data.subItems[selectedIndex].unitError = true;
      }
      if (!item.cost) {

        state.data.subItems[selectedIndex].costError = true;
      }
      if (item.cost && item.name && item.unit_id) {
        state.data.subItems.push({
          name: "",
          description: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          unit_id: "",
          cost: 0 as any,
          markup: 0 as any,
          // eslint-disable-next-line @typescript-eslint/camelcase
          markup_type: state.defMarkUpType,
          total: 0 as any,
        })
      } else {
        return
      }

    }

    function changeItemMarkupType(item: any) {
      // state.data.itemMarkup = 0;
      state.data.itemMarkupType = item;
      calculateiItemTotal();
    }

    function removeSubItem(item: any) {
      state.data.subItems = state.data.subItems.filter((e: any) => e != item);
    }

    function createLineItemFormValidation() {
      state.ui.errors.validationErrorList.unitName = "";
      state.ui.errors.validationErrorList.itemCostError = "";
      state.ui.errors.validationErrorList.itemUnitError = "";
      state.ui.errors.validationErrorList.itemName = "";

      state.ui.errors.hasError = false;
      if (!state.data.itemName) {
        state.ui.errors.validationErrorList.itemName = "Enter item name";
        state.ui.errors.hasError = true;
      }
      if (!state.data.itemCost) {
        state.ui.errors.validationErrorList.itemCostError = "Enter Cost";
        state.ui.errors.hasError = true;
      }
      if (!state.data.selectedUnit) {
        state.ui.errors.validationErrorList.itemUnitError = "Enter Unite";
        state.ui.errors.hasError = true;
      }
      state.data.subItems.forEach((item: any) => {
        item.nameError = false;
        if (!item.name) {

          item.nameError = true;
          state.ui.errors.hasError = true;

        }
        item.unitError = false;
        if (!item.unit_id) {

          item.unitError = true;
          state.ui.errors.hasError = true;

        }
        item.costError = false;
        if (!item.cost) {

          item.costError = true;
          state.ui.errors.hasError = true;

        }
      });
    }


    function createUnitValidation() {
      state.ui.errors.validationErrorList.unitName = "";
      if (!state.data.unit.name) {
        state.ui.errors.validationErrorList.unitName = "Ener valid unit";
        state.ui.errors.hasError = true;
      }
      // if (!state.data.unit.abbreviation) {
      //   state.ui.errors.validationErrorList.unitAbbreviation = "Ener valid abbreviation";
      //   state.ui.errors.hasError = true;
      // }
    }

    function createUnit() {
      state.ui.isPostLoading.addUnite = false;
      state.ui.errors.hasError = false;
      createUnitValidation();
      if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
      const payLoad = {

        name: state.data.unit.name,
        abbreviation: state.data.unit.abbreviation,

      };
      state.ui.isPostLoading.addUnite = true;
      ethitransService
        .createUnit(payLoad)
        .then((res: any) => {
          if (state.selectSubItemIndex) {
            state.data.subItems[state.selectSubItemIndex].unit = res.data.data[0].id;
            state.data.subItems[state.selectSubItemIndex].selectUnit = res.data.data[0];
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.data.subItems[state.selectSubItemIndex].unit_id = res.data.data[0].id;
          } else {
            state.data.selectedUnit = res.data.data[0];
            // eslint-disable-next-line @typescript-eslint/camelcase
          }


          state.data.unitLists = res.data.data;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createUnit)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createUnit.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addUnite = false;
          addUniteClose();

        });
    }

    function updateUnit() {
      state.ui.isPostLoading.addUnite = false;
      state.ui.errors.hasError = false;
      createUnitValidation();
      if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
      const payLoad = {

        name: state.data.unit.name,
        abbreviation: state.data.unit.abbreviation,

      };
      state.ui.isPostLoading.addUnite = true;
      ethitransService
        .updateUnit(state.selectUnitId, payLoad)
        .then((res: any) => {
          if (state.selectSubItemIndex) {
            state.data.subItems[state.selectSubItemIndex].unit = res.data.data[0].id;
            state.data.subItems[state.selectSubItemIndex].selectUnit = res.data.data[0];
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.data.subItems[state.selectSubItemIndex].unit_id = res.data.data[0].id;
          } else {
            state.data.selectedUnit = res.data.data[0];
            // eslint-disable-next-line @typescript-eslint/camelcase
          }


          state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createUnit)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createUnit.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addUnite = false;
          state.selectUnitId = '';
          addUniteClose();

        });
    }

    function updateLineItem() {
      state.ui.isPostLoading.createLineItem = false;
      state.ui.errors.hasError = false;
      createLineItemFormValidation();
      if (state.ui.isPostLoading.createLineItem || state.ui.errors.hasError) return false;
      const payLoad = {

        name: state.data.itemName,
        description: state.data.itemDetails,
        // eslint-disable-next-line @typescript-eslint/camelcase
        unit_id: state.data.selectedUnit.id,
        cost: state.data.itemCost,
        markup: state.data.itemMarkup,
        // eslint-disable-next-line @typescript-eslint/camelcase
        markup_type: state.data.itemMarkupType,
        subitems: state.data.subItems,

      };
      state.ui.isPostLoading.createLineItem = true;
      ethitransService
        .updateLineItem(state.data.itemId, payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "Update LineItem Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createLineItem)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createLineItem.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createLineItem = false;

        });
    }

    function createLineItem() {
      state.ui.isPostLoading.createLineItem = false;
      state.ui.errors.hasError = false;
      createLineItemFormValidation();
      if (state.ui.isPostLoading.createLineItem || state.ui.errors.hasError) return false;
      const payLoad = {
        name: state.data.itemName,
        description: state.data.itemDetails,
        // eslint-disable-next-line @typescript-eslint/camelcase
        unit_id: state.data.selectedUnit.id,
        cost: state.data.itemCost,
        markup: state.data.itemMarkup,
        // eslint-disable-next-line @typescript-eslint/camelcase
        markup_type: state.data.itemMarkupType,
        subitems: state.data.subItems,

      };
      state.ui.isPostLoading.createLineItem = true;
      ethitransService
        .createLineItem(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "Create LineItem Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createLineItem)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createLineItem.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createLineItem = false;

        });
    }

    function gotoItemList() {
      router.push({
        name: "list-line-item",
      });
    }

    function editItem() {
      state.ui.isLoading.isShow = false;
      state.ui.isLoading.isEdit = true;
    }
    function closeSuccessModal() {
      state.ui.isLoading.enableSubItem = false;
      state.data.itemName = "";
      state.data.itemDetails = "";
      state.data.subItems = [];
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.ui.isLoading.isEdit = false;
      $("#successfulModal").modal("hide");
      gotoItemList();
    }

    function addNewUnit(item: any) {
      if (item) {
        state.selectSubItemIndex = '';
        state.selectSubItemIndex = common.findIndex(state.data.subItems, item);
      }

      addUnitShow();
    }

    function deleteUnitList(item: any, unitList: any) {
      if (item) {
        state.selectSubItemIndex = '';
        state.selectSubItemIndex = common.findIndex(state.data.subItems, item);
      }

      ethitransService
        .deleteUnite(unitList.id)
        .then((res: any) => {
          if (item && state.data.subItems[state.selectSubItemIndex].selectUnit) {
            state.data.subItems[state.selectSubItemIndex].selectUnit = '';
          }
          state.data.unitLists = res.data.data;


        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }

    function deleteUnit(item: any, unitList: any) {
      const result = window.confirm('Do you want to delete?');
      if (result) {
        deleteUnitList(item, unitList);
        console.log('User clicked "Yes"');
      } else {

        console.log('User clicked "No"');
      }
    }

    function editUnit(item: any) {
      state.data.unit.name = item.name;
      state.data.unit.abbreviation = item.abbreviation;
      state.selectUnitId = item.id;
      state.ui.isLoading.isUnitEdit = true;
      addUnitShow();
    }
    // function resetSubItem(){
    //   setTimeout(addMoreSubItems(state.data.subItems[state.data.subItems.length -1]), 1000);

    // }

    function itemDetails() {
      ethitransService
        .lineItemDetails(state.lineItemId)
        .then((res: any) => {
          res.data.data.subitems.forEach((subItem: any) => {
            subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
            if (subItem.markup_type == 1) {
              const parCal = (parseFloat(subItem.cost) * parseFloat(subItem.markup)) / 100;
              subItem.total = parCal + parseFloat(subItem.cost);
            } else {
              subItem.total = parseFloat(subItem.cost) + parseFloat(subItem.markup);
            }
          });
          if (res.data.data.markup_type == 1) {
            const parCal = (parseFloat(res.data.data.cost) * (res.data.data.markup ? parseFloat(res.data.data.markup) : 0)) / 100;
            state.data.itemTotal = parCal + parseFloat(res.data.data.cost);
          } else {
            state.data.itemTotal = parseFloat(res.data.data.cost) + (res.data.data.markup ? parseFloat(res.data.data.markup) : 0);
          }
          state.data.itemId = res.data.data.id;
          state.data.itemName = res.data.data.name;
          state.data.itemCost = res.data.data.cost;
          state.data.itemMarkup = res.data.data.markup ? res.data.data.markup : 0;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.itemMarkupType = res.data.data.markup_type;
          state.data.selectedUnit = res.data.data.unit;
          state.data.itemDetails = res.data.data.description;
          res.data.data.subitems.forEach((item: any) => {
            state.data.subItems.push({
              name: item.name,
              id: item.id,
              description: item.description,
              // eslint-disable-next-line @typescript-eslint/camelcase
              unit_id: item.unit_id,
              cost: item.cost,
              markup: item.markup,
              // eslint-disable-next-line @typescript-eslint/camelcase
              markup_type: item.markup_type,
              selectUnit: item.unit,
              total: common.usdPriceFormate(item.total),
            });
          });
          state.data.itemTotal = common.usdPriceFormate(state.data.itemTotal);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }

    function deleteItemShow() {

      $("#deleteItem").modal("show");
    }
    function deleteItemClose() {
      $("#deleteItem").modal("hide");
    }

    function deleteItemApi() {

      ethitransService
        .deleteItem(state.data.itemId)
        .then((res: any) => {
          state.data.successFulMessage = "Delete LineItem Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }

    function deleteSubItemShow(value: any) {

      state.data.selectSubItem = value;
      $("#deleteSubItem").modal("show");
    }
    function deleteSubItemClose() {
      $("#deleteSubItem").modal("hide");
    }

    function deleteSubItemApi() {
      if (!state.data.selectSubItem.id) {
        removeSubItem(state.data.selectSubItem)
        return true
      }
      ethitransService
        .deleteSubItem(state.data.selectSubItem.id)
        .then((res: any) => {
          state.data.subItems = state.data.subItems.filter((e: any) => e != state.data.selectSubItem);

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }

    function userStatus() {

      const payLoad = {
        email: localStorage.getItem("current_user_email"),
      };
      ethitransService
        .userStatus(payLoad)
        .then((res: any) => {
          state.defMarkUpType = res.data.data.markup_type;
          state.data.itemMarkupType = res.data.data.markup_type;
        })
        .catch((error: any) => {
          //
        })
        .finally(() => {
          //   state.ui.isLoading.userStatus = true;
        });
    }

    function updateMarkupType(item: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        markup_type: item,
      };
      ethitransService
        .updateMarkupType(payLoad)
        .then((res: any) => {
          state.defMarkUpType = res.data.data.markup_type;

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }
    onBeforeRouteLeave((to, from) => {
      $("#successfulModal").modal("hide");
      addUniteClose()
      closeRemoveSubItem()
      deleteItemClose()
      deleteSubItemClose()
    })

    onMounted(() => {
      getAllUnits();
      const url: any = window.location.href;
      const page: any = url.split("#/").pop().split("/")[0];
      userStatus();
      if (page === "detail") {
        state.ui.isLoading.isShow = true;
        state.ui.isLoading.enableSubItem = true;
        itemDetails();
      }

      if (page === "edit") {
        state.ui.isLoading.isShow = false;
        state.ui.isLoading.enableSubItem = true;
        state.ui.isLoading.isEdit = true;
        itemDetails();
      }
      state.data.itemTotal = common.usdPriceFormate(state.data.itemTotal);

    });
    return {
      state,
      updateItem,
      setCost,
      addSubItem,
      addMoreSubItems,
      removeSubItem,
      createLineItemFormValidation,
      createLineItem,
      closeSuccessModal,
      gotoItemList,
      changeMarkupType,
      calculateTotal,
      checkInputNumber,
      addNewUnit,
      addUniteClose,
      addUnitShow,
      showRemoveSubItem,
      closeRemoveSubItem,
      getAllUnits,
      createUnitValidation,
      createUnit,
      changeUnit,
      deleteUnit,
      deleteUnitList,
      editUnit,
      updateUnit,
      itemDetails,
      editItem,
      updateLineItem,
      deleteItemShow,
      deleteItemClose,
      deleteItemApi,
      removeAllSubItem,
      deleteSubItemShow,
      deleteSubItemClose,
      deleteSubItemApi,
      selectUnit,
      changeItemMarkupType,
      calculateiItemTotal,
      setMarkup,
      updateItemMarkup,
      userStatus,
      updateMarkupType
    };
  },
});
