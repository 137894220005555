
import { defineComponent, onMounted, reactive } from "vue";

export default defineComponent({
  name: "SuccessfulModal",
  props : {
      showModal : Boolean
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const state = reactive({
      data: {
        folderName: "",
        folderList: [] as any,
        folderTitle: "folder",
        folderHeading: "No folders created",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          createNewFolder: false,
        },
      },
    });
    function closeSuccessfulModal() {
      emit("close");
      $("#successfulModal").modal("hide");
    }
    onMounted(() => {
        if(props.showModal){
            $("#successfulModal").modal("show");
        }
      //   getFolderList();
    });

    return {
      state,
      closeSuccessfulModal,
    };
  },
});
