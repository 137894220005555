
import { defineComponent, onMounted, reactive, ref } from "vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";
import { config } from "@/Config";
import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";

export default defineComponent({
  name: "ProjectImageUploadModal",
  components: {
    DragAndDropZone,
    SuccessfulModal,
    VueEasyLightbox
  },
  props: {
    showModal: Boolean,
    project: String,
  },
  setup(props, { emit }) {
    const {
      // methods
      show, onHide, changeIndex,
      // refs
      visibleRef = ref(false), indexRef = ref(0), imgsRef = ref([])
    } = useEasyLightbox({
      // src / src[]
      imgs: [],
      // initial index
      initIndex: 0
    });

    const state = reactive({
      allSliderImage: [] as any,
      data: {
        selectImageId:"" as any,
        selectImageName:"" as any,
        loginUserId: "" as any,
        folderRename: "" as any,
        imagesToUpload: [] as any,
        imageList: [] as any,
        imageBaseUrl: '' as any,
        project: '' as any,
        showSuccesModal: false,
        SuccessFulMessage: ''
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
          },
        },
        isLoading: {
          uploadImage: false,
        },
        isPostLoading: {
          createNewFolder: false,
          deleteProjectImage: false,
          uploadImage: false,
        },
      },
    });
    function closeDetailModal() {
      emit("close");
      $("#imageUpload").modal("hide");
    }

    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        console.log("UPLOAD READY FILE >>>", data);
        if (data.extension === 'jpg' ||
          data.extension === 'png' ||
          data.extension === 'jpeg' ||
          data.extension === 'gif') {
          state.data.imagesToUpload.push(data);

        }
      };
      reader.readAsDataURL(file);
    }

    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    }

    function imageList() {
      ethitransService
        .getProjectImage(state.data.project.id)
        .then((res: any) => {
          state.data.imageList = res.data.data;

        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadAttachments = false;
        });
    }


    function uploadAttachments() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.project.id,
        files: state.data.imagesToUpload,
      };
      state.ui.isPostLoading.uploadImage = true;
      ethitransService
        .projectImageUpload(payLoad)
        .then((res: any) => {
          state.data.imagesToUpload = [];
          imageList();
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          state.data.SuccessFulMessage = "image upload successfully"
          state.data.showSuccesModal = true;
          state.ui.isPostLoading.uploadImage = false;
        });
    }

    function deleteProjectImage(data: any){
      state.data.selectImageId = data.id
      $("#deleteImage").modal("show");
    }
    function deleteProjectImageClose(){
      $("#deleteImage").modal("hide");
    }
   
    function imageDelete() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        image_id: state.data.selectImageId,
      };
      state.ui.isPostLoading.deleteProjectImage = true;
      ethitransService
        .projectImageDelete(payLoad)
        .then((res: any) => {
          imageList();
          deleteProjectImageClose();
          // emit("updateProjectList", res.data.data);
          // state.data.showSuccesModal = !state.data.showSuccesModal;
          // state.data.successFulMessage = "Project Successfully Hide.";
        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
          //   .then((res: any) => {
          //     state.ui.error.isErrorStatus = true;
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.error.errorsList.projectArchive.push(value[0]);
          //       }
          //     }
          //   });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteProjectImage = false;
        });
    }

  
    function deleteImage(image: any) {
      state.data.imagesToUpload.splice(image, 1);
    }

    function handleHide() {
      visibleRef.value = false;

    }

    function clickImage(communication: any, imageIndex: any) {

      imgsRef.value = [];
      state.allSliderImage = [];
      communication.forEach((file: any) => {
        state.allSliderImage.push(file.file_link);
      });
      imgsRef.value = state.allSliderImage;
      console.log(imgsRef.value)
      setTimeout(() => {
        indexRef.value = imageIndex;
        visibleRef.value = true;
      }, 20);

    }

    function isImgUrl(url: any) {
      return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url)
    }


    function imageSliderNext(index: any, allSliderImage: any) {
      return new Promise((resolve, reject) => {
        const newIndex = index + 1;
        if (isImgUrl(state.allSliderImage[newIndex])) {
          return resolve(newIndex);
        } else {
          if (state.allSliderImage[newIndex + 1]) {
            imageSliderNext(newIndex + 1, state.allSliderImage);
          } else {
            return resolve(-1);
          }

        }
      });

    }


    function imageSliderPre(index: any, allSliderImage: any) {
      return new Promise((resolve, reject) => {
        const newIndex = index - 1;
        if (isImgUrl(state.allSliderImage[newIndex])) {
          return resolve(newIndex);
        } else {
          if (state.allSliderImage[newIndex - 1]) {
            imageSliderPre(newIndex - 1, state.allSliderImage);
          } else {
            return resolve(-1);
          }
        }
      });

    }



    function handlePrev(oldIndex: any, newIndex: any) {
      if (isImgUrl(state.allSliderImage[newIndex])) {
        indexRef.value = newIndex;
        visibleRef.value = true;
      } else {
        const tempIndex = newIndex - 1;
        if (state.allSliderImage[tempIndex]) {
          if (isImgUrl(state.allSliderImage[tempIndex])) {
            indexRef.value = tempIndex;
            visibleRef.value = true;
          } else {
            imageSliderPre(tempIndex, state.allSliderImage).then((index: any) => {
              if (index != -1) {
                indexRef.value = index;
                visibleRef.value = true;
              } else {
                visibleRef.value = false;
              }
            });
          }
        } else {
          visibleRef.value = false;
        }

      }
    }



    function handleNext(oldIndex: any, newIndex: any) {

      if (isImgUrl(state.allSliderImage[newIndex])) {
        indexRef.value = newIndex;
        visibleRef.value = true;
      } else {
        const tempIndex = newIndex + 1;
        if (state.allSliderImage[tempIndex]) {
          if (isImgUrl(state.allSliderImage[tempIndex])) {
            indexRef.value = newIndex + 1;
            visibleRef.value = true;
          } else {
            imageSliderNext(tempIndex, state.allSliderImage).then((index: any) => {
              if (index != -1) {
                indexRef.value = index;
                visibleRef.value = true;
              } else {
                visibleRef.value = false;
              }
            });
          }
        } else {
          visibleRef.value = false;

        }

      }
    }

    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }

    function showRenameImage(data: any){
      state.data.selectImageName= data.file_name
      state.data.selectImageId = data.id
      $("#renameFileModal").modal("show");
    }

    function closeRenameImage(){
      state.data.selectImageName= '';
      state.data.selectImageId=''
      $("#renameFileModal").modal("hide");
    }

    function editName() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: state.data.selectImageId,
                // eslint-disable-next-line @typescript-eslint/camelcase
        file_name: state.data.selectImageName,
      };
      state.ui.isPostLoading.deleteProjectImage = true;
      ethitransService
        .projectImageEdit(payLoad)
        .then((res: any) => {
          imageList();
          closeRenameImage();
          // emit("updateProjectList", res.data.data);
          // state.data.showSuccesModal = !state.data.showSuccesModal;
          // state.data.successFulMessage = "Project Successfully Hide.";
        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
          //   .then((res: any) => {
          //     state.ui.error.isErrorStatus = true;
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.error.errorsList.projectArchive.push(value[0]);
          //       }
          //     }
          //   });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteProjectImage = false;
        });
    }
    onMounted(() => {
      state.data.loginUserId = localStorage.getItem("current_user_id")
      state.data.project = props.project;
      state.data.imageBaseUrl = config.imageBaseUrl;
      imageList();
      if (props.showModal) {
        $("#imageUpload").modal("show");
      }
      //   getFolderList();
    });

    return {
      state,
      closeDetailModal,
      dropFiles,
      readImage,
      deleteImage,
      uploadAttachments,
      imageList,
      clickImage,
      isImgUrl,
      imageSliderNext,
      imageSliderPre,
      handlePrev,
      handleNext,
      visibleRef,
      indexRef,
      imgsRef,
      show,
      onHide,
      handleHide,
      closeSuccessModal,
      deleteProjectImageClose,
      deleteProjectImage,
      imageDelete,
      showRenameImage,
      closeRenameImage,
      editName
    };
  },
});
