
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { useRouter } from "vue-router";
import { each } from "jquery";
import NotifyMailSendModel from "@/components/modal/NotifyMailSendModel.vue";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "UserActivity",
  components: {
    EmptyData,
    SuccessfulModal,
    NotifyMailSendModel,
  },
  setup() {
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const state = reactive({
      userList: [] as any,
      checkUser: [] as any,
      totalUser: "" as any,
      onlineUser: "" as any,
      isCheckedAll: false,
      isExport: false,
      loginFrom: "" as any,
      loginTo: "" as any,
      createdFrom: "" as any,
      createdTo: "" as any,
      filter: {
        selected: { name: "All" } as any,
      },
      sortList: [
        {
          name: "All",
        },
        {
          name: "Online",
        },
        {
          name: "Offline",
        },
      ],
      masks: {
        input: "MM-DD-YYYY",
      },
      url: `${config.apiBaseUrl}user/export`,
      countryCodeList: [] as any,
      data: {
        successMessage: "",
        showSuccesModal: false,
        mailData: {} as any,
        showNotificationMailModel: false,
        selectedUser: {} as any,
        selectedEditUser: {
          phoneDialcode: "+1",
          mobileDialCode: "+1",
          phoneNumber: "" as any,
          mobileNumber: "" as any,
        } as any,
        imageBaseUrl: "",
        title: "User",
        heading: "No User found .",
        searchUser: "",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          getUserActivityList: false,
          saveEditUser: false,
        },
        errors: {
          errorList: {
            saveEditUser: [] as any,
            errorList: [] as any,
          },
          hasError: false,
        },
      },

      newSearchData: {
        loginFromError: false,
        loginToError: false,
        createdFromError: false,
        createdToError: false,
        loginToDisable: true,
        createdToDisable: true,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });

    function serachValidation() {
      console.log("date", moment(state.loginFrom) > moment(state.loginTo));

      state.newSearchData.loginFromError = false;
      state.newSearchData.loginToError = false;
      state.newSearchData.createdFromError = false;
      state.newSearchData.createdToError = false;
      if (state.loginFrom != "" && state.loginTo == "") {
        state.error.loginToError = "Login to date is required";
        state.newSearchData.loginToError = true;
      }

      if (state.loginFrom == "" && state.loginTo != "") {
        state.error.loginFromError = "Login from date is required";
        state.newSearchData.loginFromError = true;
      }

      if (moment(state.loginFrom) > moment(state.loginTo)) {
        state.error.loginFromError =
          "Login from date is less then login to date";
        state.newSearchData.loginFromError = true;
      }
      if (state.createdFrom != "" && state.createdTo == "") {
        state.error.createdToError = "Join to date is required";
        state.newSearchData.createdToError = true;
      }

      if (state.createdFrom == "" && state.createdTo != "") {
        state.error.createdFromError = "Join from date is required";
        state.newSearchData.createdFromError = true;
      }

      if (moment(state.createdFrom) > moment(state.createdTo)) {
        state.error.createdFromError =
          "Join from date is less then Join to date";
        state.newSearchData.createdFromError = true;
      }
    }

    function allActiveUser() {
      state.ui.isPostLoading.getUserActivityList = true;
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      serachValidation();
      const payLoad = {
        loginFrom: state.loginFrom
          ? moment(state.loginFrom).format("YYYY-MM-DD")
          : "",
        loginTo: state.loginTo
          ? moment(state.loginTo).format("YYYY-MM-DD")
          : "",
        createdFrom: state.createdFrom
          ? moment(state.createdFrom).format("YYYY-MM-DD")
          : "",
        createdTo: state.createdTo
          ? moment(state.createdTo).format("YYYY-MM-DD")
          : "",
        sort: state.filter.selected.name,
      };
      ethitransService
        .getAllActiveUser(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.userList = res.data.data.users ? res.data.data.users : [];
          state.totalUser = res.data.data.total_user
            ? res.data.data.total_user
            : 0;
          state.onlineUser = res.data.data.total_online_user
            ? res.data.data.total_online_user
            : 0;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserActivityList = false;
        });
    }

    function exportUser() {
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      // state.isLoading.getProjectRoleList = true;
      const payLoad = {
        checkUser: state.checkUser,
      };
      ethitransService
        .userExport(payLoad)
        .then((res: any) => {
          console.log("expord data", res.data);
          window.open(res.data);
          // state.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.isLoading.getProjectRoleList = false;
        });
    }

    function checkAll() {
      state.isCheckedAll = !state.isCheckedAll;
      state.checkUser = [];
      state.isExport = true;
      if (state.isCheckedAll) {
        // Check all
        for (const user in state.userList) {
          state.checkUser.push(state.userList[user].id);
        }
        console.log("Check Online user", state.checkUser);
      }
      if (state.checkUser.length == 0) {
        state.isExport = false;
      }
    }
    function updateCheckall() {
      if (state.checkUser.length == state.userList.length) {
        state.isCheckedAll = true;
        state.isExport = true;
      } else {
        state.isCheckedAll = false;
        state.isExport = true;
      }
      if (state.checkUser.length == 0) {
        state.isExport = false;
      }
    }

    watch(
      () => [state.loginTo, state.loginFrom],
      (first, second) => {
        if (first.length) {
          state.newSearchData.loginToDisable = false;
        }
        allActiveUser();
      }
    );

    watch(
      () => [state.createdTo, state.createdFrom],
      (first, second) => {
        if (first.length) {
          state.newSearchData.createdToDisable = false;
        }
        allActiveUser();
      }
    );

    function clearSearch() {
      (state.loginFrom = ""),
        (state.loginTo = ""),
        (state.createdFrom = ""),
        (state.createdTo = ""),
        (state.filter.selected = { name: "All" } as any);
      allActiveUser();
    }

    function redireToUserLog(userId: any) {
      router.push({
        name: "admin-user-log",
        params: userId,
      });
    }
    function getCountryCodeList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      ethitransService
        .getCountryCodeList(payLoad)
        .then((res: any) => {
          console.log("getprojectList Check:>>> ", res.data.data);
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    function openUserEditModel(user: any) {
      getCountryCodeList();
      state.data.selectedUser = JSON.parse(JSON.stringify(user));
      state.data.selectedEditUser.name = state.data.selectedUser.full_name;
      state.data.selectedEditUser.mobileDialCode = state.data.selectedUser
        .dial_code_mobile
        ? state.data.selectedUser.dial_code_mobile
        : "+1";
      state.data.selectedEditUser.phoneDialcode = state.data.selectedUser
        .dial_code
        ? state.data.selectedUser.dial_code
        : "+1";
      state.data.selectedEditUser.mobileNumber =
        state.data.selectedUser.mobile_number;
      state.data.selectedEditUser.phoneNumber =
        state.data.selectedUser.contact_number;

      $("#editUserlModal").modal("show");
    }
    function hideEditUserModal() {
      state.data.selectedUser = {};
      $("#editUserlModal").modal("hide");
    }

    function saveEditUser() {
      console.log("save edit channel");
      state.ui.errors.errorList.saveEditUser = [];
      state.ui.errors.hasError = false;
      // console.log("CHECK THE SELECTED >>> ", memberSelected);
      if (state.ui.isPostLoading.saveEditUser) return false;

      const payLoad = {
        id: state.data.selectedUser.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code: state.data.selectedEditUser.phoneDialcode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.selectedEditUser.mobileDialCode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.selectedEditUser.phoneNumber,
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.selectedEditUser.mobileNumber,
      };
      // memberSelected.resend = true;
      state.ui.isPostLoading.saveEditUser = true;
      ethitransService
        .updateUserProfileContact(payLoad)
        .then((res: any) => {
          console.log("saveEditUser:>>> ", res.data.data);
          allActiveUser();
          hideEditUserModal();
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successMessage = "User Edited Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.saveEditUser)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.saveEditUser.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.saveEditUser = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }

    function mailData(data: any) {
      console.log("emit data to here", data);
      state.data.mailData.push(data);
    }

    function showNotificationMailModel() {
      state.data.showNotificationMailModel =
        !state.data.showNotificationMailModel;
    }
    function closeAppointmentModal() {
      $("#showNotificationMailModel").modal("hide");
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      allActiveUser();
      getCountryCodeList();
    });
    return {
      state,
      allActiveUser,
      redireToUserLog,
      exportUser,
      checkAll,
      updateCheckall,
      clearSearch,
      serachValidation,
      getCountryCodeList,
      openUserEditModel,
      hideEditUserModal,
      saveEditUser,
      mailData,
      showNotificationMailModel,
      closeAppointmentModal,
    };
  },
});
