import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
// vue-cookie-nex
// import { ProCalendar } from "vue-pro-calendar";

// import VueTelInput from 'vue3-tel-input'
// import 'vue3-tel-input/dist/vue3-tel-input.css'

// import VueTelInput from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'
import Pusher from "pusher-js"
// import VCalendar from 'v-calendar';
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
// import GoogleAuth from 'vue-google-auth'
// import GoogleLogin from 'vue-google-login';
import GSignInButton from 'vue-google-signin-button'
// import GoogleSignInButton from 'vue-google-signin-button-directive'
// Pusher.logToConsole = true;



import $ from "jquery";
import bootstrap from "bootstrap";
// import 'bootstrap';
import 'vue-select/dist/vue-select.css';
// import VueChatScroll from 'vue-chat-scroll'

// import VueMeta from 'vue-meta'

// declare global {
//     interface Window {
//         Echo: any;
//         Pusher: any;
//     }
// }
// // declare global const window: any

// import Echo from 'laravel-echo';
// // console.log("CHECK Window", window)
// window.Pusher = Pusher;
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: '09208b42687e79917dd5',
//     cluster: 'ap2',
//     // forceTLS: true
//     // wsHost: process.env.VUE_APP_WEBSOCKETS_KEY,
//     // wsPort:6001,
//     // cluster: process.env.PUSHER_APP_CLUSTER,
//     // forceTLS: false,
//     // disableStats: true
//     // cluster: 'eu',
//     // encrypted: true
// });





// import VueCookies from 'vue-cookies'
// app.use(VueCookies)

// import VueTelInput from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'
// import VueTelInput from 'vue3-tel-input'
//   import 'vue3-tel-input/dist/vue3-tel-input.css'

//   const VueTelInputOptions = {
//     mode: "international",
//     onlyCountries: ["US", "CA", "NP"]
//   }



// resources/assets/js/bootstrap.js

// import Echo from "laravel-echo"

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: '09208b42687e79917dd5',
//     cluster: 'eu',
//     encrypted: true
// import CKEditor from '@ckeditor/ckeditor5-vue';
// }); 

import { VueCookieNext } from 'vue-cookie-next'

import vueCountryRegionSelect from 'vue3-country-region-select'
createApp(App).use(router).use(store).use(GSignInButton).use(VCalendar).use(vueCountryRegionSelect).use($).use(VueCookieNext).mount('#app')
// createApp(App).use(router).use(store).use(GSignInButton).use(VCalendar).use(VueTelInput).mount('#app')
// createApp(App).use(router).use(store).use(GSignInButton).use(VCalendar).use(VueTelInput).mount('#app')
// createApp(App).use(router).use(store).use(GSignInButton).mount('#app')
