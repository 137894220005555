
import { defineComponent, onMounted, reactive } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import AddNotification from "@/components/modal/AddNotification.vue";
import { config } from "@/Config";
import { useRoute } from "vue-router";
// import Axios from "axios";
// @ts-ignore-start
import handleClientLoad = require("../../../public/js/calendarAuth.js");
// @ts-ignore-end
// const handleClientLoad =  import("../../../public/js/calendarAuth");

interface TimeSlotType {
  id: string;
  // calendarId: "1";
  title: string;
  body: string;
  isReadOnly: string;
  isPrivate: string;
  // location: "1550 Mubugi Court";
  attendees: Array<string>;
  recurrenceRule: string;
  state: string;
  goingDuration: number;
  comingDuration: number;
  isAllday: string;
  category: string;
  dueDateClass: "morning";
  start: Date;
  end: Date;
  bgColor: string;
  notifications: Array<object>;
}

export default defineComponent({
  name: "CreateTimeSlot",
  props: {
    showModal: Boolean,
    action: String,
    bgColor: String,
    timeSlot: {
      type: Object as () => TimeSlotType,
      required: true,
    },
  },
  components: {
    ErrorList,
    SuccessfulModal,
    AddNotification,
  },
  setup(props, { emit }) {
    const gapi = window.gapi;
    const router = useRoute();
    const state = reactive({
      data: {
        notifications: [] as any,
        showSuccesModal: false,
        SuccessFulMessage: "",
        invitedUser: "",
        bgColor: "#fdc689",
        allDay: false,

        range: {
          start: props.timeSlot ? new Date(props.timeSlot.start) : new Date(),
          end: props.timeSlot ? new Date(props.timeSlot.end) : new Date(),
        },
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        masks: {
          input: "YYYY-MM-DD h:mm A",
        },
        meetingVia: [
          // {
          //   name: "Skype",
          //   value: "skype",
          // },
          // {
          //   name: "Google",
          //   value: "google",
          // },
          // {
          //   name: "Zoom",
          //   value: "zoom",
          // },
          {
            name: "Physical",
            value: "physical",
          },
        ],
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
      routeValue: router.query.showModel,
      errorList: [] as any,
      isErrorStatus: false,
      postLoadingCreateAppointmen: false,
      meetingType: "" as any,
      title: "" as string,
      location: "" as string,
      note: "" as string,
      inviteUesr: [] as any,
      meetingUrl: "" as string,
      meetingCode: "" as string,
      googleMeetURL: "" as any,
      auth2: {},
      googleUser: {} as any,
      googleSignInParams: {
        /* eslint-disable @typescript-eslint/class-name-casing */
        /* eslint-disable @typescript-eslint/camelcase */
        client_id:
          "268712205523-dpbrhqpqu6cpbjdmp83ia9a7nhk2vu5k.apps.googleusercontent.com",
      },
    });
    //  function checkGoolgeLogin(){
    //    if(gapi.auth2.getAuthInstance().isSignedIn.get()){
    //      state.data.meetingVia.unshift({
    //           name: "Google",
    //           value: "google",

    //         },)
    //    }
    //   }
    function updateNotificationList(data: any) {
      state.data.notifications = data;
    }
    function slotcolorSelector(color: string) {
      state.data.bgColor = color;

      // state.data.bgColor
    }

    function closeAppointmentModal() {
      emit("closeModal");
      $("#timeslotCreateModel").modal("hide");
    }

    function addAppointment() {
      emit("addAppointment", "addAppointment success");
      $("#timeslotCreateModel").modal("hide");
    }

    function validateForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.title) {
        state.errorList.push("Appointment title is required.");
      }
      if (!state.meetingType) {
        state.errorList.push("Meeting via is required.");
      }

      if (state.data.tags.value.length == 0) {
        state.errorList.push("Invite user is required.");
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }
    function scrollToTop() {
      $("#appointmentModelScroll").animate({
        scrollTop: $("#appointmentModelScroll")[0],
      });
    }

    async function saveAppointment() {
      // scrollToTop();
      // validateForm();
      const data = {
        id: props.timeSlot.id,
        // calendarId: "1",
        title: props.timeSlot.title,
        body: "",
        new: true,
        isReadOnly: false,
        isPrivate: false,
        // location: "1550 Mubugi Court",
        attendees: props.timeSlot.attendees,
        recurrenceRule: "",
        state: "Free",
        goingDuration: 0,
        comingDuration: 0,
        // raw: {
        //   memo: "Cowrovca jelhe adodebi po pe konuhi daihno uv uri inrablo zugwehu ho reitoviv tamevit abuw ve bor.",
        //   creator: {
        //     name: "Lily Thompson",
        //     avatar:
        //       "//www.gravatar.com/avatar/49604855d26621098bf82c1cda92ac72",
        //     email: "nezwoew@gosce.tm",
        //     phone: "(445) 632-7003",
        //   },
        // },
        isAllday: false,
        category: "time",
        dueDateClass: "morning",
        start: state.data.range.start,
        end: state.data.range.end,
        // bgColor: state.data.bgColor,
      } as any;
      if (props.action === "new") {
        data.bgColor = state.data.bgColor;
        if (state.data.allDay) {
          data.isAllday = true;
          data.category = "allday";
        }
        data.notifications = state.data.notifications;
        emit("addAppointment", data);
      } else if (props.action === "edit") {
        data.bgColor = state.data.bgColor;
        if (state.data.allDay) {
          data.isAllday = true;
          data.category = "allday";
        }
        data.notifications = state.data.notifications;
        emit("editAppointment", data);
      }
      closeAppointmentModal();
      // addNewAppointment();
    }

    onMounted(() => {
      console.log('The time slot props data is', JSON.stringify(props.timeSlot, null, 2));
      if (props.showModal) {
        if (props.timeSlot.bgColor) {
          state.data.bgColor = props.timeSlot.bgColor;
        }
        if (props.action === "edit") {
          //set the notification List
          if (props.timeSlot.notifications) {
            // setTimeout(() => {
              state.data.notifications = props.timeSlot.notifications;
            // }, 100);
          }
        }
        //     showModal: Boolean,
        // action: String,
        // timeSlot: Object,
        state.title = props.timeSlot.title;
        const dateRange = {} as any;
        (dateRange.start = props.timeSlot.start),
          (dateRange.end = props.timeSlot.end),
          (state.data.range = dateRange);
          if(props.timeSlot.attendees && props.timeSlot.attendees.length){

            state.data.invitedUser = props.timeSlot.attendees[0];
          }
        $("#timeslotCreateModel").modal("show");
        // getZoomAccessToken();

        // checkGoolgeLogin();
        // saveEventGoogleCalendar();
      }
      //   getFolderList();
    });

    return {
      state,
      updateNotificationList,
      slotcolorSelector,
      addAppointment,
      closeAppointmentModal,
      validateForm,
      scrollToTop,
      // checkGoolgeLogin,
      saveAppointment,
    };
  },
});
