
import { defineComponent, reactive, onMounted, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import { validationService } from "@/common/ValidationService";
import { useStore } from "vuex";
import introJs from "intro.js";

// import AppTour from "@/components/modal/AppTour.vue";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "EnvelopeDocumentList",
  components: {
    // AppTour,
    EmptyData,
    SuccessfulModal,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      inviteMember: [] as any,
      invite: {
        title: "" ,
        users: [] as any,
        invites: [] as any[],
        filteredUsers: [] as any[],
        filteredInvites: [] as any[],
      },
      data: {
        userList: [] as any,
        imageBaseUrl: "",
        edocUploadedList: false,
        showSuccesModal: false,
        successFulMessage: "",
        selectedForDelete: {} as any,
        selectDocument:{} as any,
        clientDocuments: [] as any,
        clientReDocuments: [] as any,
        clientReceiveDocuments: [] as any,
        clientPdfs: [] as any,
        searchUser: "",
        title: "pdf",
        heading: "Get started by uploading pdf to digitize the form.",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
          clientDocumentList: false,
        },
        isPostLoading: {
          templateCreating: false,
          uploadClientDocument: false,
          enableClientDocument: false,
          disableClientDocument: false,
          deleteClientDocument: false,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
        },
      },
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome to Upload Pdf! 👋 ",
                intro:
                  "Welcome to Convorally! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Upload Pdf",
                element: document.getElementById(
                  "uploadPdfForDigitize"
                ) as HTMLInputElement,
                intro: "Upload Pdf to Digitize.",
              },
              {
                title: "Uploaded Pdfs",
                element: document.getElementById(
                  "uploadedPdfTemplate"
                ) as HTMLInputElement,
                intro: "Uploaded Pdf for Digitized and its status",
              },
            ],
          })
          .start();
      }
    }
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }

    function docusignTempleteDetails(uuid: any) {
        ethitransService
        .docusignTempleteDetails(uuid)
        .then((res: any) => {
          $("#responseModal").modal("show");
          state.data.selectDocument=res.data.data;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadClientDocument = false;
        });
    }


    function createTemplate() {
      // state.ui.isPostLoading.uploadClientDocument = true;
      //   ethitransService
      //   .createDocusignTemplate()
      //   .then((res: any) => {
      //     // window.open(res.data.data.url, '_blank', 'noreferrer');
      //     window.location.href =  res.data.data.url;

      //   })
      //   .catch((error: any) => {
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     state.ui.isPostLoading.uploadClientDocument = false;
      //   });
    }

   
    function hideResponseModal() {
      $("#responseModal").modal("hide");
    }
    function uploadPdf() {
      if (state.data.clientPdfs && state.data.clientPdfs.length) {
        state.data.clientPdfs.forEach((item: any) => {
          // uploadClientDocument(item);
          state.data.clientPdfs = state.data.clientPdfs.filter(
            (data: any) => data != item
          );
        });
      }
    }

    function readImage(file: any) {
      // console.log("FILE CHECK", file)
      const data = {} as any;
      const files = [] as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);

        if (extension != "pdf") return false;
        data.base64 = e.target.result;
        data.fileName = file.name.slice(0, file.name.lastIndexOf("."));
        // console.log("PDF CHECK", file);
        console.log("PDF CHECK", data);
        // data.extension = extension;
        // data.size = common.formatBytes(parseInt(file.size));
        // console.log("UPLOAD READY FILE >>>", data)
        // files.push(data)
        state.data.clientPdfs.push(data);
        // state.imageList.push(data);
        // if(files && files.length){

        //   files.forEach((item: any)=> {
        //     console.log("CHECK", item)
        uploadPdf();
        //   })
        // }
      };
      reader.readAsDataURL(file);
    }
    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      // console.log("DOCUMENT 1111", event.target.files)
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("DOCUMENT", file)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        if (extensionLower != "pdf") return false;
        readImage(file);
      }
    }
    function openPdf(pdfLink: any) {
      // alert(pdfLink)
      window.open(pdfLink);
    }

    function enableDocument(document: any) {
      // console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.uuid,
      };
      state.ui.isPostLoading.enableClientDocument = true;
      document.enableStatus = true;
      ethitransService
        .enableClientDocument(payLoad)
        .then((res: any) => {
          // console.log("enableClientDocument :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.enableStatus = false;
              item.disableStatus = false;
              item.deleteStatus = false;
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          // if(res.data.data){
          //   res.data.data.convertedFileSize = common.formatBytes(
          //           parseInt(
          //             res.data.data.file_size
          //           )
          //         );
          //   state.data.clientDocuments.unshift(res.data.data);
          // }
          state.data.successFulMessage = "Document Enabled Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableClientDocument = false;
        });
    }
    function disableDocument(document: any) {
      // console.log("Disable Doc >> ", document);
      if (document.disableStatus) return false;

      const payLoad = {
        id: document.uuid,
      };
      state.ui.isPostLoading.disableClientDocument = true;
      document.disableStatus = true;
      ethitransService
        .disableClientDocument(payLoad)
        .then((res: any) => {
          console.log("disableClientDocument :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.enableStatus = false;
              item.disableStatus = false;
              item.deleteStatus = false;
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          // if(res.data.data){
          //   res.data.data.convertedFileSize = common.formatBytes(
          //           parseInt(
          //             res.data.data.file_size
          //           )
          //         );
          //   state.data.clientDocuments.unshift(res.data.data);
          // }
          state.data.successFulMessage = "Document Disabled Successfully.";
          state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.disableClientDocument = false;
        });
    }
    function showInviteModel(){
      $("#inviteMember").modal("show");

    }
    function hideInviteModel() {
      // alert("Add New Members");
      $("#inviteMember").modal("hide");
    }
    function showDeleteModal(document: any) {
      state.data.selectedForDelete = {};
      $("#deleteConfirmeModal").modal("show");
      state.data.selectedForDelete = document;
    }
    function deleteDocument() {
      // console.log("Delete Doc >> ", document);
      if (state.data.selectedForDelete.deleteStatus) return false;
      const payLoad = {
        id: state.data.selectedForDelete.uuid,
      };
      state.ui.isPostLoading.deleteClientDocument = true;
      // document.deleteStatus = true;
      ethitransService
        .deleteClientDocument(payLoad)
        .then((res: any) => {
          console.log("deleteClientDocument :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.enableStatus = false;
              item.disableStatus = false;
              item.deleteStatus = false;
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          // if(res.data.data){
          //   res.data.data.convertedFileSize = common.formatBytes(
          //           parseInt(
          //             res.data.data.file_size
          //           )
          //         );
          //   state.data.clientDocuments.unshift(res.data.data);
          // }
          $("#deleteConfirmeModal").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.deleteClientDocument = false;
        });
    }
    function clientDocumentList() {
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .getSendEnvelopeDocumentList()
        .then((res: any) => {
            state.data.clientDocuments = res.data.data;
          state.data.edocUploadedList = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }

    function signDocument(uuid: any) {
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .envelopeSign(uuid)
        .then((res: any) => {
          window.location.href =  res.data.data.url;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }


    function clientReceiveDocumentList() {
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .getReceivesEnvelopeDocumentList()
        .then((res: any) => {
          state.data.clientReceiveDocuments = res.data.data;
          state.data.edocUploadedList = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }
    function copyDocumentSharableLink(inputId: any) {
      // console.log("CHECK INPUT ID >>>", inputId)
      const copyText: any = document.getElementById(inputId);

      // console.log("CHECK INPUT >>>", copyText)
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      // /* Copy the text inside the text field */
      document.execCommand("copy");
    }

    function allActiveUser() {
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      const payLoad = {};
      ethitransService
        .getAllActiveUser(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.data.userList = res.data.data.users ? res.data.data.users : [];
        //   state.totalUser = res.data.data.total_user
        //     ? res.data.data.total_user
        //     : 0;
        //   state.onlineUser = res.data.data.total_online_user
        //     ? res.data.data.total_online_user
        //     : 0;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.getUserActivityList = false;
        });
    }

    function changeInviteMember() {
      if (validationService.isValidEmail(state.inviteMember)) {
        // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.invite.invites.length === 0) {
          state.invite.invites.push(state.inviteMember);
        } else {
          state.invite.invites.forEach((member: any) => {
            console.log("member", member);
            if (member != state.inviteMember) {
              state.invite.invites.push(state.inviteMember);
              return false;
            }
          });
        }
        state.invite.invites = state.invite.invites.filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
        state.inviteMember = "";
      }
    }

    function removeSelectedUser(item: any) {
      console.log("check the sELCTIOn", item);
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);
      console.log("AFTER DELECTION", state.invite.invites);
    }


    function createEvelopeDocument() {
      // console.log("UPLOAD READY FILE >>>", pdf)
      // if (!state.inviteMember.length) return false;
      const payLoad = {
        name: state.invite.title,
        recipients: state.invite.invites,
      };
      ethitransService.envelopeCreate(payLoad)
        .then((res: any) => {
          console.log("uploadClientDocument :>>> ", res.data.data);
          window.location.href =  res.data.data.url;

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          hideInviteModel();
          // state.ui.isPostLoading.uploadClientDocument = false;
        });
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      clientDocumentList();
      allActiveUser();
      clientReceiveDocumentList();
      // if (state.data.clientDocuments) {
      //   setInterval(clientDocumentList, 10000);
      // }
    });

    return {
      state,
      toggleTourGuide,
      onDocumentChange,
      readImage,
      createEvelopeDocument,
      openPdf,
      enableDocument,
      disableDocument,
      deleteDocument,
      clientDocumentList,
      copyDocumentSharableLink,
      showDeleteModal,
      uploadPdf,
      hideResponseModal,
      closeSuccessModal,
      createTemplate,
      docusignTempleteDetails,
      showInviteModel,
      hideInviteModel,
      changeInviteMember,
      removeSelectedUser,
      clientReceiveDocumentList,
      signDocument

    };
  },
});
