
import { defineComponent, reactive, onMounted, watch, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import DocusignDocumentListTable from "@/components/commonDesign/AppDocusignTable.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'


import { useStore } from "vuex";
import introJs from "intro.js";


export default defineComponent({
  name: "DocusignDocumentList",
  components: {
    UpgradeSubscriptionModal,
    SuccessfulModal,
    DocusignDocumentListTable,
    ErrorList,

  },
  setup() {
    const store = useStore();
    const state = reactive({
      data: {
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        documentName: "",
        docuSignUrl: "",
        docuSignSignEditUrl: "",
        fromName: "docusignDocumentUpload",
        uploadMaxSize: 25 * 1024 * 1024 as any,
        from: 'DocumentList',
        hearder: [
          {
            name: 'Title'
          },
          {
            name: 'Action'
          }
        ],
        imageBaseUrl: "",
        edocUploadedList: false,
        showSuccesModal: false,
        successFulMessage: "",
        selectDocument: {} as any,
        imagesToUpload: [] as any,
        clientDocuments: [] as any,
        title: "pdf",
        heading: "Get started by uploading pdf to digitize the form.",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isActive: {
          upgradeSubscription: false,
        },
        isLoading: {
          packageDetailsByUser: false,
          showDocusignIframe: false,
          getAccountFormList: false,
          clientDocumentList: false,
          docuSignIframeEdit: false
        },
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
            createDocusignDocument: [] as any,
            getFolderList: [] as any,
            fileValid: "",
          },
        },
        isPostLoading: {
          templateCreating: false,
          uploadClientDocument: false,
          enableClientDocument: false,
          disableClientDocument: false,
          deleteClientDocument: false,
        },
      },

    });
    function hideUpdateSubscriptionModal() {
      // alert("Hello")
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }

    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }

    function docusignTempleteDetails(uuid: any) {
      ethitransService
        .docusignTempleteDetails(uuid)
        .then((res: any) => {
          $("#responseModal").modal("show");
          state.data.selectDocument = res.data.data;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadClientDocument = false;
        });
    }

    function envelopeSign(uuid: any) {
      ethitransService
        .envelopeSign(uuid)
        .then((res: any) => {
          $("#responseModal").modal("show");
          state.data.selectDocument = res.data.data;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadClientDocument = false;
        });
    }


    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (extension == "pdf") {
          const extensionLower = extension.toLowerCase();
          data.base64 = e.target.result;
          data.duplicateFile = false; // data.fileName = file.name;
          data.fileName = file.name.replaceAll(`.${extension}`, "");
          if (file.size > state.data.uploadMaxSize) {
            data.isValidToUpload = false;
          } else {
            data.isvalidToUpload = true;
          }
          // console.log("Check the File Status >>>", duplicateName);
          // console.log("Attachment Name Check >>>", data.fileName)
          data.extension = extensionLower;
          data.isuploded = false;
          data.tag = [] as any;
          data.size = common.formatBytes(parseInt(file.size));
          console.log("UPLOAD READY FILE >>>", data);
          state.data.imagesToUpload.push(data);
          state.ui.error.errorList.fileValid = "";
        } else {
          state.ui.error.errorList.fileValid = "Only pdf file is allowed"
        }

        // console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
    }

    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readImage(file);
      }
    }
    function uploadImage() {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => !e.duplicateFile
      );
    }



    const imagesToUpload = computed(() => {
      return state.data.imagesToUpload;
    });

    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    }

    function showUploadDocusignDocument() {
      if (state.data.clientDocuments && state.data.clientDocuments.length === 0) {
        state.ui.isActive.upgradeSubscription = true;
        $("#uploadFileInDocusign").modal("show");
      } else {

        if (state.data.clientDocuments >= state.data.subscriptionDetails.pdf_number) {
          state.ui.isActive.upgradeSubscription = true;
          // $("#uploadFileInDocusign").modal("show");
        } else {

          $("#uploadFileInDocusign").modal("show");
        }
      }

    }
    function hideUploadDocusignDocument() {
      $("#uploadFileInDocusign").modal("hide");
      state.data.documentName = '';
      state.data.imagesToUpload = []
      $("#profileUpdate").val('');
    }

    function getReadyImageListToUpload(data: any) {
      console.log("READY IMAGES >>>", data)
      state.data.imagesToUpload = data
    }

    function removeSelectedImage(image: any) {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
      $("#profileUpdate").val('');
    }

    function validateCreateNewDocusignDocument() {
      if (!state.data.documentName) {
        state.ui.error.errorList.createDocusignDocument.push(
          "Document name is required."
        );
      }
      if (imagesToUpload.value.length == 0) {
        state.ui.error.errorList.createDocusignDocument.push(
          "Document is required."
        );
      }
      if (
        !state.ui.error.errorList.createDocusignDocument &&
        !state.ui.error.errorList.createDocusignDocument.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createDocusignDocument.length != 0) {
        state.ui.error.hasError = true;
      }
    }

    function createTemplate() {
      state.ui.error.errorList.createDocusignDocument = [];
      state.ui.error.hasError = false;
      validateCreateNewDocusignDocument();
      if (
        state.ui.error.errorList.createDocusignDocument.length != 0 ||
        state.ui.error.hasError)
        return false;
      state.ui.isPostLoading.templateCreating = true;
      const payLoad = {
        name: state.data.documentName,
        files: imagesToUpload.value,
      };
      ethitransService
        .createDocusignTemplate(payLoad)
        .then((res: any) => {
          // window.open(res.data.data.url, '_blank', 'noreferrer');
          // window.location.href = res.data.data.url;
          hideUploadDocusignDocument();
          state.ui.isLoading.showDocusignIframe = true;
          state.data.docuSignUrl = res.data.data.url;

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.templateCreating = false;
        });

    }

    function clientDocumentList() {
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .getDocusignTemplate()
        .then((res: any) => {
          state.data.clientDocuments = res.data.data;
          store.dispatch("docusignDocumentList", res.data.data);
          state.data.edocUploadedList = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
          state.data.edocUploadedList = false;
        });
    }

    watch(
      () => store.state.editSignedData,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.ui.isLoading.docuSignIframeEdit = true;
          state.data.docuSignSignEditUrl = store.state.editSignedData;
        } else {
          state.ui.isLoading.docuSignIframeEdit = false;
        }
      }
    );
    function packageDetailsByUser() {
      state.ui.error.errorList.uploadAttachments = [];
      state.ui.error.hasError = false;
      state.ui.isLoading.packageDetailsByUser = true;
      ethitransService
        .packageDetailsByUser()
        .then((res: any) => {
          console.log("Check response >>> ", res.data.data.package_features)
          state.data.subscriptionDetails = res.data.data.package_features;
          state.ui.isLoading.packageDetailsByUser = false;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.uploadAttachments)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.uploadAttachments.push(value[0]);
                }
              }

            });
        })
    }

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.ui.isLoading.showDocusignIframe = false;
      clientDocumentList();
      packageDetailsByUser();
      // window.parent.postMessage({ event: 'taskDone', data: { foo: 'bar' } }, '*');
      window.addEventListener('message', event => {
        if (event.data.event === 'taskDone') {
          // window.location.href = "/#/uploadpdf";
          clientDocumentList();
          state.ui.isLoading.showDocusignIframe = false;
          state.ui.isLoading.docuSignIframeEdit = false;
          state.data.documentName = '';
          state.data.imagesToUpload = []
        }
      });

    });

    return {
      state,
      packageDetailsByUser,
      hideUpdateSubscriptionModal,
      closeSuccessModal,
      createTemplate,
      docusignTempleteDetails,
      envelopeSign,
      hideUploadDocusignDocument,
      showUploadDocusignDocument,
      getReadyImageListToUpload,
      removeSelectedImage,
      clientDocumentList,
      readImage,
      dropFiles,
      imagesToUpload,
      uploadImage,
      validateCreateNewDocusignDocument,
      onDocumentChange
    };
  },
});
