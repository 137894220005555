
import { defineComponent, reactive, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import ToDoListBreadCrumb from "@/components/todo/ToDoListBreadCrumb.vue";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import { useStore } from "vuex";

export default defineComponent({
  name: "InviteShareProjectDetails",
  components: {
    ToDoListBreadCrumb
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {
      params: { invitationId },
    } = useRoute();
    const state = reactive({
      data: {
        endPoint: 'Invitation Details',
        selectAll: false,
        showSuccesModal: false,
        successFulMessage: "",
        declineMessage: "",
        imageBaseUrl: "",
        selectedInvition: {} as any,
        projectShareInvitionDetails: {
          attachments: [],
          breadcrumbs: [],
          allInvitation: [],
          payers: [],
          phase: {} as any,
          project: {},
          type: 0,
          role: 0,
          description: "",
          todayLog: {} as any,
        } as any,
        channelIndex: 0,
        inviteAsGuest: false,
      },
            selectedShareInvitationForRemoval: {} as any,
      ui: {
        modal: {
          showProjectAcknowledgementModal: false,
        },
        isLoading: {
          projectShareInvitionDetails: false,
        },
        isPostLoading: {
          redirectToChat: false,
          acknowledgeProject: false,
          declineProject: false,
          projectShareInvitionDetails: false,
          acceptInvitation: false,
          acceptSelectedPhase: false,
          declineSelectedPhase: false,
          removeShareInvitation:false,
        },
        errors: {
          errorList: {
            projectShareInvitionDetails: [] as any,
            declineTaskInvitations: [] as any,
            removeProjectRole: [] as any,
          },
          hasError: false,
        },
      },
    });
    const isInvitatationSelected = computed(() => {
      if (!state.data.projectShareInvitionDetails.child_invitations) return false;
      const res = state.data.projectShareInvitionDetails.child_invitations.some(
        (item: any) => item.phase_detail.phase.isChecked === true
      );
      return res;
    });

     function hideShareProjectRemove() {
      $("#removeProjectShareInvitation").modal("hide");
      state.selectedShareInvitationForRemoval = {};
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
    }

    function selectShareProjectRemove(member: any) {
      console.log('aler',member);
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      $("#removeProjectShareInvitation").modal("show");
      state.selectedShareInvitationForRemoval = {};
      state.selectedShareInvitationForRemoval = member;
    }


    function redirectToInvitation() {
      router.push({
        name: "admin-project-details",
        params: { projectId: state.data.projectShareInvitionDetails.project.uuid },
      });
    }

    function redirectToEdit() {
      router.push({
        name: "share-invitation-edit",
        params: { editId: invitationId },
      });
    }

      function openSuccessModal() {
        $("#successfulModal").modal("show");
      }
  
      function closeSuccessModal() {
        $("#successfulModal").modal("hide");
        redirectToInvitation();
      }

     function removeShareInvitation() {
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.removeShareInvitation) return false;
      state.ui.isPostLoading.removeShareInvitation = true;
      ethitransService
      .removeProjectShareInvitation(state.selectedShareInvitationForRemoval.uuid)
        .then((res: any) => {
          hideShareProjectRemove();
          state.data.successFulMessage = "Member Remove Successfully.";
          openSuccessModal() 
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.removeProjectRole)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.removeProjectRole.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.removeShareInvitation = false;
        });
    }

    function projectShareInvitationDetail() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isLoading.projectShareInvitionDetails) return false;
      state.ui.isLoading.projectShareInvitionDetails = true;
      ethitransService
        .projectShareMemberInvitationDetails(invitationId.toString())
        .then((res: any) => {
          state.data.projectShareInvitionDetails = res.data.data;
          state.data.projectShareInvitionDetails.allInvitation = [];
          res.data.data.child_invitations.forEach((invitation: any) => {
            state.data.projectShareInvitionDetails.allInvitation.push(invitation.uuid);
          });
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.projectShareInvitionDetails = false;
        });
    }

 


    onMounted(() => {
      projectShareInvitationDetail();
      state.data.imageBaseUrl = config.imageBaseUrl;

    });

    return {
      state,
      projectShareInvitationDetail,
      redirectToInvitation,
      redirectToEdit,
      hideShareProjectRemove,
      selectShareProjectRemove,
      removeShareInvitation,
      closeSuccessModal,
        openSuccessModal,
    };
  },
});
