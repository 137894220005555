
import { defineComponent, reactive, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

import ToDoDetailsAttachment from "@/components/todo/ToDoDetailsAttachment.vue";
import HistoryTimeLine from "@/components/projectDetail/HistoryTimeLine.vue";

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import EmptyData from "../commonDesign/EmptyData.vue";
import SignaturePadModal from "@/components/modal/SignaturePadModal.vue";
import SignatureUploadModal from "@/components/modal/SignatureUploadModal.vue";
export default defineComponent({
  name: "ProjectAcknowledgement",
  components: {
    ToDoDetailsAttachment,
    HistoryTimeLine,
    // TemplateCard,
    // SuccessfulModal,
    // HistoryCard,
    // EmptyData,
    ErrorList,
    SignaturePadModal,
    SignatureUploadModal,
  },
  setup() {
    const canvas = ref();
    let signaturePad: any;
    const router = useRouter();
    const {
      params: { invitationId },
    } = useRoute();
    const routerQuery = useRoute();
    // console.log("CHECK ID, >>", projectId);
    const state = reactive({
      canvas: {} as any,
      data: {
        haveProfileSign: false,
        useProfileSign: false,
        showSignaturePad: false,
        showSignatureUpload: false,
        uploadSignatureFile: "" as any,
        signatureLink: "" as any,
        contract: '' as any,
        leagelDocuments: '' as any,
        cropImage: "",
        imageBaseUrl: "",
        timeCount: 130,
        cropSignature: "" as any,
        newUploadImge: "" as any,
        SuccessFulMessage: "",
        uploadImageData: {} as any,
        sendPhases: [] as any,
        slectedPhases: [] as any,
        acknowledgementDetails: {
          description: '',
          attachments: [],
          payerRequest: {} as any,
          payers: [],
          project: {},
        },
        channelIndex: 0,
        inviteAsGuest: false,
        signatureFileName: "" as any,
        uploadSignature: "" as any,
      },
      ui: {
        modal: {
          showProjectAcknowledgementModal: false,
        },
        isLoading: {
          projectAcknowledgementDetails: false,
        },
        isPostLoading: {
          redirectToChat: false,
          acknowledgeProject: false,
          declineProject: false,
          phaseAcknowledgementDetails: false,
          checkSignature: false,
        },
        errors: {
          errorList: {
            phaseAcknowledgementDetails: [] as any,
            projectAcknowledgementDetails: [] as any,
          },
          hasError: false,
        },
      },
    });
    state.ui.errors.errorList
    function redirectToDoc() {
      window.open(state.data.acknowledgementDetails.payerRequest.edoc_id, "_blank");
    }
    function redirectBack() {
      // admin-invitation
      router.push({
        name: 'admin-invitation',
      });

    }
    function redirectToChat() {
      state.ui.errors.errorList.projectAcknowledgementDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.redirectToChat) return false;
      state.ui.isPostLoading.redirectToChat = true;
      ethitransService
        .payerDiscussionChannel(invitationId.toString())
        .then((res: any) => {
          router.push({
            name: 'admin-channel',
            params: { channelId: res.data.data.channel.uuid.toString() },
          });
          console.log("Acknowledge Project Response:>>> ", res.data.data.channel);
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.projectAcknowledgementDetails
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectAcknowledgementDetails.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.redirectToChat = false;
        });
    }

    function validateForm() {
      if (!state.data.uploadSignature && !state.data.signatureLink) {
        state.ui.errors.errorList.phaseAcknowledgementDetails.push("Use/Upload/Draw Your valid Signature");
        state.ui.isPostLoading.checkSignature = true;
      }
      if (!state.data.slectedPhases.length && state.data.slectedPhases.length == 0) {
        state.ui.errors.errorList.projectAcknowledgementDetails.push("Phase is required.");
      }

      if (!state.ui.errors.errorList.phaseAcknowledgementDetails && !state.ui.errors.errorList.phaseAcknowledgementDetails.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.phaseAcknowledgementDetails.length != 0) {
        state.ui.errors.hasError = true;
      }
    }

    function acceptEstimation() {
      state.data.slectedPhases = [];
      state.ui.errors.errorList.phaseAcknowledgementDetails = [];
      state.ui.errors.hasError = false;
      state.data.sendPhases.forEach((phase: any) => {
        if (phase.isChecked) {
          state.data.slectedPhases.push(phase.id);
        }
      })
      validateForm();
      if (state.ui.isPostLoading.acknowledgeProject || state.ui.errors.errorList.phaseAcknowledgementDetails.length != 0 || state.ui.errors.hasError) return false;
      state.ui.isPostLoading.acknowledgeProject = true;
      const payLoad = {
        id: invitationId.toString(),
        signature: state.data.uploadSignature,
         // eslint-disable-next-line @typescript-eslint/camelcase
         signature_link: state.data.signatureLink ,
        phases: state.data.slectedPhases,
      };
      ethitransService
        .acceptEstimation(payLoad)
        .then((res: any) => {
          state.data.acknowledgementDetails.payerRequest.status = 2;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.projectAcknowledgementDetails
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectAcknowledgementDetails.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acknowledgeProject = false;
          state.data.uploadSignature = '';
          state.data.slectedPhases = [];
          router.push({ name: "admin-invitation", });
        });
      // alert("Acknowledge Project");
    }
    function closeAcknowledgementInvitationModal() {
      $("#deleteAcknowledgementInvitationModal").modal("hide");
      router.push({ name: "admin-invitation", });
    }
    function declineProject() {
      state.data.slectedPhases = [];
      state.ui.errors.errorList.projectAcknowledgementDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      if (!state.data.acknowledgementDetails.description) return false;
      state.data.sendPhases.forEach((phase: any) => {
        if (phase.isChecked) {
          state.data.slectedPhases.push(phase.id);
        }
      })
      const payLoad = {
        id: invitationId.toString(),
        description: state.data.acknowledgementDetails.description,
        phases: state.data.slectedPhases,
      };
      state.ui.isPostLoading.declineProject = true;
      ethitransService
        .declineProjectApprovalInvitation(payLoad)
        .then((res: any) => {
          // console.log("Decline Project Response:>>> ", res.data.data);
          // res.data.data.payerRequest.localStartedDate = common.localDateAndTime(
          //   res.data.data.payerRequest.start_date
          // );
          // res.data.data.payerRequest.localEndDate = common.localDateAndTime(
          //   res.data.data.payerRequest.end_date
          // );
          // res.data.data.attachments.forEach((attachment: any) => {
          //   // attachment.file_size
          //   attachment.convertedFileSize = common.formatBytes(
          //     parseInt(attachment.file_size)
          //   );
          // });
          // // res.data.data.payerRequest.status = 3;
          // state.data.acknowledgementDetails = res.data.data;
          // state.data.acknowledgementDetails.payerRequest.status = 3;
          state.data.acknowledgementDetails.payerRequest.status = 2;
          closeAcknowledgementInvitationModal();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.projectAcknowledgementDetails
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectAcknowledgementDetails.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }

    function showDeclineModal() {
      state.data.acknowledgementDetails.description = '';
      $("#deleteAcknowledgementInvitationModal").modal("show");
    }
    function projectAcknowledgementDetails(id: any) {
      const invitationId: any = id;
      state.ui.errors.errorList.projectAcknowledgementDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isLoading.projectAcknowledgementDetails) return false;
      state.ui.isLoading.projectAcknowledgementDetails = true;
      ethitransService
        .projectAcknowledgementRequestDetail(invitationId.toString())
        .then((res: any) => {
          state.data.contract = res.data.data.smart_contract.acknowledgement;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.leagelDocuments = res.data.data.smart_contract.legal_statement;
          res.data.data.payerRequest.localStartedDate = common.localDateOnly(
            res.data.data.payerRequest.start_date
          );
          res.data.data.payerRequest.localEndDate = common.localDateOnly(
            res.data.data.payerRequest.end_date
          );
          if (res.data.data.payerRequest.history && res.data.data.payerRequest.history.length) {
            res.data.data.payerRequest.history.forEach((history: any) => {
              if (history.changes && history.changes.length) {
                history.changes.forEach((change: any) => {
                  if (change.old_documents && change.old_documents.length) {
                    change.old_documents.forEach((oldDoc: any) => {
                      oldDoc.activeLink = config.liveImageBaseUrl + oldDoc.file_link;

                    })
                  }
                  if (change.new_documents && change.new_documents.length) {
                    change.new_documents.forEach((newDoc: any) => {
                      newDoc.activeLink = config.liveImageBaseUrl + newDoc.file_link;

                    })
                  }
                })
              }
            });
          }
          if (res.data.data.attachments && res.data.data.attachments.length) {

            res.data.data.attachments.forEach((attachment: any) => {
              attachment.activeLink = config.liveImageBaseUrl + attachment.file_link;
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
            });

          }
          res.data.data.phases.forEach((phase: any) => {
            phase.isChecked = true;
            phase.startDate = common.localDateOnly(phase.start_date);
            phase.budget = common.usdPriceFormate(phase.budget);
            phase.endDate = common.localDateOnly(phase.end_date);
            phase.child_phases.forEach((child: any) => {
              child.budget = common.usdPriceFormate(child.budget);
              child.isChecked = true;
              child.startDate = child.start_date ? common.localDateOnly(child.start_date) : '-';
              child.endDate = child.end_date ? common.localDateOnly(child.end_date) : '-';
            });
          });

          state.data.sendPhases = res.data.data.phases;
          res.data.data.payerRequest.budget = common.formatCurrency(res.data.data.payerRequest.budget);
          /* eslint-disable @typescript-eslint/camelcase */
          res.data.data.payerRequest.created_at = common.localDateOnly(res.data.data.payerRequest.created_at);
          res.data.data.approved_signers.forEach((sign: any) => {
            sign.date = common.localDateOnly(sign.signature_date);
          })
          state.data.acknowledgementDetails = res.data.data;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.projectAcknowledgementDetails
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectAcknowledgementDetails.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.projectAcknowledgementDetails = false;
        });
    }


    function showUploadImageModel() {
      $("#updateUpload").modal("show");
    }


    function hideShowUploadImageModel() {
      $("#profileUpdate").val('');
      $("#updateUpload").modal("hide");
    }

    function readSignature(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.duplicateFile = false; // data.fileName = file.name;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.tag = [] as any;
        data.size = common.formatBytes(parseInt(file.size));
        // state.data.uploadImageData = data;
        state.data.newUploadImge = data.base64;
        state.data.cropImage = data.base64;
        state.data.signatureFileName = data.fileName;
      };
      reader.readAsDataURL(file);
    }

    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readSignature(file);
        $("#updateUpload").modal("show");
      }
    }

    function saveSignature() {
      hideShowUploadImageModel();
    }

    function clearSignature() {
      state.data.cropSignature = "";
      signaturePad.clear();
    }




    function slectedPhases(phase: any) {
      state.data.sendPhases[phase].isChecked = !state.data.sendPhases[phase].isChecked
    }

    watch(
      () => routerQuery.params.invitationId,
      (newvalue, oldvalue) => {
        ``
        // console.log("Hello", newvalue);
        if (newvalue) {
          projectAcknowledgementDetails(newvalue);
        }
        //  scrollAtButton();
      }
    );
    function handleImageInNewTab(url: any) {
      window.open(config.liveImageBaseUrl + url, '_blank');
    }

    function showSignaturePad() {
      state.data.showSignaturePad = !state.data.showSignaturePad;
    }

    function hideSignaturePad() {
      state.data.showSignaturePad = !state.data.showSignaturePad;
      $("#signaturePadModal").modal("hide");
    }

    function hideSignatureUpload() {
      state.data.showSignatureUpload = false;
      $("#signatureUploadModal").modal("hide");
    }
    function uploadSignature(data: any) {
      state.data.signatureLink ="";
      state.data.cropSignature = data;
      state.data.uploadSignature = data;
      state.data.useProfileSign = false;
    }

    function uploadSignatureFile(event: any) {
      state.data.showSignatureUpload = true;
      state.data.useProfileSign = false;
      state.data.uploadSignatureFile = event.target.files || event.dataTransfer.files;
    }

    function userSignature(from: any) {
      ethitransService
        .userSignature()
        .then((res: any) => {

          if (from == 'onMount' && res.data.data.signature) {
            state.data.haveProfileSign = true;
          }
          if (from == 'use' && res.data.data.signature) {
            state.data.useProfileSign = true;
            state.data.uploadSignature = "";
            state.data.cropSignature = res.data.data.signature;
            state.data.signatureLink = res.data.data.signature;
          }
        })
        .catch((error: any) => {
          //   common
        })
    }
    onMounted(() => {
      projectAcknowledgementDetails(invitationId);
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.data.haveProfileSign = false;
      userSignature('onMount');
    });

    return {
      state,
      redirectBack,
      handleImageInNewTab,
      closeAcknowledgementInvitationModal,
      showDeclineModal,
      projectAcknowledgementDetails,
      redirectToChat,
      acceptEstimation,
      declineProject,
      redirectToDoc,
      clearSignature,
      readSignature,
      onDocumentChange,
      showUploadImageModel,
      hideShowUploadImageModel,
      saveSignature,
      validateForm,
      slectedPhases,
      showSignaturePad,
      hideSignaturePad,
      uploadSignature,
      uploadSignatureFile,
      hideSignatureUpload,
      userSignature
    };
  },
});
