
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "OtpLoginComponent",
  setup() {
    const isRecording = ref(false);
    const transcription = ref('');

    let recognition =  '' as any;

    const startRecording = () => {
      isRecording.value = true;
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.start();

      recognition.onresult = (event: any) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        transcription.value = transcript;
      };

      recognition.onend = () => {
        isRecording.value = false;
      };
    };

    const stopRecording = () => {
      if (recognition) {
        recognition.stop();
        isRecording.value = false;
      }
    };


    return {
      isRecording,
      transcription,
      startRecording,
      stopRecording,
    };
  },
});
