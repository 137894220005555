
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import ToDoListBreadCrumb from "@/components/todo/ToDoListBreadCrumb.vue";
import ToDoDetailsAttachment from "@/components/todo/ToDoDetailsAttachment.vue";
// import ToDoListAddPhaseModal from "@/components/todo/ToDoListAddPhaseModal.vue";
import ToDoPhaseTaskListUpdateModal from "@/components/todo/ToDoPhaseTaskListUpdateModal.vue";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import ToDoPhaseDetailTasks from "@/components/todo/ToDoPhaseDetailTasks.vue";
import ToDoMembersList from "@/components/todo/ToDoMembersList.vue";
// import ToDoListMembersList from "@/components/todo/ToDoListMembersList.vue";
import ToDoPhasePaymentRequest from "@/components/todo/ToDoPhasePaymentRequest.vue";
import ToDoPhasePaymentApproval from "@/components/todo/ToDoPhasePaymentApproval.vue";
import ToDoPhaseChatChannelModel from "@/components/todo/ToDoPhaseChatChannelModel.vue";
// import ToDoPhaseStatus from "@/components/todo/ToDoPhaseStatus.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { useStore } from "vuex";

import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Pusher from "pusher-js";

export default defineComponent({
  name: "ToDoDetails",
  components: {
    ErrorList,
    ToDoListBreadCrumb,
    ToDoDetailsAttachment,
    ToDoPhaseTaskListUpdateModal,
    // ToDoListAddPhaseModal,
    SuccessfulModal,
    ToDoPhaseDetailTasks,
    ToDoMembersList,
    // ToDoListMembersList,
    ToDoPhasePaymentRequest,
    ToDoPhasePaymentApproval,
    ToDoPhaseChatChannelModel,
  },
  setup() {
    const routerQuery = useRoute();
    const store = useStore();
    const router = useRouter();
    const {
      params: { phaseId },
    } = useRoute();
    const selectedPhase = ref({
      attachments: [] as any,
      permissions: [] as any,
      breadcrumbs: [] as any,
      invoice: {} as any,
      project: {} as any,
      phase: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        child_phases: [] as any,
        estimation: {
          type: '',
          amount: 0,
        } as any,
      } as any,
      role: 3,
      // eslint-disable-next-line @typescript-eslint/camelcase
      smart_contract_id: "",
    });
    const state = reactive({
      data: {
        selectedTasks: [] as any,
        checkedTasks: [] as any,
        generatedInvoiceDetails: {
          breadcrumb: [] as any,
          invoice: {} as any,
        } as any,
        phaseId: "" as any,
        successFulMessage: "",
        endPoint: "To do",
        taskCompletionDetails: "",
        stripeUrl: "",
        imageBaseUrl: "",
        selectedInvoiceReceiverId: 0,
        invoiceReceiverList: [],
        successFullMessage: "",
        showGenerate: false,
        showInvoiceReceiverModal: false,
        showPhaseChannelModal: false,
        showSuccesModal: false,
        showPhasePaymentRequestModal: false,
        showPaymentRequestApprovalModal: false,
        showAddPhaseModal: false,
        editMode: false,
        myDriveFileFolderLoad: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getPhaseDetails: [] as any,
            invoiceReceiverList: [] as any,
            generateInvoice: [] as any,
          },
        },
        isLoading: {
          getPhaseDetails: false,
          stripeAccountCheck: false,
        },
        isPostLoading: {
          showInvoiceReceiverModal: false,
          generateInvoice: false,
          phaseCompleted: false,
          enableSelectedFileTag: false,
          redirectToChat: false,
          stripeAccount: false,
        },
      },
    });
    function checkPermissionExists(permissions: any, permissionToCheck: any) {
      return permissions.some(
        (permission: any) =>
          permission.id === permissionToCheck.id &&
          permission.code === permissionToCheck.code &&
          permission.type === permissionToCheck.type &&
          permission.name === permissionToCheck.name
      );
    }
    // const checkedTasks = computed(() => store.state.selectedTasks);
    function redirectToCoOwnerInvoice() {
      // console.log("CHECK INVOICE ID>>> ", invoiceId)
      router.push({
        name: "user-todo-invoice-sent",
        params: { smartId: selectedPhase.value.smart_contract_id },
        // query: { mode: selectedMode },
        query: { phaseId: selectedPhase.value.phase.uuid },
      });
    }
    function redirectToAllInvoice(invoiceId: string, selectedMode: string) {
      // console.log("CHECK >>> ", data)
      router.push({
        name: "admin-billing-requested-invoice",
        params: { phaseId: phaseId },
        query: { mode: selectedMode },
        // query: { mode: selectedMode, invoiceId: invoiceId },
      });
    }
    // function redirectToSelectedInvoice(
    //   invoiceId: string,
    //   selectedMode: string
    // ) {
    //   // console.log("CHECK >>> ", data)
    //   router.push({
    //     name: "admin-billing-requested-invoice",
    //     params: { phaseId: phaseId },
    //     query: { mode: selectedMode, invoiceId: invoiceId },
    //   });
    // }
    function redirectToRequestedInvoice(
      invoiceId: string,
      selectedMode: string
    ) {
      router.push({
        name: "admin-billing-received-invoice",
        params: { phaseId: phaseId },
        query: { mode: selectedMode },
        // query: { mode: selectedMode, invoiceId: invoiceId },
      });
    }
    function redirectToRequestedDraftInvoice(
      invoiceId: string,
      selectedMode: string
    ) {
      router.push({
        name: "admin-billing-draft-invoice",
        params: { phaseId: phaseId },
        query: { mode: selectedMode },
        // query: { mode: selectedMode, invoiceId: invoiceId },
      });
    }
    function redirectToSelectedInvoice(
      invoiceId: string,
      selectedMode: string
    ) {
      // console.log("CHECK >>> ", data)
      router.push({
        name: "admin-billing-invoice",
        params: { invoiceId: invoiceId },
        query: { mode: selectedMode },
      });
    }

    function redirectToInvoice() {
      // console.log("CHECK >>> ", data)
      router.push({
        name: "user-phase-invoice",
        params: { id: phaseId },
      });
    }
    function redirectToPhaseLogs(data: any) {
      // console.log("CHECK >>> ", data)
      router.push({
        name: "user-todo-task-log-details",
        params: { phaseId: phaseId },
        query: { id: selectedPhase.value.project.uuid },

        // props.phaseId
      });
    }
    const invoiceGeneratePermissionByRole = computed(() => {
      if (selectedPhase.value.role === 1 || selectedPhase.value.role === 2) {
        return true;
      } else if (selectedPhase.value.role === 3) {
        const permissionToCheck = {
          id: 8,
          code: "invoice_receiver",
          type: 0,
          name: "Invoice Receiver",
        };
        const isAllowded: any = checkPermissionExists(selectedPhase.value.permissions, permissionToCheck);
        //
        if (isAllowded) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    const phaseStatus = computed(() => {
      if (selectedPhase.value.phase.status != "Open") {
        return false;
      } else {
        return true;
      }
    });
    const isActiveGenerateInvoice = computed(() => {
      if (selectedPhase.value.role === 3) {
        for (const childPhase of selectedPhase.value.phase.child_phases) {
          if (
            (childPhase &&
              childPhase.status_detail &&
             childPhase.status_detail.status.code === "done") 
              //  && (childPhase &&
              //   childPhase.invoice && !childPhase.invoice.uuid)
          ) {
                    console.log("Debug: Child phase status is 'done' and invoice UUID is missing", childPhase);
            const permissionToCheck = {
              id: 8,
              code: "invoice_receiver",
              type: 0,
              name: "Invoice Receiver",
            };
            const isAllowded: any = checkPermissionExists(selectedPhase.value.permissions, permissionToCheck);
            if (!isAllowded) {
              return true;
            }
          }
        }
      }
      return false;
    });
    const checkedSelectedTasks = computed(() => {
      return state.data.selectedTasks;
    });
    function showFlagCompleteTask() {
      state.data.taskCompletionDetails = "";
      $("#flagTaskAsCompleted").modal("show");
    }
    function hideFlagCompleteTask() {
      $("#flagTaskAsCompleted").modal("hide");
    }
    function hidePhaseChannel() {
      state.data.showPhaseChannelModal = !state.data.showPhaseChannelModal;
      // $("#phaseChatChannelModel").modal("show");
    }
    function showPhaseChatModal() {
      state.data.showPhaseChannelModal = !state.data.showPhaseChannelModal;
      // $("#phaseChatChannelModel").modal("show");
    }
    function hideToDoPhasePaymentRequest() {
      state.data.showPhasePaymentRequestModal = false;
    }
    function closePaymentApproval() {
      state.data.showPaymentRequestApprovalModal = false;
    }
    function paymentRequestApproval() {
      state.data.showPaymentRequestApprovalModal =
        !state.data.showPaymentRequestApprovalModal;
    }
    function paymentRequest() {
      state.data.showPhasePaymentRequestModal =
        !state.data.showPhasePaymentRequestModal;
    }
    function redirectToChat() {
      // alert("Redirect to Chat Room");
      // payerDiscussionChannel
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.redirectToChat) return false;
      state.ui.isPostLoading.redirectToChat = true;
      ethitransService
        .channelExistCheckForPhase(routerQuery.params.phaseId.toString())
        .then((res: any) => {
          if (res.data.data.channel && res.data.data.channel.uuid) {
            router.push({
              name: "admin-channel",
              params: { channelId: res.data.data.channel.uuid.toString() },
            });
          } else {
            showPhaseChatModal();
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.redirectToChat = false;
        });
    }
    function redirectToToDoList() {
      if (selectedPhase.value.phase.parent_id > 0) {
        router.push({
          name: "user-todo-details",
          params: { phaseId: selectedPhase.value.phase.parent_phase.uuid },
        });
      } else {
        router.push({
          name: "user-todo-list",
          params: { projectId: selectedPhase.value.smart_contract_id },
        });
      }
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function seeInvoiceGenerated() {
      closeSuccessModal();
      redirectToSelectedInvoice(
        state.data.generatedInvoiceDetails.invoice.uuid,
        "2"
      );
    }
    function checkedTask(data: any) {
      console.log("<<< CHECK THE DATA >>>", data);
      state.data.checkedTasks = data;
      // state.data.showGenerate = true;
    }
    const activeTasks = computed(() => store.state.selectedTasks);
    function selectedTasks(data: any) {
      // if(selectedPhase.value.role != 3) return false;
      console.log("DATA CHECK  PRABHU>>> ", data);
      // if(!data) return false;
      state.data.selectedTasks = data;
      store.dispatch("saveActiveTask", data);
      console.log("Selected Tasks PC 11>>> ", state.data.selectedTasks);
    }
    function addNewPhase(data: any) {
      data.phase.child_phases.forEach((child: any) => {
        child.localStartedDate = common.localDateAndTime(child.start_date);
        child.localEndDate = common.localDateAndTime(child.end_date);
      });
      data.phase.localStartedDate = common.localDateOnly(data.phase.start_date);
      data.phase.localEndDate = common.localDateOnly(data.phase.end_date);
      data.attachments.forEach((attachment: any) => {
        attachment.activeLink = config.liveImageBaseUrl + attachment.file_link;
        // attachment.file_size
        attachment.convertedFileSize = common.formatBytes(
          parseInt(attachment.file_size)
        );
      });
      selectedPhase.value = data;
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.data.successFullMessage = "New To Do Added Successfully.";
    }

    function updatePhaseList(data: any) {
      data.phase.child_phases.forEach((child: any) => {
        child.localStartedDate = common.localDateAndTime(child.start_date);
        child.localEndDate = common.localDateAndTime(child.end_date);
      });
      data.phase.localStartedDate = common.localDateOnly(data.phase.start_date);
      data.phase.localEndDate = common.localDateOnly(data.phase.end_date);
      data.attachments.forEach((attachment: any) => {
        attachment.activeLink = config.liveImageBaseUrl + attachment.file_link;
        // attachment.file_size
        attachment.convertedFileSize = common.formatBytes(
          parseInt(attachment.file_size)
        );
      });
      selectedPhase.value = data;
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.data.successFullMessage = "Update Successfully.";
    }
    function showAddPhaseModal() {
      state.data.showAddPhaseModal = !state.data.showAddPhaseModal;
    }
    function redirectToPhase(phase: any) {
      // console.log("CHECK THE PHASE DETAILS >>>>> ", phase)
      router.push({
        name: "user-todo-details",
        params: { phaseId: phase.phase_uuid },
      });
      // router.push({
      //   name: "admin-project-details",
      //   params: { projectId: phase.phase_uuid },
      // });
    }
    function getPhaseDetails(id: any) {
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPhaseDetails) return false;
      state.ui.isLoading.getPhaseDetails = true;
      ethitransService
        .phaseDetailsById(id.toString())
        .then((res: any) => {
          // console.log("getPhaseDetails Response Check:>>> ", res.data.data);
          // console.log("LINK Check:>>> ", config.liveImageBaseUrl);
          res.data.data.phase.localStartedDate = common.localDateOnly(
            res.data.data.phase.start_date
          );
          res.data.data.phase.localEndDate = common.localDateOnly(
            res.data.data.phase.end_date
          );
          if (res.data.data.attachments) {
            res.data.data.attachments.forEach((attachment: any) => {
              // attachment.file_size
              attachment.activeLink =
                config.liveImageBaseUrl + attachment.file_link;
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
              // console.log("HELLO ATTACHMENT >>> ", attachment.activeLink)
            });
          }

          res.data.data.phase.child_phases.forEach((child: any) => {
            child.isChecked = false;
            if (child.start_date) {
              child.localStartedDate = common.localDateOnly(child.start_date);
            } else {
              child.localStartedDate = "";
            }
            if (child.end_date) {
              child.localEndDate = common.localDateOnly(child.end_date);
            } else {
              child.localEndDate = "";
            }
          });
          res.data.data.phase.budget = common.formatCurrency(
            res.data.data.phase.budget
          );
          console.log("CHECK BUDGET >>>> ", res.data.data.phase.budget);
          res.data.data.invoices = res.data.data.invoices
            ? res.data.data.invoices
            : {};
          selectedPhase.value = res.data.data;
          // res.data.data.phases.data.forEach((phase: any)=> {
          //   phase.localStartedDate = common.localDateAndTime(
          //       phase.start_date
          //     );
          //   phase.localEndDate = common.localDateAndTime(
          //       phase.end_date
          //     );
          // })

          // todoData.value = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getPhaseDetails = false;
        });
    }
    function updateLog(data: any) {
      data.phase.localStartedDate = common.localDateAndTime(
        data.phase.start_date
      );
      data.phase.localEndDate = common.localDateAndTime(data.phase.end_date);
      data.attachments.forEach((attachment: any) => {
        // attachment.file_size
        attachment.activeLink = config.liveImageBaseUrl + attachment.file_link;
        attachment.convertedFileSize = common.formatBytes(
          parseInt(attachment.file_size)
        );
        // console.log("HELLO ATTACHMENT >>> ", attachment.activeLink)
      });
      data.phase.child_phases.forEach((child: any) => {
        child.localStartedDate = common.localDateAndTime(child.start_date);
        child.localEndDate = common.localDateAndTime(child.end_date);
      });

      selectedPhase.value = data;
    }
    function phaseCompleted() {
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;
      if (!state.data.taskCompletionDetails) return false;
      if (!state.data.phaseId) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: state.data.phaseId,
        detail: state.data.taskCompletionDetails,
      };
      console.log("ID CHECK  WITH PAYLOAD>>>", payLoad);
      state.ui.isPostLoading.phaseCompleted = true;
      ethitransService
        .phaseCompleted(payLoad)
        .then((res: any) => {
          console.log("phaseCompleted Response Check:>>> ", res.data.data);
          selectedPhase.value.phase.status = "Close";
          hideFlagCompleteTask();
          // $("#notificationModal").modal("hide");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.phaseCompleted = false;
        });
    }
    // function redirectToGeneratedInvoice(){

    // }
    function hideInvoiceReceiverModal() {
      state.data.showInvoiceReceiverModal = false;
      $("#setInvoiceReceiverModal").modal("hide");
    }

    function showInvoiceReceiverModal() {
      state.ui.error.errorList.invoiceReceiverList = [];
      state.ui.error.hasError = false;
      // generateInvoice()
      if (state.ui.isPostLoading.showInvoiceReceiverModal) return false;
      state.ui.isPostLoading.showInvoiceReceiverModal = true;
      const data = {
        phase: phaseId,
      };
      ethitransService
        .invoiceReceiverList(data)
        .then((res: any) => {
          console.log("invoiceReceiverList Check:>>> ", res.data.data);
          state.data.invoiceReceiverList = res.data.data.receivers;
          state.data.showInvoiceReceiverModal = true;
          state.ui.isPostLoading.showInvoiceReceiverModal = false;
          state.ui.error.errorList.invoiceReceiverList = [];
          state.ui.error.hasError = false;
          $("#setInvoiceReceiverModal").modal("show");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.invoiceReceiverList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.invoiceReceiverList.push(value[0]);
                }
              }
            });
        });
    }
    function checkGenerateInvoice() {
      if (state.data.checkedTasks.length) {
        showInvoiceReceiverModal();
      } else {
        alert("Please check atleast 1 Task.");
      }
    }
    function validateInvoiceReceiver() {
      state.ui.error.errorList.generateInvoice = [];
      state.ui.error.hasError = false;

      if (!state.data.selectedInvoiceReceiverId) {
        state.ui.error.errorList.generateInvoice.push(
          "Please select the Invoice Receiver."
        );
      }
      if (
        !state.ui.error.errorList.generateInvoice &&
        !state.ui.error.errorList.generateInvoice.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.generateInvoice.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function generateInvoice() {
      state.ui.error.errorList.generateInvoice = [];
      state.ui.error.hasError = false;
      const selectedTask: any = [];
      if (!state.data.checkedTasks) return false;
      state.data.checkedTasks.forEach((item: any) => {
        selectedTask.push(item.uuid);
      });
      // if (!state.data.taskCompletionDetails) return false;
      if (state.ui.isPostLoading.generateInvoice) return false;
      validateInvoiceReceiver();
      if (
        state.ui.error.errorList.generateInvoice &&
        state.ui.error.errorList.generateInvoice.length > 0
      )
        return false;
      const payLoad = {
        phases: selectedTask,
        // eslint-disable-next-line @typescript-eslint/camelcase
        receiver_id: state.data.selectedInvoiceReceiverId,
      };
      console.log("ID CHECK  WITH PAYLOAD>>>", payLoad);
      state.ui.isPostLoading.generateInvoice = true;
      ethitransService
        .phaseTaskInvoice(payLoad)
        .then((res: any) => {
          console.log("phaseCompleted Response Check:>>> ", res.data.data);
          state.data.generatedInvoiceDetails = res.data.data
            ? res.data.data
            : {};
          hideInvoiceReceiverModal();
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFullMessage = "Invoice Generated Successfully!!!";
          // redirectToAllInvoice(selectedPhase.invoices.sent_invoice.uuid, '2')
          // selectedPhase.value.phase.status = "Close";
          // hideFlagCompleteTask();
          // $("#notificationModal").modal("hide");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.generateInvoice)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.generateInvoice.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.generateInvoice = false;
        });
    }
    function stripeAccountCheck() {
      // alert("HELLO");
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.stripeAccountCheck) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.getPhaseDetails.length != 0
      )
        return false;
      state.ui.isLoading.stripeAccountCheck = true;
      ethitransService
        .stripeAccountCheck("")
        // .stripeAccountCheck(state.data.invoiceDetails.uuid)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 0) {
            state.data.stripeUrl = res.data.data.link;
            console.log("Project Todo Response Check:>>> ", res.data.data);
            state.data.showSuccesModal = !state.data.showSuccesModal;
            state.data.successFulMessage = "Stripe Account Checked";
          } else if (res.data.data.status === 1) {
            generateInvoice();
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.stripeAccountCheck = false;
        });
    }
    watch(
      () => state.data.selectedTasks,
      (newvalue, oldvalue) => {
        if (newvalue) {
          console.log("GENERATE TRIGGERED");
          // getPhaseDetails(newvalue);
        }
        //  scrollAtButton();
      }
    );
    watch(
      () => routerQuery.params.phaseId,
      (newvalue, oldvalue) => {
        if (newvalue) {
          // console.log("TRIGGERED");
          getPhaseDetails(newvalue);
        }
        //  scrollAtButton();
      }
    );
    // private channel pusher implemented
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });

    const todoPhaseDetail = pusher.subscribe(
      `private-phaseDashboard.${phaseId.toString()}`
    );
    // console.log("Channel Communication>>> ", channel);

    todoPhaseDetail.bind("phaseDashboard.updated", function (data: any) {
      if (JSON.stringify(data)) {
        const url: any = window.location.href;
        // const url: any = window.location.href.split("#/").pop();
        const page: any = url.split("#/").pop().split("/")[0];
        console.log("CHECK URL >>> ", page);
        // const channelId: any = url.split("/").pop();
        // state.data.channelId = "";
        if (page === "todo-details") {
          // alert("Phase/Task Detail TRIGGERED");
          // state.data.channelId = channelId;
          getPhaseDetails(phaseId);
        }
      }
    });
    function redirectToStripeAccount() {
      state.ui.isPostLoading.stripeAccount = true;
      window.location.replace(`${state.data.stripeUrl}`);
      state.ui.isPostLoading.stripeAccount = false;
    }
    function isActive(permissionList: any, action: string) {
      return common.isActionAllowded(
        selectedPhase.value.role,
        permissionList,
        action
      );
    }

    function usdPriceFormate(data: any) {
      if (data != null) {
        return data.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
        });
      }
    }

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      if (phaseId) {
        state.data.phaseId = phaseId;
      }
      getPhaseDetails(phaseId);
    });

    return {
      state,
      checkPermissionExists,
      invoiceGeneratePermissionByRole,
      validateInvoiceReceiver,
      isActiveGenerateInvoice,
      checkGenerateInvoice,
      isActive,
      activeTasks,
      redirectToRequestedDraftInvoice,
      redirectToStripeAccount,
      stripeAccountCheck,
      seeInvoiceGenerated,
      // checkedTasks,
      checkedTask,
      checkedSelectedTasks,
      hideInvoiceReceiverModal,
      showInvoiceReceiverModal,
      generateInvoice,
      selectedTasks,
      redirectToRequestedInvoice,
      redirectToAllInvoice,
      redirectToSelectedInvoice,
      redirectToInvoice,
      showFlagCompleteTask,
      hideFlagCompleteTask,
      phaseCompleted,
      updateLog,
      redirectToPhaseLogs,
      hidePhaseChannel,
      phaseStatus,
      showPhaseChatModal,
      redirectToChat,
      closeSuccessModal,
      addNewPhase,
      showAddPhaseModal,
      redirectToPhase,
      selectedPhase,
      getPhaseDetails,
      redirectToToDoList,
      paymentRequest,
      paymentRequestApproval,
      closePaymentApproval,
      hideToDoPhasePaymentRequest,
      updatePhaseList,
      usdPriceFormate,
    };
  },
});
