
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import ToDoPhaseAddMemberIndividualTest from "@/components/todo/ToDoPhaseAddMemberIndividualTest.vue";

// import ToDoPhaseAddMemberIndividual from "@/components/todo/ToDoPhaseAddMemberIndividual.vue";
import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";
import CheckAvaibilityByUser from "@/components/todo/CheckAvaibilityByUser.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

// import { Data } from "@/components/todo/AddMember.ts";

export default defineComponent({
  name: "AddMembeToTask",
  props: {
    isActive: {
      type: Boolean,
    },
    activeUserRole: {
      type: Boolean,
    },
    phaseDetails: {
      type: Object,
    },
    activeTab: {
      type: String,
    },
    tasks: {
      type: Array,
    },
    existingMembers: {
      type: Array,
    },
    permissions: {
      type: Array,
    },
    relatedPermissions: {
      type: Array,
    },
    amountDepends: {
      type: Array,
    },
    roles: {
      type: Array,
    },
    amountTypes: {
      type: Array,
    },
  },
  components: {
    // ToDoPhaseAddMemberIndividual,
    ToDoPhaseAddMemberIndividualTest,
    CheckAvaibilityByUser,
    AppDeleteConfirmationModal,
    ErrorList,
  },
  setup(props, { emit }) {
    const {
      params: { id },
    } = useRoute();
    const state = reactive({
      data: {
        selectedMemberIndex: 0,
        selectedEmail: "",
        selectedUser: {},
        selectedToRemove: {},
        selectedMemberForRole: {},
        showAvailibilityModal: false,
        showDeleteConfirmationModal: false,
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        inviteToTask: {
          newUsers: "",
          existingMembers: [] as any,
          existingMemberSelected: [] as any,
          selectedMembers: [] as any,
        },
        agreeToInvitation: false,
        filterBy: {
          startDate: "",
          endDate: "",
        },
        dates: {
          email: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          start_date: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          end_date: "",
          availability: [] as any,
          formattedDate: [] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            availabilityCheck: [] as any,
            phaseDetails: [] as any,
            addMembersInPhases: [] as any,
            addMembersToTasks: [] as any,
          },
        },
        isLoading: {
          availabilityCheck: false,
          changeDetailsByEmail: false,
        },
        isPostLoading: {
          createPhase: false,
          changeDetailsByEmail: false,
          addMembersInPhases: false,
          addMembersToTasks: false,
        },
      },
    });
    function hasValidFilteredSlots(member: any) {
      return (
        Array.isArray(member.filteredSlots) &&
        member.filteredSlots.length > 0 &&
        member.amountTypeNumber !== undefined &&
        member.amountTypeNumber !== null &&
        member.amountTypeNumber !== "" &&
        typeof member.amountType === "object" &&
        member.amountType !== null &&
        member.role &&
        member.role.permissions &&
        member.role.permissions.some(
          (permission: any) => permission.isChecked === true
        )
      );
    }
    const memberFilteredSlotsValid = computed(() => {
      return (
        state.data.inviteToTask.selectedMembers.length > 0 &&
        state.data.inviteToTask.selectedMembers.every((member: any) =>
          hasValidFilteredSlots(member)
        )
      );
    });
    function permissionByUser(data: any) {
      state.data.inviteToTask.selectedMembers = data;

    }
    const customDates = computed(() => {
      const dates = {
        startDate: "",
        endDate: "",
      };
      // Sample array of objects with start and end dates
      const dateArray: any = [];
      if (props.tasks) {
        props.tasks.forEach((item: any) => {
          dateArray.push({
            startDate: common.formatDateByDash(item.edit_start_date),
            endDate: common.formatDateByDash(item.edit_end_date),
          });
        });
      }

      // Initialize variables to store the earliest start and latest end dates
      // eslint-disable-next-line @typescript-eslint/camelcase
      dates.startDate = dateArray[0].startDate;
      // eslint-disable-next-line @typescript-eslint/camelcase
      dates.endDate = dateArray[0].endDate;

      // Iterate through the array to find the earliest start and latest end dates
      for (const dateObj of dateArray) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (dateObj.startDate < dates.startDate) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          dates.startDate = dateObj.startDate;
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (dateObj.endDate > dates.endDate) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          dates.endDate = dateObj.endDate;
        }
      }

      console.log("Earliest Start Date:", dates.startDate);
      console.log("Latest End Date:", dates.endDate);
      return dates;
    });
    // const disabledDates = ref([
    //   {
    //     month: {
    //       october: [2, 3],
    //     },
    //   },
    // ]);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const attributes = ref([
      // {
      //   key: "today",
      //   highlight: {
      //     color: "purple",
      //     fillMode: "solid",
      //     contentClass: "italic",
      //   },
      //   dates: new Date(year, month, 12),
      // },
      // {
      //   highlight: {
      //     color: "purple",
      //     fillMode: "light",
      //   },
      //   dates: new Date(year, month, 13),
      // },
      {
        dot: true,
        highlight: {
          color: "purple",
          fillMode: "outline",
        },
        dates: new Date(year, month, 14),
      },
    ]);
    //     function createdEvent(event: any){
    //       console.log("CHECK Task CREATED >>> ", event);
    //       // event.formatedStart = common.formatDateAndTimeSlot(event.start);
    //       // event.formatedEnd = common.formatDateAndTimeSlot(event.end);
    //       // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTimeSlot(event.start));
    //       // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTimeSlot(event.end));

    //       // latest update code
    //       state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots = event;
    //       // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.push(event);

    // // Create an object to store the latest end_time for each unique date and time
    // const latestEndTimes: any = {};

    // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.forEach((slot: any) => {
    //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    //   const time = slot.formatedStart.split(" ")[1]; // Extract the time part
    //   const formatedEnd = new Date(slot.formatedEnd);

    //   const dateTimeKey = `${date} ${time}`;

    //   if (!latestEndTimes[dateTimeKey] || formatedEnd > latestEndTimes[dateTimeKey]) {
    //     latestEndTimes[dateTimeKey] = formatedEnd;
    //   }
    // });

    // // Create a new array with the updated time slots
    // const updatedTimeSlots = Object.values(latestEndTimes).map(
    //   (latestEndTime: any) => {
    //     return state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.find(
    //       (slot: any) =>
    //         new Date(slot.formatedEnd).getTime() ===
    //         latestEndTime.getTime()
    //     );
    //   }
    // );

    // // Filter the old slots to keep only the ones that are in updatedTimeSlots
    // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots = state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.filter((slot: any) => {
    //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    //   const time = slot.formatedStart.split(" ")[1]; // Extract the time part
    //   const dateTimeKey = `${date} ${time}`;
    //   return updatedTimeSlots.includes(slot) || latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd);
    // });

    // // eslint-disable-next-line @typescript-eslint/camelcase
    // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].filteredSlots = updatedTimeSlots;

    // //       state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.push(event);
    // //       // Create an object to store the latest end_time for each unique date
    // //       const latestEndTimes: any = {};

    // // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.forEach((slot: any) => {
    // //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    // //   const formatedEnd = new Date(slot.formatedEnd);

    // //   if (!latestEndTimes[date] || formatedEnd > latestEndTimes[date]) {
    // //     latestEndTimes[date] = formatedEnd;
    // //   }
    // // });

    // // // Create a new array with the updated time slots
    // // const updatedTimeSlots = Object.values(latestEndTimes).map(
    // //   (latestEndTime: any) => {
    // //     return state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.find(
    // //       (slot: any) =>
    // //         new Date(slot.formatedEnd).getTime() ===
    // //         latestEndTime.getTime()
    // //     );
    // //   }
    // // );
    // // // Filter the old slots to keep only the ones that are in updatedTimeSlots
    // // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots = state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.filter((slot: any) => {
    // //   return updatedTimeSlots.includes(slot);
    // // });
    // // // eslint-disable-next-line @typescript-eslint/camelcase
    // // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].filteredSlots = updatedTimeSlots;
    //     }
    function filterByNew(array: any) {
      return array.filter((item: any) => item.new === true);
    }
    function createdEvent(event: any) {
      console.log("CHECK Task CREATED >>> ", event);
      // // event.formatedStart = common.formatDateAndTimeSlot(event.start);
      // // event.formatedEnd = common.formatDateAndTimeSlot(event.end);
      // // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTimeSlot(event.start));
      // // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTimeSlot(event.end));

      // // latest update code
      // state.data.inviteToTask.selectedMembers[
      //   state.data.selectedMemberIndex
      // ].slots = event;
      // // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.push(event);

      // // Create an object to store the latest end_time for each unique date and time
      // const latestEndTimes: any = {};

      // state.data.inviteToTask.selectedMembers[
      //   state.data.selectedMemberIndex
      // ].slots.forEach((slot: any) => {
      //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //   const time = slot.formatedStart.split(" ")[1]; // Extract the time part
      //   const formatedEnd = new Date(slot.formatedEnd);

      //   const dateTimeKey = `${date} ${time}`;

      //   if (
      //     !latestEndTimes[dateTimeKey] ||
      //     formatedEnd > latestEndTimes[dateTimeKey]
      //   ) {
      //     latestEndTimes[dateTimeKey] = formatedEnd;
      //   }
      // });

      // // Create a new array with the updated time slots
      // const updatedTimeSlots = Object.values(latestEndTimes).map(
      //   (latestEndTime: any) => {
      //     return state.data.inviteToTask.selectedMembers[
      //       state.data.selectedMemberIndex
      //     ].slots.find(
      //       (slot: any) =>
      //         new Date(slot.formatedEnd).getTime() === latestEndTime.getTime()
      //     );
      //   }
      // );

      // // Filter the old slots to keep only the ones that are in updatedTimeSlots
      // state.data.inviteToTask.selectedMembers[
      //   state.data.selectedMemberIndex
      // ].slots = state.data.inviteToTask.selectedMembers[
      //   state.data.selectedMemberIndex
      // ].slots.filter((slot: any) => {
      //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //   const time = slot.formatedStart.split(" ")[1]; // Extract the time part
      //   const dateTimeKey = `${date} ${time}`;
      //   return (
      //     updatedTimeSlots.includes(slot) ||
      //     latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd)
      //   );
      // });
      if (event && event.length) {
        event.forEach((item: any) => {
          item.formatedStart = common.formatDateAndTimeSlot(item.start);
          item.formatedEnd = common.formatDateAndTimeSlot(item.end);
        })
      }

      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.inviteToTask.selectedMembers[
        state.data.selectedMemberIndex
      ].filteredSlots = filterByNew(event);

      //       state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.push(event);
      //       // Create an object to store the latest end_time for each unique date
      //       const latestEndTimes: any = {};

      // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.forEach((slot: any) => {
      //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //   const formatedEnd = new Date(slot.formatedEnd);

      //   if (!latestEndTimes[date] || formatedEnd > latestEndTimes[date]) {
      //     latestEndTimes[date] = formatedEnd;
      //   }
      // });

      // // Create a new array with the updated time slots
      // const updatedTimeSlots = Object.values(latestEndTimes).map(
      //   (latestEndTime: any) => {
      //     return state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.find(
      //       (slot: any) =>
      //         new Date(slot.formatedEnd).getTime() ===
      //         latestEndTime.getTime()
      //     );
      //   }
      // );
      // // Filter the old slots to keep only the ones that are in updatedTimeSlots
      // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots = state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.filter((slot: any) => {
      //   return updatedTimeSlots.includes(slot);
      // });
      // // eslint-disable-next-line @typescript-eslint/camelcase
      // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].filteredSlots = updatedTimeSlots;
    }
    function deletedEvent(event: any) {
      // console.log("CHECK Phase DELETED >>> ", event)
      // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
      // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
      // state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots = state.data.inviteToTask.selectedMembers[state.data.selectedMemberIndex].slots.filter(
      //   (e: any) => e._eid !== event._eid
      // );

      event.formatedStart = common.formatDateAndTime(event.start);
      event.formatedEnd = common.formatDateAndTime(event.end);
      state.data.inviteToTask.selectedMembers[
        state.data.selectedMemberIndex
      ].slots = state.data.inviteToTask.selectedMembers[
        state.data.selectedMemberIndex
      ].slots.filter(
        (e: any) =>
          e.title !== event.title &&
          e.start !== event.start &&
          e.end !== event.end
      );
      // state.data.inviteToTask.selectedMembers
    }
    function selectedAmountTypeForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.amountType);
      selectedMember.member.amountType = selectedMember.amountType;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.inviteToTask.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.amountType = selectedMember.amountType;
        }
      });
    }
    function selectedRoleForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.role);
      if (
        selectedMember.role &&
        selectedMember.role.permissions &&
        selectedMember.role.permissions.length
      ) {
        selectedMember.role.permissions.forEach((permission: any) => {
          permission.isChecked = false;
        });
      }
      selectedMember.member.role = selectedMember.role;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.inviteToTask.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.role = selectedMember.role;
        }
      });
    }
    function addNewRole(newRole: any) {
      // phaseDetails.value.roles = newRole;
      emit("newRoleFromTaskMember", newRole);
    }
    function addNewAmountType(newAmountTypes: any) {
      emit("newAmountTypeFromTaskMember", newAmountTypes);
    }
    function hideAvaibilityModal() {
      state.data.showAvailibilityModal = false;
    }
    function checkAvaibilityByUser(member: any) {
      console.log("Selected User >>> ", member);
      state.data.selectedEmail = member.email;
      state.data.selectedUser = member;
      state.data.showAvailibilityModal = true;
    }
    function hideConfirmation() {
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
    }
    function removeSelectedMember() {
      // props.members
      state.data.inviteToTask.selectedMembers =
        state.data.inviteToTask.selectedMembers.filter(
          (e: any) => e != state.data.selectedToRemove
        );
    }
    function selectedMemberIndex(memberIndex: number) {
      state.data.selectedMemberIndex = memberIndex;
    }
    function selectedMemberFromTask(selectedMember: any) {
      state.data.selectedToRemove = selectedMember;
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
      // emit("selectedMember", selectedMember);
    }
    function setNewSelectedMembers(fullName: string, email: string) {
      let phaseMember: any = [];
      if (props.phaseDetails && props.phaseDetails.phase_members) {
        phaseMember = props.phaseDetails.phase_members;
      }
      console.log("IN HERE Name >>>", fullName);
      console.log("IN HERE Email >>>", email);
      // if (state.data.inviteToTask.selectedMembers.length === 0) {
      // state.data.inviteToTask.selectedMembers.push({
      //   name: fullName,
      //   amountType: "",
      //   isExist: common.findEmailMatch(email, phaseMember),
      //   amountTypeNumber: "",
      //   role: "",
      //   email: email,
      //   slots: [],
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   approval_required: false,
      // });

      // new code for check
      // const newMember = {
      //   name: fullName,
      //   amountType: "",
      //   isExist: common.findEmailMatch(email, phaseMember),
      //   amountTypeNumber: "",
      //   role: "",
      //   email: email,
      //   slots: [],
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   approval_required: false,
      // };

      if (state.data.inviteToTask.selectedMembers.length > 0 &&
        state.data.inviteToTask.selectedMembers[0].email === "") {
        state.data.inviteToTask.selectedMembers[0].name = fullName;
        state.data.inviteToTask.selectedMembers[0].amountType = "";
        state.data.inviteToTask.selectedMembers[0].isExist = common.findEmailMatch(email, phaseMember);
        state.data.inviteToTask.selectedMembers[0].amountTypeNumber = "";
        state.data.inviteToTask.selectedMembers[0].role = "";
        state.data.inviteToTask.selectedMembers[0].email = email;
        state.data.inviteToTask.selectedMembers[0].slots = [];
        // eslint-disable-next-line @typescript-eslint/camelcase
        state.data.inviteToTask.selectedMembers[0].approval_required = false;
        state.data.inviteToTask.selectedMembers[0].name = fullName;
        state.data.inviteToTask.selectedMembers[0].name = fullName;
        state.data.inviteToTask.selectedMembers[0].name = fullName;
      } else {
        state.data.inviteToTask.selectedMembers.push({
          name: fullName,
          amountType: "",
          isExist: common.findEmailMatch(email, phaseMember),
          amountTypeNumber: "",
          role: "",
          email: email,
          slots: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: false,
        });
      }

      // state.data.inviteToTask.existingMemberSelected = "";
    }
    function changeDetailsByEmail() {
      if (
        !validationService.isValidEmail(
          state.data.inviteToTask.existingMemberSelected
        )
      )
        return false;
      const selectedEmail = state.data.inviteToTask.existingMemberSelected;
      const oldEmail = state.data.inviteToTask.selectedMembers.find(
        (member: any) => {
          return member.email == selectedEmail;
        }
      );
      if (!(oldEmail === undefined)) {
        return false;
      }
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.changeDetailsByEmail) return false;
      state.ui.isLoading.changeDetailsByEmail = true;
      ethitransService
        .detailsByemail(selectedEmail.toString())
        .then((res: any) => {
          console.log("RES CHECK >>> ", res.data.data)
          if (res.data.data.email && res.data.data.full_name) {
            setNewSelectedMembers(res.data.data.full_name, res.data.data.email);
          } else {
            setNewSelectedMembers("", selectedEmail);
          }

          state.ui.isLoading.changeDetailsByEmail = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function availabilityCheck() {
      state.ui.error.errorList.availabilityCheck = [];
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: customDates.value.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: customDates.value.endDate,
      };

      if (state.ui.isLoading.availabilityCheck) return false;
      state.ui.isLoading.availabilityCheck = true;
      ethitransService
        .availabilityCheckByUser(payLoad)
        .then((res: any) => {
          const dates = [] as any;
          state.data.dates = res.data.data;
          if (res.data.data.availability && res.data.data.availability.length) {
            res.data.data.availability.forEach((item: any) => {
              if (item.status === 0) {
                dates.push({
                  dot: true,
                  highlight: {
                    color: "purple",
                    fillMode: "outline",
                  },
                  dates: new Date(common.formatDate(item.day)),
                });
              }
            });
          }
          res.data.data.formattedDate = dates;
          console.log("Formated:>>> ", dates);
          console.log("getToDoData Check:>>> ", res.data.data);
          state.data.dates = res.data.data;
          state.ui.isLoading.availabilityCheck = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.availabilityCheck)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.availabilityCheck.push(value[0]);
                }
              }
            });
        });
    }
    function hideAddMemberToTaskModal() {
      $("#add-member-Modal").modal("hide");
      emit("hideAddMemberToTaskModal");
    }
    function addMembersToTasks() {
      state.ui.error.errorList.addMembersToTasks = [];
      state.ui.error.hasError = false;

      const filterMembers = [] as any;
      const filteredTask = [] as any;
      if (props.tasks) {
        props.tasks.forEach((task: any) => {
          filteredTask.push({
            uuid: task.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            priority_id: task.priority_id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: task.edit_start_date,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: task.edit_end_date,
            budget: task.edit_budget,
          });
        });
      }
      console.log("Check User >>> ", state.data.inviteToTask.selectedMembers)
      state.data.inviteToTask.selectedMembers.forEach((member: any) => {
        const permissionList = [] as any;
        const timeSlots = [] as any;
        // starts here
        if (
          member.role &&
          member.role.permissions &&
          member.role.permissions.length
        ) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.isChecked) {
              permissionList.push(permission.id);
            }
          });
        }

        member.filteredSlots.forEach((slot: any) => {
          console.log("Slot Check >>> ", slot)
          timeSlots.push({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_time: slot.formatedStart,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_time: slot.formatedEnd,
            title: slot.title,
            bgColor: slot.bgColor,
          });
        });
        if (permissionList && permissionList.length) {
          permissionList.push(3);
        }
        filterMembers.push({
          email: member.email,
          name: member.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          role_id: member.role.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          amount_type_id: member.amountType.id,
          amount: member.amountTypeNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: member.approval_required,
          permissions: permissionList,
          // eslint-disable-next-line @typescript-eslint/camelcase
          time_slots: timeSlots,
        });
        // ends here
      });

      // add validation here

      if (state.ui.isPostLoading.addMembersInPhases) return false;
      state.ui.isPostLoading.addMembersToTasks = true;

      const payLoad = {
        phases: filteredTask,
        members: filterMembers,
      };
      console.log("PayLoad Add Member Check >> ", payLoad);
      ethitransService
        .addMembersInPhases(payLoad)
        .then((res: any) => {
          // state.data.showSuccesModal = true;
          console.log("Response Check >> ", res.data.data);
          // state.data.unitLists = res.data.data;
          state.ui.isPostLoading.addMembersToTasks = false;
          hideAddMemberToTaskModal();
          emit("addMemberToTaskData", res.data.data);
          emit("addMemberToTaskSuccess");
        })
        .catch((error: any) => {
          state.ui.isPostLoading.addMembersToTasks = false;
          common
            .sdCatchErr(error, state.ui.error.errorList.addMembersInPhases)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.addMembersInPhases.push(value[0]);
                }
              }
            });
        });
    }
    function insertEmptyMember() {
      if (state.data.inviteToTask.selectedMembers && state.data.inviteToTask.selectedMembers.length === 0) {

        state.data.inviteToTask.selectedMembers.push({
          name: '',
          amountType: "",
          isExist: false,
          amountTypeNumber: "",
          role: "",
          email: "",
          slots: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: false,
        });
      } else {
        state.data.inviteToTask.selectedMembers.push({
          name: '',
          amountType: "",
          isExist: false,
          amountTypeNumber: "",
          role: "",
          email: "",
          slots: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: false,
        });
      }
    }
    const hasValidLastEmail = computed(() => {
      const members = state.data.inviteToTask.selectedMembers;
      if (members.length === 0) {
        return false;
      }
      const lastMember = members[members.length - 1];
      return validationService.isValidEmail(lastMember.email);
    });

    onMounted(() => {
      if (props.isActive) {
        // $("#add-member-Modal").modal("show");
        insertEmptyMember()

      }
    });

    return {
      state,
      permissionByUser,
      hasValidLastEmail,
      insertEmptyMember,
      filterByNew,
      memberFilteredSlotsValid,
      hasValidFilteredSlots,
      selectedMemberIndex,
      createdEvent,
      deletedEvent,
      addMembersToTasks,
      selectedAmountTypeForMember,
      selectedRoleForMember,
      addNewAmountType,
      addNewRole,
      hideAvaibilityModal,
      checkAvaibilityByUser,
      customDates,
      hideConfirmation,
      removeSelectedMember,
      selectedMemberFromTask,
      setNewSelectedMembers,
      changeDetailsByEmail,
      availabilityCheck,
      hideAddMemberToTaskModal,
      // disabledDates,
      attributes,
    };
  },
});
