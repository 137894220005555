
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { config } from "@/Config";
import Loader from "./Loader.vue";

export default defineComponent({
  name: "ManageReferralLink",
  components: {
    SuccessfulModal,
    EmptyData,
    Loader
  },
  setup() {
    const state = reactive({
      data: {
        verifyMessage: '' as any,
        successMessage: "",
        showSuccesModal: false,
        title: "Referral Link",
        heading: "Referral",
        urlTitle: '' as any,
        urlVlidate: '' as any,
        subscription: '' as any,
        subscriptionVlidate: '' as any,
        showCopyMessage: false,
        selectId: '' as any,
        // eslint-disable-next-line @typescript-eslint/camelcase
        package_id: '' as any,
        imageBaseUrl: "",
        selectUrl: "" as any,
        subscriptionList: "" as any
      },
      ui: {

        isLoading: {
          referralList: false,
          isEditReferral: false,
        },
        isPostLoading: {
          sendInvitation: false,
          createReferralLink: false,
        },
        errors: {
          hasError: false,
        },
      },
      invites: [] as any,
      userList: [] as any,
      referralLinks: [] as any,
      inviteMember: "",
      errorList: [] as any,
      inputEmail: [] as any,
      isErrorStatus: false,
      error: {
        hasError: false,
        errorList: {
          createChannel: [] as any,
        },
      },
    });
    function addMember(data: any) {
      state.data.selectUrl = data;
      $("#inviteMember").modal("show");
    }

    function hideAddMember() {
      state.data.verifyMessage = '',
        state.data.selectUrl = '';
      state.invites = [];
      $("#inviteMember").modal("hide");
    }
    function showLoader() {
      const container = document.getElementById('f1_container');
      const loader = document.getElementById('loader');
      if (container) {
        container.style.display = "none"
      }
      if (loader) {
        loader.style.display = "block";
      }
    }
    function hideLoader() {
      const container = document.getElementById('f1_container');
      const loader = document.getElementById('loader');
      if (container) {
        container.style.display = "block"
      }
      if (loader) {
        loader.style.display = "none";
      }
    }

    function addReferralLink() {
      state.ui.isPostLoading.createReferralLink = false;
      showLoader();
      state.data.urlVlidate = false;
      ethitransService
        .subscriptionList()
        .then((res: any) => {
          console.log("Check the Subscription List >>> ", res.data.data)
          state.data.subscriptionList = res.data.data;
          // if (state.data.subscriptionList.length === 0) {
            
          // }
          hideLoader();
          $("#createRefferalLink").modal("show");
        })
        .catch((error: any) => {
          console.log(error);
        })
      // $("#createRefferalLink").modal("show");
    }



    function hideAddReferral() {
      $("#createRefferalLink").modal("hide");
    }

    function editReferralLink(data: any) {
      state.ui.isLoading.isEditReferral = true;
      state.data.urlTitle = data.title;
      state.data.selectId = data.id;
      state.data.subscription = data.package_id;
      addReferralLink();
    }
    

    
    function removeSelectedUser(item: any) {
      state.invites = state.invites.filter((e: any) => e != item);
    }

    function isValidEmail(email: any) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    function changeInviteMember() {
      state.data.verifyMessage = "";
      if (isValidEmail(state.inviteMember)) {
        state.inputEmail = [];
        state.inputEmail.push(state.inviteMember);
      } else {
        state.data.verifyMessage = "please enter valid email";
        return
      }

      const payLoad = {
        emails: state.inputEmail
      };
      ethitransService
        .verifyReferalInvitation(payLoad)
        .then((res: any) => {
          if (res.data.data.new[0]) {
            state.data.verifyMessage = "";
            state.inviteMember = "";
            if (res.data.data.new[0].trim() !== "" && !state.invites.includes(res.data.data.new[0])) {
              state.invites.push(res.data.data.new[0])
              console.log(res.data.data.new[0]);
            }

          } else {
            state.inviteMember = "";
            state.data.verifyMessage = res.data.data.old[0] + " already exists in our system"
          }

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.verifyInvitation = false;
        });
    }

    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }


    // function sendInviteMemberList() {
    //   ethitransService
    //     .sendReferalInvitationList()
    //     .then((res: any) => {
    //       state.userList = res.data.data
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {

    //       // state.ui.isPostLoading.sendInvitation = false;
    //     });
    // }

    function listReferralLink() {
      state.ui.isLoading.referralList = true;
      ethitransService
        .listReferralLink()
        .then((res: any) => {
          res.data.data.forEach((data: any) => {
            data.url = config.logOutUrl + `#/signup?ref=${data.code}`;
          });
          state.referralLinks = res.data.data
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {

          state.ui.isLoading.referralList = false;
        });
    }

    function createRefferalLink() {
      state.data.urlVlidate = '';
      if (!state.data.urlTitle) {
        state.data.urlVlidate = 'please enter share url for';
        return;
      }
      if (!state.data.subscription) {
        state.data.subscriptionVlidate = 'please select subscription package';
        return;
      }
      state.ui.isPostLoading.createReferralLink = true;
      const payLoad = {
        title: state.data.urlTitle,
        // eslint-disable-next-line @typescript-eslint/camelcase
        package_id: state.data.subscription
      };
      ethitransService
        .createReferralLink(payLoad)
        .then((res: any) => {
          hideAddReferral()
          state.data.showSuccesModal = true;
          state.data.successMessage = "Link Generate sucessfully";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          listReferralLink();
          state.ui.isPostLoading.createReferralLink = false;
        });
    }

    function updateRefferalLink() {
      state.data.urlVlidate = '';
      if (!state.data.urlTitle) {
        state.data.urlVlidate = 'please enter share url for';
        return;
      }
      state.ui.isPostLoading.createReferralLink = true;
      const payLoad = {
        title: state.data.urlTitle,
        id: state.data.selectId
      };
      ethitransService
        .updateReferralLink(payLoad)
        .then((res: any) => {
          hideAddReferral()
          state.data.showSuccesModal = true;
          state.data.successMessage = "Update sucessfully";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          listReferralLink();
          state.ui.isPostLoading.createReferralLink = false;
          state.ui.isLoading.isEditReferral = false;
          state.data.selectId = ''
        });
    }
    function sendInviteMember() {
      state.data.verifyMessage = '';
      if (state.invites.length == 0) {
        state.data.verifyMessage = "email Required";
        return
      }
      state.ui.isPostLoading.sendInvitation = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        referral_link_id: state.data.selectUrl.id,
        emails: state.invites
      };
      ethitransService
        .sendReferalInvitation(payLoad)
        .then((res: any) => {
          hideAddMember()
          state.data.showSuccesModal = true;
          state.data.successMessage = "Invite sent sucessfully";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.sendInvitation = false;
          listReferralLink();
        });
    }

    function copySharableLink(url: any, inputId: any) {
      const copyText: any = document.getElementById(inputId);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    }

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      listReferralLink();
    });

    return {
      state,
      addMember,
      hideAddMember,
      changeInviteMember,
      removeSelectedUser,
      isValidEmail,
      sendInviteMember,
      closeSuccessModal,
      // sendInviteMemberList,
      listReferralLink,
      addReferralLink,
      hideAddReferral,
      createRefferalLink,
      copySharableLink,
      editReferralLink,
      updateRefferalLink,
      showLoader,
      hideLoader
    };
  },
});
