import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    (!_ctx.isRecording)
      ? (_openBlock(), _createBlock("button", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startRecording && _ctx.startRecording(...args)))
        }, "Start Recording"))
      : _createCommentVNode("", true),
    (_ctx.isRecording)
      ? (_openBlock(), _createBlock("button", {
          key: 1,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stopRecording && _ctx.stopRecording(...args)))
        }, "Stop Recording"))
      : _createCommentVNode("", true),
    (_ctx.transcription)
      ? (_openBlock(), _createBlock("div", _hoisted_1, " Transcription: " + _toDisplayString(_ctx.transcription), 1))
      : _createCommentVNode("", true)
  ]))
}