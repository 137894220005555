import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "calendar-wrap" }
const _hoisted_2 = { class: "add-appointment text-center" }
const _hoisted_3 = {
  class: "alert alert-success text-center",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TostCalender = _resolveComponent("TostCalender")
  const _component_AppointmentCreateModal = _resolveComponent("AppointmentCreateModal")
  const _component_AppointmentDetailModal = _resolveComponent("AppointmentDetailModal")
  const _component_AppointmentImportModal = _resolveComponent("AppointmentImportModal")
  const _component_SuccessfulModal = _resolveComponent("SuccessfulModal")
  const _component_UpgradeSubscriptionModal = _resolveComponent("UpgradeSubscriptionModal")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("a", {
          class: "mr-2",
          id: "createNewAppointment",
          href: "javascript:void(0)",
          "data-toggle": "modal",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showAppointmentModal && _ctx.showAppointmentModal(...args)))
        }, "Add New"),
        _createVNode("a", {
          id: "createNewAppointment",
          href: "javascript:void(0)",
          "data-toggle": "modal",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showAppointmentImportModal && _ctx.showAppointmentImportModal(...args)))
        }, "Import")
      ]),
      _createVNode(_component_TostCalender, {
        key: _ctx.childComponentKey,
        activeScreen: 'mycalender',
        data: _ctx.state.data.appointmentLoad
      }, null, 8, ["data"])
    ]),
    (_ctx.state.data.showAppointCreateModel)
      ? (_openBlock(), _createBlock(_component_AppointmentCreateModal, {
          key: 0,
          showModal: _ctx.state.data.showAppointCreateModel,
          onAddAppointment: _ctx.getaddAppointment,
          onCalendarData: _ctx.appointData
        }, null, 8, ["showModal", "onAddAppointment", "onCalendarData"]))
      : _createCommentVNode("", true),
    (_ctx.state.data.showAppointDetailModel)
      ? (_openBlock(), _createBlock(_component_AppointmentDetailModal, {
          key: 1,
          showModal: _ctx.state.data.showAppointDetailModel,
          onAddAppointment: _ctx.getDetailAppointment,
          onCalendarData: _ctx.appointData,
          eventId: _ctx.state.data.eventId
        }, null, 8, ["showModal", "onAddAppointment", "onCalendarData", "eventId"]))
      : _createCommentVNode("", true),
    (_ctx.state.data.showAppointImportModel)
      ? (_openBlock(), _createBlock(_component_AppointmentImportModal, {
          key: 2,
          showModal: _ctx.state.data.showAppointImportModel,
          onImportAppointment: _ctx.getImportAppointment,
          onCalendarData: _ctx.appointData
        }, null, 8, ["showModal", "onImportAppointment", "onCalendarData"]))
      : _createCommentVNode("", true),
    (_ctx.state.data.showSuccesModal)
      ? (_openBlock(), _createBlock(_component_SuccessfulModal, {
          key: 3,
          onClose: _ctx.closeSuccessModal,
          showModal: _ctx.state.data.showSuccesModal
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.state.data.SuccessFulMessage), 1)
          ]),
          _: 1
        }, 8, ["onClose", "showModal"]))
      : _createCommentVNode("", true),
    (_ctx.state.ui.isActive.upgradeSubscription)
      ? (_openBlock(), _createBlock(_component_UpgradeSubscriptionModal, {
          key: 4,
          onCloseModal: _ctx.hideUpdateSubscriptionModal
        }, null, 8, ["onCloseModal"]))
      : _createCommentVNode("", true)
  ]))
}