
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";

import moment from "moment";

// import { Data } from "@/components/todo/AddMember.ts";

import { config } from "@/Config";
import Pusher from "pusher-js";

export default defineComponent({
  name: "RequestedInvoice",
  components: {
    // ToDoPhaseAddMemberTask,
    ErrorList,
    // AppDeleteConfirmationModal,
    SuccessfulModal,
  },
  setup() {
    const router = useRouter();
    const routerQuery = useRoute();
    const {
      params: { id },
    } = useRoute();
    const state = reactive({
      data: {
        breadcrumb: [] as any,
        invoices: [] as any,
        invoiceDetails: {} as any,
        isPayActive: false,
        stripeSuccess: false,
        isIndividual: false,
        showSuccesModal: false,
        stripeAccount: false,
        successFulMessage: "",
        selectedInvoice: "",
        stripeUrl: "",
        invoice: {
          total: 0,
          subTotal: 0,
          tax: 0,
          discount: 0,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getInvoiceByPhaseOrTask: [] as any,
          },
        },
        isLoading: {
          getInvoiceByPhaseOrTask: false,
          stripeAccountCheck: false,
          phaseInvoiceListById: false,
        },
        isPostLoading: {
          generateInvoice: false,
          stripeAccount: false,
          confirmPayment: false,
        },
      },
    });

    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceTotal = computed(() => {
      return state.data.invoice.total;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceCalculate = computed(() => {
      if (
        state.data.invoiceDetails.invoice_items &&
        state.data.invoiceDetails.invoice_items.length
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.subTotal = 0;
        state.data.invoiceDetails.invoice_items.forEach((invoice: any) => {
          invoice.amount = invoice.cost * invoice.quantity;
          const number = invoice.amount;
          state.data.invoice.subTotal += number;
        });
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.total =
          state.data.invoice.subTotal +
          (state.data.invoice.subTotal * state.data.invoice.tax) / 100 -
          state.data.invoice.discount;

        return state.data.invoiceDetails.invoice_items;
      }
    });
    // const invoiceSubtotal = invoiceCalculate.value.reduce((accumulator: any, selected: any)=> {
    //   return accumulator + selected.amount;
    // }, 0)
    // eslint-disable-next-line vue/return-in-computed-property
    const isAuthEdit = computed(() => {
      if (state.data.invoiceDetails && state.data.invoiceDetails.user_role) {
        // ROLE  1 = owner,4 = subcontractor, 2 = payer, 3 = member,
        if (state.data.invoiceDetails.user_role === 2) {
          // console.log("WOWOW ROLE");
          // billed_from && user_id
          // return true; // temporary
          return false;
        } else if (
          state.data.invoiceDetails.user_id ===
          state.data.invoiceDetails.billed_from
        ) {
          // console.log("HELLO");
          if (state.data.isIndividual) {
            return true;
          } else {
            return false;
          }
          // } else {
          //   // console.log("INDI FA");
          // return true;
          // }
        }
        // } else if (
        //   state.data.invoiceDetails.user_role === 1 ||
        //   state.data.invoiceDetails.user_role === 4
        // ) {
        //   // console.log("HELLO");
        //   if (state.data.isIndividual) {
        //     // console.log("INDI TR");
        //     if (state.data.invoiceDetails.draft_uuid) {
        //       return false;
        //     } else {
        //       return true;
        //     }
        //   } else {
        //     // console.log("INDI FA");
        //     return false;
        //   }
        // }
      }
      // if (state.data.invoiceDetails && state.data.invoiceDetails.from_role) {
      //   // 1 Payer
      //   // 2 Owner
      //   // 3 Sub Contractor
      //   // 4 Member/Employee
      //   if (
      //     state.data.invoiceDetails.from_role === 2 ||
      //     state.data.invoiceDetails.from_role === 3
      //   ) {
      //     return false;
      //   } else if (
      //     state.data.invoiceDetails.from_role === 1 ||
      //     state.data.invoiceDetails.from_role === 4
      //   ) {
      //     if (state.data.isIndividual) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
    });
    function downloadFile() {
      window.open(state.data.invoiceDetails.pdf_url, "_blank");
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.data.stripeSuccess = false;
      $("#successfulModal").modal("hide");
    }
    function addItemInInvoice() {
      state.data.invoiceDetails.invoice_items.push({
        amount: 0,
        cost: 0,
        quantity: 0,
        title: "",
      });
    }
    function removeSelectedItemInInvoice(item: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invoiceDetails.invoice_items =
        state.data.invoiceDetails.invoice_items.filter((e: any) => e != item);
    }
    function choosePaymentMode() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (!state.data.invoiceDetails.uuid) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: state.data.invoiceDetails.uuid,
        amount: state.data.invoiceDetails.total,
      };
      console.log("Paylaod Check >>> ", payLoad);
      state.ui.isPostLoading.confirmPayment = true;
      ethitransService
        .payInvoiceByStripe(payLoad)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 1) {
            console.log("Project Todo Response Check:>>> ", res.data.data.link);
            window.location.replace(`${res.data.data.link}`);
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.confirmPayment = false;
        });
    }
    function invoiceSuccess() {
      state.data.successFulMessage = "Invoice Generated Successfully.";
      // state.data.showSuccesModal = !state.data.showSuccesModal;
      if (routerQuery.query.account === "success") {
        router.push({
          name: "admin-billing-invoice",
          params: { invoiceId: state.data.selectedInvoice },
          query: { account: "" },
        });
      }
    }
    function generateInvoice() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      let phaseId = "";
      invoiceCalculate.value.forEach((item: any) => {
        delete item.created_at;
        delete item.deleted_at;
        delete item.invoice_id;
        delete item.id;
        delete item.updated_at;
        delete item.uuid;
      });
      if (state.data.invoiceDetails && state.data.invoiceDetails.phase_id) {
        phaseId = state.data.invoiceDetails.phase_id;
      } else {
        phaseId = state.data.invoiceDetails.invoice_phase.phase.uuid;
      }

      if (state.ui.isPostLoading.generateInvoice) return false;
      if (!phaseId) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: phaseId,
        items: invoiceCalculate.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sub_total: state.data.invoice.subTotal,
        tax: state.data.invoice.tax,
        discount: state.data.invoice.discount,
        total: state.data.invoice.total,
      };
      console.log("ID CHECK  WITH PAYLOAD>>>", payLoad);
      state.ui.isPostLoading.generateInvoice = true;
      ethitransService
        .phaseGenerateInvoice(payLoad)
        .then((res: any) => {
          closeSuccessModal();
          console.log("generateInvoice Response Check:>>> ", res.data.data);
          setTimeout(invoiceSuccess, 1000);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.generateInvoice = false;
        });
    }
    function redirectToStripeAccount() {
      state.ui.isPostLoading.stripeAccount = true;
      window.location.replace(`${state.data.stripeUrl}`);
      state.ui.isPostLoading.stripeAccount = false;
    }
    function stripeAccountCheck() {
      // alert("HELLO");
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.stripeAccountCheck) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.getInvoiceByPhaseOrTask.length != 0
      )
        return false;
      state.ui.isLoading.stripeAccountCheck = true;
      ethitransService
        .stripeAccountCheck(state.data.invoiceDetails.uuid)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 0) {
            state.data.stripeUrl = res.data.data.link;
            console.log("Project Todo Response Check:>>> ", res.data.data);
            state.data.showSuccesModal = !state.data.showSuccesModal;
            state.data.successFulMessage = "Stripe Account Checked";
          } else if (res.data.data.status === 1) {
            generateInvoice();
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.stripeAccountCheck = false;
        });
    }
    function getInvoiceByPhaseOrTask(selected: any) {
      const mode: number = selected.mode;
      console.log("CHECK MODE >>> ", mode);
      let invoiceId = "";
      if (mode === 1) {
        invoiceId = selected.uuid;
      } else {
        invoiceId = id.toString();
      }
      console.log("Choose Payment Method", invoiceId);
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;
      state.ui.isLoading.getInvoiceByPhaseOrTask = true;
      ethitransService
        .invoiceByPhaseOrTask(invoiceId, mode)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice && res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateAndTime(
              res.data.data.invoice.invoice_date
            );
          }
          res.data.data.breadcrumb.reverse();
          state.data.invoiceDetails = res.data.data.invoice
            ? res.data.data.invoice
            : {};
          if (routerQuery.query.mode === "1") {
            state.data.isIndividual = true;
          } else {
            state.data.isIndividual = false;
          }
          state.data.breadcrumb = res.data.data.breadcrumb
            ? res.data.data.breadcrumb
            : [];
          if (
            localStorage.getItem("current_user_id") &&
            state.data.invoiceDetails.billed_from
          ) {
            const userId: any = localStorage.getItem("current_user_id");
            if (
              parseInt(userId) !=
              parseInt(state.data.invoiceDetails.billed_from)
            ) {
              state.data.isPayActive = true;
            } else {
              state.data.isPayActive = false;
            }
          }
          if (routerQuery.query.account === "success") {
            state.data.stripeSuccess = true;

            state.data.showSuccesModal = !state.data.showSuccesModal;
            state.data.successFulMessage = "Stripe Account Setup Completed.";
            // generateInvoice();
          }
          if (routerQuery.query.invoiceId) {
            router.push({
              name: "admin-billing-requested-invoice",
              params: { phaseId: id },
              query: { mode: routerQuery.query.mode },
            });
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getInvoiceByPhaseOrTask = false;
        });
    }
    function phaseInvoiceListById() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.phaseInvoiceListById) return false;
      const payLoad: any = {
        id: id,
        status: "",
      };
      state.ui.isLoading.phaseInvoiceListById = true;
      ethitransService
        .phaseInvoiceListById(payLoad)
        .then((res: any) => {
          console.log(
            "phaseInvoiceListById Response Check:>>> ",
            res.data.data
          );

          res.data.data.reverse();
          state.data.invoices = res.data.data ? res.data.data : [];
          if (state.data.invoices && state.data.invoices.length) {
            state.data.invoices.forEach((invoice: any) => {
              invoice.mode = 1;
              // eslint-disable-next-line @typescript-eslint/camelcase
              invoice.invoice_date = common.localDateAndTime(
                invoice.invoice_date
              );
            });
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          // state.data.invoices.unshift({invoice_no : 'All', mode: 2, invoice_date: 'All Invoices For Phase'})
          // state.data.selectedInvoice = state.data.invoices[state.data.invoices.length-1].uuid
          if (routerQuery.query.invoiceId) {
            const invoiceId: any = routerQuery.query.invoiceId;
            state.data.selectedInvoice = invoiceId;
            console.log("QUERY >>>");
            getInvoiceByPhaseOrTask(state.data.selectedInvoice);
          } else {
            console.log("AUTOSET >>>");
            const invoiceId: any = state.data.invoices[0];
            state.data.selectedInvoice = invoiceId;
            // console.log("Length >>> ", 0);
            // console.log("hello >>> ", invoiceId);
            getInvoiceByPhaseOrTask(state.data.selectedInvoice);
            //   state.data.invoices[state.data.invoices.length - 1].uuid
            // );
          }

          // state.data.invoiceDetails = res.data.data.invoice;
          // if (
          //   localStorage.getItem("current_user_id") &&
          //   state.data.invoiceDetails.billed_from
          // ) {
          //   const userId: any = localStorage.getItem("current_user_id");
          //   if (
          //     parseInt(userId) !=
          //     parseInt(state.data.invoiceDetails.billed_from)
          //   ) {
          //     state.data.isPayActive = true;
          //   } else {
          //     state.data.isPayActive = false;
          //   }
          // }
          // if (routerQuery.query.account === "success") {
          //   state.data.stripeSuccess = true;

          //   state.data.showSuccesModal = !state.data.showSuccesModal;
          //   state.data.successFulMessage = "Stripe Account Setup Completed.";
          //   // generateInvoice();
          // }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.phaseInvoiceListById = false;
        });
    }

    onMounted(() => {
      // getToDoData();
      console.log("CHECK >>>", id);

      phaseInvoiceListById();
    });

    return {
      state,
      downloadFile,
      invoiceTotal,
      getInvoiceByPhaseOrTask,
      redirectToStripeAccount,
      stripeAccountCheck,
      closeSuccessModal,
      choosePaymentMode,
      isAuthEdit,
      invoiceCalculate,
      addItemInInvoice,
      removeSelectedItemInInvoice,
      generateInvoice,
      invoiceSuccess,
    };
  },
});
