
import { defineComponent, reactive, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ToDoListBreadCrumb from '@/components/todo/ToDoListBreadCrumb.vue';
import ToDoListPhaseBlock from '@/components/todo/ToDoListPhaseBlock.vue';
import EmptyData from '@/components/commonDesign/EmptyData.vue';
import ErrorList from '@/components/commonDesign/ErrorList.vue';
import { config } from '@/Config';

import { ethitransService } from '@/services/EthitransService';
import { common } from '@/common/services/Common';
import ToDoListAddPhaseModal from '@/components/todo/ToDoListAddPhaseModal.vue';
import SuccessfulModal from '@/components/modal/SuccessfulModal.vue';

import AppGoBack from '@/components/commonDesign/AppGoBack.vue';

import Pusher from 'pusher-js';

export default defineComponent({
  name: 'ToDoList',
  components: {
    ErrorList,
    EmptyData,
    ToDoListBreadCrumb,
    ToDoListPhaseBlock,
    ToDoListAddPhaseModal,
    SuccessfulModal,
    AppGoBack,
  },
  setup() {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const selectAll = ref(false);
    const todoData = ref({
      project: {} as any,
      payer: {} as any,
      phases: {} as any,
      todo: {} as any,
      projectAcknowledgement: {
        attachments: [] as any,
        payerRequest: {} as any,
        payers: {} as any,
      } as any,
      role: 3,
    });
    const state = reactive({
      data: {
        selectedProjectUuid: "To Do",
        endPoint: "To Do",
        todoEmptyTitle: "ToDo List",
        todoEmptyDescription:
          "Get started by adding Todo List in your project.",
        todoFilterBy: {
          search: "",
          startDate: "",
          endDate: "",
          status: "",
        },
        successFullMessage: "",
        showGetToDoList: false,
        showAddPhaseModal: false,
        showEditPhaseModal: false,
        editMode: false,
        showSuccesModal: false,
        totalReadyToCheck: "" as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getToDoData: [] as any,
            createPhase: [] as any,
          },
        },
        isLoading: {
          showShareEstimation: false,
          getToDoData: false,
          getFolderList: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });
    function copySharableLink(){

      // Update the content dynamically
      const url = `${config.logOutUrl}#/public-calender/${projectId}`;

      // Create a temporary element to copy text
      const tempElement = document.createElement("textarea");
      tempElement.value = url;

      // Set it to be invisible
      tempElement.style.position = "absolute";
      tempElement.style.left = "-9999px";

      document.body.appendChild(tempElement);
      tempElement.select();

      try {
        const successful = document.execCommand("copy");
        const message = successful ? "Copied to clipboard!" : "Unable to copy";
        alert(message);
      } catch (err) {
        console.error("Unable to copy", err);
      }

      document.body.removeChild(tempElement);
      // projectId
      // const copyText: any = document.getElementById(`https://localhost:8080/#/public-calender/${projectId}`);
      // copyText.select();
      // copyText.setSelectionRange(0, 99999);
      // document.execCommand("copy");
    }
    function updatePhaseList(updatedList: any) {
      console.log("Final List: ", updatedList.phases);
      todoData.value = updatedList;
      todoData.value.phases.data = updatedList.phases.data;
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.data.successFullMessage = "To Do Update Successfully.";
    }
    function check(data: any) {
      console.log("DATA CHECK >> ", data);
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function showAddPhaseModal() {
      console.log("Hellooo");
      // alert("SHOW")
      state.data.showAddPhaseModal = !state.data.showAddPhaseModal;
    }

    function showEditPhaseModal() {
      console.log("Hellooo");
      // alert("SHOW")
      state.data.showEditPhaseModal = !state.data.showEditPhaseModal;
    }

    function addNewPhase(data: any) {
      console.log("add List: ", data.phases);
      todoData.value = data;
      todoData.value.phases.data = data.phases;
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.data.successFullMessage = "New To Do Added Successfully.";
    }
    function updateSelected() {
      alert("im from emit");
      console.log("UPDATE CHECK >>> ");
    }
    function updateNewPhase(data: any) {
      console.log("Update Phase", data);
      todoData.value = data;
      todoData.value.phases.data = data.phases;
      state.data.showSuccesModal = !state.data.showSuccesModal;
      state.data.successFullMessage = "To Do Update Successfully.";
    }
    function getToDoData() {
      // alert("HELLO")
      state.ui.error.errorList.getToDoData = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getToDoData) return false;
      state.ui.isLoading.getToDoData = true;
      ethitransService
        .todoDetailsByProjectId(
          projectId.toString(),
          state.data.todoFilterBy.search,
          state.data.todoFilterBy.startDate,
          state.data.todoFilterBy.endDate,
          state.data.todoFilterBy.status
        )
        .then((res: any) => {
          // console.log("Project Todo Response Check:>>> ", res.data.data);
          res.data.data.phases.data.forEach((phase: any) => {
            phase.localStartedDate = common.localDateOnly(phase.start_date);
            phase.localEndDate = common.localDateOnly(phase.end_date);
            phase.isSelected = false;
          });

          todoData.value = res.data.data;
          state.data.selectedProjectUuid = res.data.data.project.uuid;
          state.data.showGetToDoList = true;
          state.data.totalReadyToCheck = todoData.value.phases.data.filter(
            (phase: any) =>
              phase.status_detail.status.id == 1 ||
              phase.status_detail.status.id == 3 ||
              phase.status_detail.status.id == 5
          );
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getToDoData)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getToDoData.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getToDoData = false;
        });
    }
    function clearGetToDoData() {
      state.data.todoFilterBy.search = "";
      state.data.todoFilterBy.startDate = "";
      state.data.todoFilterBy.endDate = "";
      state.data.todoFilterBy.status = "";
      getToDoData();
    }
    function checkAllStatus() {
      if (selectAll.value && state.data.totalReadyToCheck.length > 0) {
        state.ui.isLoading.showShareEstimation = true;
      } else {
        state.ui.isLoading.showShareEstimation = false;
      }
      todoData.value.phases.data.forEach((phase: any) => {
        if (
          phase.status_detail.status.id == 1 ||
          phase.status_detail.status.id == 3 ||
          phase.status_detail.status.id == 5
        ) {
          phase.isSelected = selectAll.value;
        }
      });
    }
    watch(
      // () => watchTour,
      () => state.data.todoFilterBy.endDate,
      (newvalue, oldvalue) => {
        if (newvalue) {
          if (!state.data.todoFilterBy.startDate) return false;
          getToDoData();
        }
      }
    );
    function getToDoDataBySearch() {
      // if (!state.data.todoFilterBy.search) return false;
      getToDoData();
    }

    function sendEstimationInvitation() {
      const selectPhase = [] as any;
      todoData.value.phases.data.forEach((phase: any) => {
        if (phase.isSelected == true) {
          selectPhase.push(phase.id);
        }
      });
      router.push({
        name: "send-estimation-invitation",
        query: { projectId: projectId, phaseId: selectPhase },
      });
    }

    function updateCheckSelectPhase(data: any, selectData: any) {
      console.log(selectData, data);
      selectAll.value = data;
      if (selectData > 0) {
        state.ui.isLoading.showShareEstimation = true;
      } else {
        state.ui.isLoading.showShareEstimation = false;
      }
    }

    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });

    const todoDashboard = pusher.subscribe(
      `private-todoDashboard.${projectId.toString()}`
    );
    // console.log("Channel Communication>>> ", channel);

    todoDashboard.bind("todoDashboard.updated", function (data: any) {
      if (JSON.stringify(data)) {
        const url: any = window.location.href;
        // const url: any = window.location.href.split("#/").pop();
        const page: any = url.split("#/").pop().split("/")[0];
        console.log("CHECK URL >>> ", page);
        // const channelId: any = url.split("/").pop();
        // state.data.channelId = "";
        if (page === "todo") {
          // alert("TODOLIST TRIGGERED");
          // state.data.channelId = channelId;
          getToDoData();
        }
      }
    });
    onMounted(() => {
      getToDoData();
    });

    return {
      state,
      copySharableLink,
      check,
      updatePhaseList,
      updateSelected,
      closeSuccessModal,
      getToDoData,
      todoData,
      getToDoDataBySearch,
      showAddPhaseModal,
      addNewPhase,
      clearGetToDoData,
      updateNewPhase,
      checkAllStatus,
      selectAll,
      updateCheckSelectPhase,
      sendEstimationInvitation,
      showEditPhaseModal,
    };
  },
});
