import { createStore } from 'vuex'
export default createStore({
  state: {
    channelActiveMembers: [],
    selectedTasks: [],
    invitationCount: 0,
    defaultTour: false,
    showTourGuide: false,
    takAtour: false,
    showTakAtourModal: false,
    profileImage:"",
    saveSignedData:"",
    openSignDocument:"",
    editSignedData:"",
    docusignDocumentDataList:[],
  },
  mutations: {
    TOOGLE_TAKE_A_TOUR(state, toogle) {
      state.takAtour = toogle;
    },
    SHOW_MODAL_A_TOUR(state) {
      state.showTakAtourModal = !state.showTakAtourModal;
    },
    SHOW_DEFAULT_TOUR(state) {
      state.defaultTour = !state.defaultTour;
    },
    SAVE_INVITATION_COUNT(state, number) {
      state.invitationCount = number;
    },
    SAVE_PROFILE_IMAGE(state, string) {
      state.profileImage = string;
    },
    DOCU_SIGN_SIGNE_INFO(state, string) {
      state.saveSignedData = string;
    },

     DOCU_SIGN_EDIT_INFO(state, string) {
      state.editSignedData = string;
    },

    DOCU_SIGN_DOCUMENT_LIST(state, array) {
      state.docusignDocumentDataList = array;
    },

    OPEN_DOCUSIGN_DOCUMENT(state, string) {
      state.openSignDocument = string;
    },

    SAVE_CHAT_ACTIVE_MEMBERS(state, members) {
      state.channelActiveMembers = members;
    },
    SHOW_ACTIVETASK(state, tasks) {
      state.selectedTasks = tasks;
    },
    TOGGLE_SHOW_TOUR_GUIDE(state, number) {
      if (number === 1 || number === '1') {
        state.showTourGuide = true;
      } else {
        state.showTourGuide = false;
      }
    }
  },
  actions: {
    takeATourGuide({ commit }, toogle) {
      commit("TOOGLE_TAKE_A_TOUR", toogle)
    },
    showModalTourGuide({ commit }) {
      commit("SHOW_MODAL_A_TOUR")
    },
    showDefaultTourGuide({ commit }) {
      commit("SHOW_DEFAULT_TOUR")
    },
    saveInvitationCount({ commit }, invitationCount) {
      commit("SAVE_INVITATION_COUNT", invitationCount)
    },
    saveProfileImage({ commit }, profileImage) {
      commit("SAVE_PROFILE_IMAGE", profileImage)
    },

    saveDocusignSignInfo({ commit }, saveSignedData) {
      commit("DOCU_SIGN_SIGNE_INFO", saveSignedData)
    },

    editDocusignSignInfo({ commit }, editSignedData) {
      commit("DOCU_SIGN_EDIT_INFO", editSignedData)
    },

    docusignDocumentList({ commit }, docusignDocumentDataList) {
      commit("DOCU_SIGN_DOCUMENT_LIST", docusignDocumentDataList)
    },

    openDocusignDocument({ commit }, openSignDocument) {
      commit("OPEN_DOCUSIGN_DOCUMENT", openSignDocument)
    },

    saveActiveMembers({ commit }, activeMembers) {
      commit("SAVE_CHAT_ACTIVE_MEMBERS", activeMembers)
    },
    saveActiveTask({ commit }, tasks) {
      console.log("TASK CHECK >>> ", tasks)
      commit("SHOW_ACTIVETASK", tasks)
    },
    toggleTourGuide({ commit }, tourGuideStatus) {
      commit("TOGGLE_SHOW_TOUR_GUIDE", tourGuideStatus)
    }
  },
  modules: {
  }
})