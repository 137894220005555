
import { defineComponent, reactive, onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { config } from "@/Config";
import { validationService } from "@/common/ValidationService";


import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
// import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";


export default defineComponent({
  name: "ToDoPhaseAddMemberIndividualTest",
  props: {
    members: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,

    },
    relatedPermissions: {
      type: Array,
      required: true,

    },
    amountDepends: {
      type: Array,
    },
    userRole: {
      type: Number,
    },
    type: {
      type: Number,
    },
    roles: {
      type: Array,
    },
    amountTypes: {
      type: Array,
      required: true,

    },
    isShowDeleteMember: {
      type: Boolean,
      required: true,
    },
    isShowDeleteSlot: {
      type: Boolean,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  components: {
    // SuccessfulModal,
    // ErrorList,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const selectAll = ref(false);
    const todoData = ref({
      project: {} as any,
    });
    const state = reactive({
      selectRole: {} as any,
      selectAmountType: {} as any,
      member: {} as any,
      data: {
        selectedMemberIndex: 0,
        unit: {
          name: "",
          permissionList: [] as any,
          // eslint-disable-next-line @typescript-eslint/camelcase  
          amount_depends: [] as any,
          selectedAmountType: 0,
        },
        selectedUnit: {
          name: "",
        } as any,
        selectedToRemove: {},
        showDeleteConfirmationModal: false,
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        showSuccesModal: false,
        successFulMessage: ""
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getToDoData: [] as any,
            createRole: [] as any,
            createAmountType: [] as any,
          },
        },
        isLoading: {
          isRoleEdit: false,
          isAmountTypeEdit: false,
          showShareEstimation: false,
        },
        isPostLoading: {
          createAmountType: false,
          createRole: false,
          createPhase: false,
          phaseRoleDelete: false,
          amountTypeDelete: false
        },
      },
    });
    const isNotEmail = computed(() => (value: string) => {
      return !validationService.isValidEmail(value);
    });
    watch(
      () => props.permissions,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.data.unit.permissionList = JSON.parse(
            JSON.stringify(newvalue)
          );
        }
      }
    );
    watch(
      // () => watchTour,
      () => props.amountDepends,
      (newvalue, oldvalue) => {
        if (newvalue) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.unit.amount_depends = JSON.parse(
            JSON.stringify(newvalue)
          );
        }
      }
    );
    function permissionByUser(memberIndex: any, permissionIndex: any, event: any) {
      const allmembers: any = JSON.parse(
        JSON.stringify(props.members));

      allmembers[memberIndex].role.permissions[permissionIndex].isChecked = event.target.checked;
      console.log("CHECK PN >>>>", allmembers)
      emit("permissionByUser", allmembers);

    }
    function deleteSelectedTimeSlot(memberIndex: any, timeslot: any) {
      emit("selectedMemberIndex", memberIndex);
      emit("activeTimeSlotForDelete", timeslot);
    }
    function showAvaibilityModalByUser(member: any, memberIndex: number) {
      // state.data.selectedMemberIndex = memberIndex;
      emit("selectedMemberForCheckAvaibility", member);
      emit("selectedMemberIndex", memberIndex);
    }
    function deleteAmountType(item: any, selected: any) {
      console.log("Triggered Delete Type >> ", selected);
      emit("selectedAmountTypeForDelete", selected);
    }
    function deleteRole(item: any, selected: any) {
      emit("selectedRoleForDelete", selected);
    }
    function selectAmountType(selectedMember: any, selectedAmountType: any) {
      const data = {
        member: selectedMember,
        amountType: selectedAmountType,
      }
      emit("selectedAmountTypeForMember", data);
    }
    function selectUnit(selectedMember: any, memberIndex: any, selectedRole: any) {
      const data = {
        member: selectedMember,
        index: memberIndex,
        role: selectedRole,
      }
      emit("selectedRoleForMember", data);
    }
    function hideConfirmation() {
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
    }
    function selectedMember(selectedMember: any) {
      emit("selectedMember", selectedMember);
    }
    // function removeSelectedMember() {
    //   // props.members
    //   state.data.members = state.data.members.filter(
    //     (e: any) => e != state.data.selectedToRemove
    //   );
    // }
    function hideAmountTypeModal() {
      // state.data.unit.permissionList = [];
      state.data.unit.name = "";
      state.ui.error.errorList.createAmountType = [];
      state.ui.error.hasError = false;
      $("#addNewAmountType").modal("hide");
      state.ui.isLoading.isAmountTypeEdit = false;
    }
    function addAmountTypeShow() {

      $("#addNewAmountType").modal("show");
    }
    function addAmountType(item: any) {
      state.data.unit.amount_depends.forEach((item: any) => {
        item.isChecked = false;
      });
      state.data.unit.name = "";
      state.ui.error.errorList.createAmountType = [];
      state.ui.error.hasError = false;
      addAmountTypeShow();
      state.data.unit.selectedAmountType = 0;
    }
    function addUnitShow() {
      state.ui.error.errorList.createRole = [];
      state.ui.error.hasError = false;
      $("#addNewmemberRole").modal("show");
    }
    function addNewUnit(item: any) {
      state.data.unit.name = "";
      state.data.unit.permissionList.forEach((item: any) => {
        item.isChecked = false;
      });
      addUnitShow();
    }

    function editRole(item: any) {
      state.data.unit.name = item.name;
      state.selectRole = item;
      state.data.unit.permissionList.forEach((item1: any) => {
        item1.isChecked = false;
        const matchingItem = item.permissions.find((item2: any) => item1.id === item2.id);
        if (matchingItem) {
          item1.isChecked = true;
        }
      });
      state.ui.isLoading.isRoleEdit = true;
      addUnitShow();
    }

    function editAmountType(item: any) {
      console.log("om", item)
      console.log("amount", state.data.unit.amount_depends)
      state.data.unit.name = item.name;
      state.selectAmountType = item;
      // type selection based on type key
      state.data.unit.selectedAmountType = item.type;
      state.data.unit.amount_depends.forEach((item1: any) => {
        item1.isChecked = false;
        if (item1.id === item.type) {
          item1.isChecked = true;
        }
      });
      console.log("amount", state.data.unit.amount_depends)
      state.ui.isLoading.isAmountTypeEdit = true;
      addAmountTypeShow();
    }
    function addUniteClose() {
      // state.data.unit.permissionList = [];
      state.data.unit.name = "";
      state.ui.error.errorList.createRole = [];
      state.ui.error.hasError = false;
      $("#addNewmemberRole").modal("hide");
      state.ui.isLoading.isRoleEdit = false;
    }
    function validateCreateAmountType() {
      console.log("<<< IN >>>")
      state.ui.error.errorList.createAmountType = [];
      state.ui.error.hasError = false;

      if (!state.data.unit.name) {
        state.ui.error.errorList.createAmountType.push("Name is required.");
      }
      if (!state.data.unit.selectedAmountType) {
        state.ui.error.errorList.createAmountType.push("Type is required.");
      }

      if (!state.ui.error.errorList.createAmountType && !state.ui.error.errorList.createAmountType.length) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createAmountType.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function createAmountType() {
      console.log("<<< CHECK >>>")
      state.ui.isPostLoading.createAmountType = false;
      validateCreateAmountType();
      if (state.ui.error.errorList.createAmountType.length != 0) return false;
      if (state.ui.isPostLoading.createAmountType) return false;
      const payLoad = {
        name: state.data.unit.name,
        type: state.data.unit.selectedAmountType,
      }

      state.ui.isPostLoading.createAmountType = true;
      ethitransService
        .createNewAmountType(payLoad)
        .then((res: any) => {
          console.log("Response Check >>> ", res.data.data);
          // eslint-disable-next-line @typescript-eslint/camelcase
          emit("addNewAmountType", res.data.data.amount_types);
          hideAmountTypeModal();
          state.ui.isPostLoading.createAmountType = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createAmountType)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createAmountType.push(value[0]);
                }
              }
            });
        });
    }

    function updateAmountType() {
      state.ui.error.errorList.createAmountType = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.createAmountType) return false;
      const payLoad = {
        id: state.selectAmountType.id,
        name: state.data.unit.name,
        type: state.data.unit.selectedAmountType,
      }

      state.ui.isPostLoading.createAmountType = true;
      ethitransService
        .updateNewAmountType(payLoad)
        .then((res: any) => {
          console.log("Response Check >>> ", res.data.data);
          // eslint-disable-next-line @typescript-eslint/camelcase 
          emit("addNewAmountType", res.data.data.amount_types);
          hideAmountTypeModal();
          state.ui.isPostLoading.createAmountType = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createAmountType)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createAmountType.push(value[0]);
                }
              }
            });
        });
    }
    function validateCreateRole() {
      state.ui.error.errorList.createRole = [];
      state.ui.error.hasError = false;

      if (!state.data.unit.name) {
        state.ui.error.errorList.createRole.push("Amount Tilte is required.");
      }
      console.log("CHECK >>> ", common.hasCheckedItem(state.data.unit.permissionList))
      if (!common.hasCheckedItem(state.data.unit.permissionList)) {
        state.ui.error.errorList.createRole.push("Atleast 1 permission needs to be checked.");
      }
      if (!state.ui.error.errorList.createRole && !state.ui.error.errorList.createRole.length) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createRole.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function createRole() {
      validateCreateRole()
      const ids = [] as any;
      if (state.ui.isPostLoading.createRole) return false;
      if (state.ui.error.errorList.createRole && state.ui.error.errorList.createRole.length) return false;
      state.data.unit.permissionList.forEach((item: any) => {
        if (item.isChecked) {
          ids.push(item.id);
        }
      })
      const payLoad = {
        name: state.data.unit.name,
        permissions: ids,
        // o = phase 1 for task
        type: props.type,
      }
      console.log("CHECK PAYLOAD >> ", payLoad);

      state.ui.isPostLoading.createRole = true;
      ethitransService
        .createNewRole(payLoad)
        .then((res: any) => {
          console.log("Response Check >>> ", res.data.data);
          emit("addNewRole", res.data.data.roles);
          addUniteClose();
          state.ui.isPostLoading.createRole = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createRole)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createRole.push(value[0]);
                }
              }
            });
        });
    }
    function updateRole() {
      state.ui.isPostLoading.createRole = false;
      state.ui.error.hasError = false;
      const ids = [] as any;
      if (state.ui.isPostLoading.createRole) return false;
      state.data.unit.permissionList.forEach((item: any) => {
        if (item.isChecked) {
          ids.push(item.id);
        }
      })
      const payLoad = {
        name: state.data.unit.name,
        permissions: ids,
        id: state.selectRole.id,
      }
      state.ui.isPostLoading.createRole = true;
      ethitransService
        .updatePhaseRole(payLoad)
        .then((res: any) => {
          emit("addNewRole", res.data.data.roles);
          const data = {
            member: selectedMember,
            role: res.data.data.roles.find((item: any) => item.id === state.selectRole.id)
          }
          emit("selectedRoleForMember", data);
        })
        .catch((error: any) => {
          //
        })
        .finally(() => {
          state.ui.isPostLoading.createRole = false;
          state.selectRole = {};
          addUniteClose();

        });
    }

    function showDeleteRole(item: any) {
      state.selectRole = item;
      $("#deleteRoleModel").modal("show");
    }
    function hideDeleteRole() {
      state.selectRole = {};
      $("#deleteRoleModel").modal("hide");
    }


    function showDeleteAmountType(item: any) {
      state.selectAmountType = item;
      $("#deleteAmountTypeModel").modal("show");
    }
    function hideDeleteAmountType() {
      state.selectAmountType = {};
      $("#deleteAmountTypeModel").modal("hide");
    }

    function deletePhaseRole() {
      const payLoad = {
        id: state.selectRole.id,
      };
      state.ui.isPostLoading.phaseRoleDelete = true;
      ethitransService
        .deletePhaseRole(payLoad)
        .then((res: any) => {
          hideDeleteRole();
          emit("addNewRole", res.data.data.roles);
          const data = {
            member: selectedMember,
            role: res.data.data.roles.find((item: any) => item.id === state.selectRole.id)
          }
          emit("selectedRoleForMember", data);
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = " Delete Task role successfully.";
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.phaseRoleDelete = false;
        });
    }

    function deletePhaseAmountType() {
      const payLoad = {
        id: state.selectAmountType.id,
      };
      state.ui.isPostLoading.amountTypeDelete = true;
      ethitransService
        .deleteAmountType(payLoad)
        .then((res: any) => {
          emit("addNewAmountType", res.data.data.amount_types);
          hideDeleteAmountType();
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = " Delete Phase amount type successfully.";
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.amountTypeDelete = false;
        });
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    onMounted(() => {
      if (props.permissions) {
        // if(props.member){

        //   state.member = props.member;
        // }
        state.data.unit.permissionList = JSON.parse(
          JSON.stringify(props.permissions)
        );
      }
      if (props.amountDepends) {

        // eslint-disable-next-line @typescript-eslint/camelcase
        state.data.unit.amount_depends = JSON.parse(
          JSON.stringify(props.amountDepends)
        );
      }
      // state.data.members = props.members;
    });

    return {
      state,
      isNotEmail,
      permissionByUser,
      // setPermissionList,
      validateCreateRole,
      // permissionList,
      deleteSelectedTimeSlot,
      showAvaibilityModalByUser,
      deleteRole,
      deleteAmountType,
      selectAmountType,
      createAmountType,
      hideAmountTypeModal,
      addAmountType,
      addAmountTypeShow,
      selectUnit,
      createRole,
      addNewUnit,
      addUnitShow,
      addUniteClose,
      selectedMember,
      hideConfirmation,
      editRole,
      updateRole,
      showDeleteRole,
      hideDeleteRole,
      showDeleteAmountType,
      hideDeleteAmountType,
      deletePhaseRole,
      deletePhaseAmountType,
      closeSuccessModal,
      editAmountType,
      updateAmountType
    };
  },
});
