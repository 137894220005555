
  import { defineComponent } from "vue";
  export default defineComponent({
    name: "ChannelChatWelcomeMessage",
    setup(props) {
      // console.log("CHECK Title props", props.title);
      // console.log("CHECK Heading props", props.heading);
      // console.log("CHECK props", props.member.id);
      return {};
    },
  });
  