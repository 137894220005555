import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import MentionList from "./MentionList.vue";
import { config } from "@/Config";
import axios from "axios";
// import { useStore } from "vuex";
// import { createStore } from '@/store/index.ts'

// const store = useStore();

// const url: any = window.location.href;
const channelActiveMemebrs = [];

// console.log("CHECK ID >> ", channelId)
const Url = `${config.apiBaseUrl}channel/communication/member`;
// console.log("CHECK URL >> ", Url)


// alert("API CALL")

function updateList() {
  const token = localStorage.getItem("current_user_token");
  let newToken = "";
  if (token) {
    newToken = token.replace(/"/gi, "");
  }
  const mainUrl = window.location.href.split("#/").pop();
  const channelId = mainUrl.split("/").pop();
  const payLoad = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    channel_id: channelId,
  };
  axios({
    method: "post",
    headers: { Authorization: `Bearer ${newToken}` },
    url: Url,
    data: payLoad,
  })
    .then((data) => {
      channelActiveMemebrs.length = 0;
      // alert("API CALL")
      // console.log("CHECK SUGGESTION COMM LIST:>>> ", data.data.data.channel.members)
      data.data.data.channel.members.forEach((item) => {
        if (item.is_mute == 0) {
          channelActiveMemebrs.push(item.user);
        }
      });
    })
    .catch((err) => {
      console.log("EROR MSG", err.response);
    });
}
updateList();
// console.log("Channel Members Name List >>>", this.$store.state.channelActiveMembers)
export default {
  items: ({ query }) => {
    return channelActiveMemebrs
      .filter((item) =>
        item.full_name.toLowerCase().startsWith(query.toLowerCase())
      )
      .slice(0, 10);
  },
  render: () => {
    let component;
    let popup;
    updateList();
    return {
      onStart: (props) => {
        component = new VueRenderer(MentionList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          // using vue 3:
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        // return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
