
import { defineComponent, reactive, onMounted } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { config } from "@/Config";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MemberCard",
  props: {
    channel: Object,
    memberList: Array,
    pendingMembersCount: Number,
    approvedMembersCount: Number,
    channelType: Number,
    isUserStatus: Boolean,
    channelStatus: Boolean,
    ownerId: Number,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      data: {
        imageBaseUrl: "",
      },
      ui: {
        errors: {
          hasError: false,
          errorList: {
            resendInvitation: [] as any,
          },
        },
        isLoading: {
          communicationListAPICall: false,
        },
        isPostLoading: {
          privateChat: false,
        },
      },
      email: "" as any,
      memberList: [] as any,
      selectedMemberForRemoval: {} as any,
      pendingMembersCount: 0 as any,
      approvedMembersCount: 0 as any,
      postLoading: {
        removeSelectedMember: false,
      },
    });
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.email = localStorage.getItem("current_user_email");
      state.pendingMembersCount = props.pendingMembersCount;
      state.approvedMembersCount = props.approvedMembersCount;

      state.memberList = props.memberList;
      state.memberList.forEach((member: any) => {
        member.isInProcess = false;
      });

      // console.log("PEMDING MEMBERS", state.pendingMembersCount);
      // console.log("APPROVED MEMBERS", state.approvedMembersCount);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function selectedMember(member: any) {
      state.selectedMemberForRemoval = {};
      state.selectedMemberForRemoval = member;
      $("#removemember").appendTo("body").modal("show");
    }
    function hideRemoveMemberModal() {
      state.selectedMemberForRemoval = {};
      $("#removemember").modal("hide");
    }

    function muteMember(member: any) {
      state.selectedMemberForRemoval = {};
      state.selectedMemberForRemoval = member;
      $("#muteMember").appendTo("body").modal("show");
    }
    function hideMuteMemberModal() {
      state.selectedMemberForRemoval = {};
      $("#muteMember").modal("hide");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function removeSelectedMember() {
      if (state.postLoading.removeSelectedMember) return false;

      const payLoad = {
        id: state.selectedMemberForRemoval.id,
      };
      state.postLoading.removeSelectedMember = true;
      ethitransService
        .removeChannelMember(payLoad)
        .then((res: any) => {
          console.log(
            "removeSelectedMember Response Check:>>> ",
            res.data.data.channel.members
          );
          res.data.data.channel.members.forEach((member: any) => {
            member.isInProcess = false;
          });
          emit("removedMember", state.selectedMemberForRemoval);
          emit("approveMemberCountRemove");

          state.memberList = state.memberList.filter(
            (e: any) => e != state.selectedMemberForRemoval
          );
          // state.approvedMembersCount = state.approvedMembersCount-Number(1);
          hideRemoveMemberModal();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.removeSelectedMember = false;
        });
    }


    function muteChannelMember() {
      if (state.postLoading.removeSelectedMember) return false;

      const payLoad = {
        id: state.selectedMemberForRemoval.id,
        mute: !state.selectedMemberForRemoval.is_mute,
      };
      state.postLoading.removeSelectedMember = true;
      ethitransService
        .muteChannelMember(payLoad)
        .then((res: any) => {
          const memeberIndex = state.memberList.findIndex((item: any) => item.id === res.data.data.id);
          if (memeberIndex !== -1) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.memberList[memeberIndex].is_mute = res.data.data.is_mute;
          }

          hideMuteMemberModal();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.removeSelectedMember = false;
        });
    }


    function privateChat(member: any) {
      if (props.channel && props.channel.project) {
        // console.log("Private CHAT >>>", member.user)
        if (state.ui.isPostLoading.privateChat) return false;
        member.isInProcess = true;
        let channel: any = "";
        if (props.channel && props.channel.related_id) {
          channel = props.channel.related_id;
        } else {
          channel = "";
        }
        const memberUUID: any = member.user;
        console.log("Private CHAT >>>", memberUUID.uuid);
        state.ui.isPostLoading.privateChat = true;
        ethitransService
          .phasePrivateChannelByPhaseIdUserId(
            channel,
            memberUUID.uuid,
            props.channel.project.uuid
          )
          .then((res: any) => {
            member.isInProcess = false;
            console.log("privateChat Response Check:>>> ", res.data.data);
            if (res.data.data.channel && res.data.data.channel.uuid) {
              router.push({
                name: "admin-channel",
                params: { channelId: res.data.data.channel.uuid.toString() },
              });
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.privateChat = false;
          });
      }
    }
    // console.log("CHECK props", props.member.id);
    return {
      state,
      privateChat,
      selectedMember,
      removeSelectedMember,
      hideRemoveMemberModal,
      muteMember,
      hideMuteMemberModal,
      muteChannelMember
    };
  },
});
